import React from "react";
import "../loader/Loader.css";

export default function Loader() {
  return (
    <>
      {/* <div className="spinner-container">
      <div class="loader"></div>
   </div> */}

      <div className="loader-center">
        <div className="loader loader-1">
          <div className="loader-outter"></div>
          <div className="loader-inner"></div>
        </div>
      </div>
    </>
  );
}
