import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../pageIndex";

import { useNavigate } from "react-router-dom";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  // maxHeight: "450px",

  // bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,

  //   objectFit:"contain"
};

const MobileNumberVerifyModal = ({ mobileVerifyModal, mobileModalClose }) => {
  // const [showWinnewModal, setShowWinnewModal] = useState(false);
  const [showTimeWinnerModal, setShowTimeWinnerModal] = useState(false);
  const navigate = useNavigate();

  // click outside close modal code

  const modalRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleDlgClose();
      }
    };
    if (mobileVerifyModal) {
      window.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [mobileVerifyModal]);

  const handleDlgClose = () => {
    mobileModalClose();
  };
  return (
    <>
      <Index.Modal
        open={mobileVerifyModal}
        // onClose={() => {
        //   mobileModalClose();
        // }}
        // onClose={mobileModalClose}
        onClose={handleDlgClose}
        // onClose={mobileModalClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-comman-details bg-noblur res-cus-modal"
      >
        <Index.Box sx={style} className="mobile-verify-modal" ref={modalRef}>
          <Index.Box>
            {/* on okay buttton close modal  */}
            <Index.Box className="modal-cancel-btn modal-mobile">
              <Index.Button className="btn btn-cancel">
                <img
                  src={PageIndex.Svg.cancelmodal}
                  className="cancel-modal "
                  alt="modal-cancel"
                  onClick={() => {
                    mobileModalClose();
                  }}
                />
              </Index.Button>
            </Index.Box>
            <Index.Button
              className="modal-button-image cancel-modal-cus"
              onClick={() => {
                navigate("/user/setting");
                mobileModalClose();
              }}
            >
              <img
                // src={PageIndex.Svg.administrator}
                // src="https://png.pngtree.com/thumb_back/fh260/background/20211031/pngtree-abstract-bg-image_914283.png"
                src={PageIndex.Png.mobileVeryFyDeummyImage}
                className="cancel-modal custom-cancel-modal"
                alt="modal-cancel"
                onClick={() => {
                  // setShowWinnewModal(false);
                  // setGameWinnerUser([]);
                  // setResponseMessage("");
                  // setShowTimeWinnerModal(false);
                }}
              />
              {/* <h1>gdf</h1> */}
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default MobileNumberVerifyModal;
