import React, { useState } from "react";
import { userEditProfileSchema } from "../../../../validation/Validation";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import Index from "../../../Index";
import PageIndex from "../../../pageIndex";
import Loader from "../../../../component/comman/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { userProfile } from "../../../../redux/user/userReducer";
import { parsePhoneNumber } from "libphonenumber-js";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import MobileNumberOtpModal from "../../auth/otp/MobileNumberOtpModal";

const UserProfile = () => {
  const userData = useSelector((state) => state?.UserReducer?.userData);

  const loading = useSelector((state) => state?.UserReducer?.loading);

  const [mobileOtpMobileModal, setMobileOtpMobileModal] = useState(false);
  const [userMobileNumber, setUserMobileNumber] = useState(false);
  // const [showVerifyMobileBtn, setShowVerifyMobileBtn] = useState(false);
  // const [otpVerifyText, setOtpVerifyText] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [otpTimerStart, setOtpTimerStart] = useState(false);

  const userId = userData?._id;

  const mobileOtpClose = () => {
    setMobileOtpMobileModal(false);
    setOtpTimerStart(false);
  };
  const mobileVeryfyFunction = (mobileNumber) => {
    let mobileNumberAllDetails = mobileNumber
      ? parsePhoneNumber(mobileNumber)
      : "";
    // console.log(parsePhoneNumber(mobileNumber), 57);
    setUserMobileNumber(mobileNumber);
    const data = new URLSearchParams();
    data.append("mobileNumber", mobileNumberAllDetails?.nationalNumber);
    data.append(
      "countryCode",
      mobileNumberAllDetails?.countryCallingCode
        ? mobileNumberAllDetails?.countryCallingCode
        : ""
    );
    data.append(
      "country",
      mobileNumberAllDetails?.country ? mobileNumberAllDetails?.country : ""
    );
    // data.append("mobileNumber", mobileNumber);

    data.append("userId", userData?._id);
    DataService.post(Api.User.SEND_MOBILE_OTP, data)
      .then((res) => {
        // setIsDisabled(false);
        setMobileOtpMobileModal(true);
        setOtpTimerStart(true);
        toast.success(res?.data?.message, {
          toastId: "customId",
        });
        // navigate("/user");
        // dispatch(userProfile());
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          toastId: "customId",
        });
      });
  };
  let initialValues = {
    profileImage: "",
    name: userData?.fullName ? userData?.fullName : "",
    email: userData?.email ? userData?.email : "",
    mobileNumber: userData?.mobileNumber
      ? String(`+${userData?.countryCode || "91"}${userData?.mobileNumber}`)
      : "",
    // mobileNumber: userData?.mobileNumber ? userData?.mobileNumber : "",
    // country: userData?.country ? userData?.country : "",
    // countryCode: userData?.countryCode ? userData?.countryCode : "",
  };
  const [profileImage, setProfileImage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const handleEditDetails = (values) => {
    const formData = new FormData();
    let mobileNumberDetails = values.mobileNumber
      ? parsePhoneNumber(values.mobileNumber)
      : "";
    setIsDisabled(true);
    formData.append("fullName", values?.name);
    formData.append("profile", values?.profileImage);
    formData.append("email", values?.email);
    formData.append(
      "mobileNumber",
      mobileNumberDetails.nationalNumber
        ? mobileNumberDetails.nationalNumber
        : ""
    );
    formData.append(
      "countryCode",
      mobileNumberDetails?.countryCallingCode
        ? mobileNumberDetails?.countryCallingCode
        : ""
    );
    formData.append(
      "country",
      mobileNumberDetails?.country ? mobileNumberDetails?.country : ""
    );
    DataService.post(Api.User.EDIT_PROFILE, formData)
      .then((res) => {
        setIsDisabled(false);
        toast.success(res?.data?.message, {
          toastId: "customId",
        });
        navigate("/user");
        dispatch(userProfile());
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          toastId: "customId",
        });
        setIsDisabled(false);
      });
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  // const otpVeriFyFunctionCheck = (msg) => {
  //   setOtpVerifyText(msg);
  //   console.log(msg, 137);
  // };

  // const mobileCheck = (mobileNumber) => {
  //   if (mobileNumber && isValidPhoneNumber(mobileNumber)) {
  //     return "";
  //   }
  //   try {
  //     const number = mobileNumber
  //       ? parsePhoneNumber(mobileNumber)?.nationalNumber
  //       : "";
  //     console.log(number, userData?.mobileNumber, 179);
  //     return number;
  //   } catch (error) {
  //     return "";
  //   }
  // };

  // const mobileCheckLength = (mobileNumber) => {
  //   if (mobileNumber && isValidPhoneNumber(mobileNumber)) {
  //     return "";
  //   }
  //   try {
  //     const length = mobileNumber
  //       ? parsePhoneNumber(mobileNumber)?.nationalNumber?.length
  //       : "";
  //     console.log(length, 194);
  //     return length;
  //   } catch (error) {
  //     return "";
  //   }
  // };
  const getNationalNumber = (mobileNumber) => {
    try {
      let number = parsePhoneNumber(mobileNumber)?.nationalNumber;
      return number;
    } catch (error) {
      return "";
    }
  };
  return (
    <>
      <PageIndex.Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={userEditProfileSchema}
        onSubmit={handleEditDetails}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          setFieldTouched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* {console.log(
              values?.mobileNumber
                ? parsePhoneNumber(values?.mobileNumber)?.nationalNumber
                : "hh",
              194
            )} */}
            {/* {console.log(
              userData?.mobileNumber,
              values?email.mobileNumber?.substring(3),
              101
            )} */}
            {/* {console.log((values?.mobileNumber).length, "datatttt")} */}
            {loading ? (
              <Loader />
            ) : (
              <Index.Box
                className="tab-main-card-content"
                id="tab-main-card-download"
              >
                <Index.Box className="tab-main-pd-content">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                      className="grid-row-details"
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column grid-user-col"
                      >
                        <Item className="grid-item">
                          <Index.Box className="update-profile-details mb-profile-user">
                            <Index.Box className="flex-center center-update-profile-details max-profile-content ml-profile-left">
                              <label htmlFor="upload-photo">
                                <input
                                  style={{ display: "none" }}
                                  id="upload-photo"
                                  name="profileImage"
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => {
                                    if (e.target.files.length !== 0) {
                                      setProfileImage(e.target.files[0]);
                                      setFieldValue(
                                        "profileImage",
                                        e.target.files[0]
                                      );
                                    } else {
                                      setProfileImage("");
                                    }
                                  }}
                                />
                                <Index.Box className="max-profile-img-admin">
                                  <Index.Box className="profile-pos-details">
                                    <Index.EditIcon color="primary" />
                                  </Index.Box>
                                  {profileImage ? (
                                    <>
                                      <Index.Avatar
                                        alt="Remy Sharp"
                                        className="add-game-img"
                                        //src={URL.createObjectURL(image) || URL.createObjectURL(values.gameImage)  }
                                        src={
                                          profileImage &&
                                          URL.createObjectURL(profileImage)
                                        }
                                        // src={values?.gameImage ? process.env.REACT_APP_IMG +values?.gameImage : URL.createObjectURL(image)}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Index.Avatar
                                        alt="Remy Sharp"
                                        className="add-game-img"
                                        src={
                                          userData?.profile &&
                                          process.env.REACT_APP_IMG +
                                            userData?.profile
                                        }
                                      />
                                    </>
                                  )}
                                </Index.Box>
                              </label>
                            </Index.Box>
                            {errors.profileImage && (
                              <Index.FormHelperText error>
                                {errors.profileImage}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Item>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column grid-user-col"
                      >
                        <Item className="grid-item">
                          <Index.Box className="form-group-main deposit-form-content setting-pass-main mb-30px-form">
                            <Index.FormHelperText className="title-label-comman-user">
                              Name
                            </Index.FormHelperText>
                            <Index.Box className="form-control-details-auth">
                              <Index.Box className="icon-position-rel">
                                <Index.TextField
                                  className="form-control custom-auth-user-control"
                                  type="text"
                                  name="name"
                                  placeholder="Enter name"
                                  onBlur={handleBlur}
                                  value={values?.name}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^[A-Za-z][A-Za-z\s]*$/;

                                    if (regex.test(value) || value === "") {
                                      setFieldValue("name", value.slice(0, 35));
                                    }
                                  }}
                                  inputProps={{
                                    maxLength: 35,
                                  }}
                                />

                                {errors.name && (
                                  <Index.FormHelperText error>
                                    {errors.name}
                                  </Index.FormHelperText>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column grid-user-col"
                      >
                        <Item className="grid-item">
                          <Index.Box className="form-group-main deposit-form-content setting-pass-main mb-30px-form">
                            <Index.FormHelperText className="title-label-comman-user">
                              Email
                            </Index.FormHelperText>
                            <Index.Box className="form-control-details-auth">
                              <Index.Box className="icon-position-rel">
                                <Index.TextField
                                  className="form-control custom-auth-user-control"
                                  name="email"
                                  placeholder="Enter email"
                                  helperText={touched.email && errors.email}
                                  error={Boolean(errors.email && touched.email)}
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === " " &&
                                      e.target.value.trim() === ""
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  value={values?.email}
                                  onChange={handleChange}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column grid-user-col"
                      >
                        <Item className="grid-item">
                          <Index.Box className="form-group-main deposit-form-content setting-pass-main mb-30px-form">
                            <Index.FormHelperText className="title-label-comman-user">
                              Mobile Number
                            </Index.FormHelperText>
                            <Index.Box className="form-control-details-auth">
                              <Index.Box className="icon-position-rel">
                                <PhoneInput
                                  className="form-control custom-auth-user-control profile-mobile-no-color"
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="IN"
                                  value={values.mobileNumber}
                                  onChange={(e) => {
                                    setFieldValue("mobileNumber", e);
                                    // setIsMobileVerified(true);
                                    if (!errors.mobileNumber) {
                                      // setShowVerifyMobileBtn(true);
                                    }

                                    // setUserMobileNumberCheck(() => {
                                    //   try {
                                    //     let number =
                                    //       parsePhoneNumber(e)?.nationalNumber;
                                    //     return number;
                                    //   } catch (error) {
                                    //     return "";
                                    //   }
                                    // });
                                  }}
                                />
                              </Index.Box>
                              <div className="error-text text-start">
                                {errors.mobileNumber}
                              </div>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column grid-user-col"
                      >
                        <Item className="grid-item">
                          <Index.Box className="form-group-main deposit-form-content setting-pass-main mb-30px-form">
                            <Index.FormHelperText className="title-label-comman-user"></Index.FormHelperText>
                            <Index.Box className="form-control-details-auth profile-otp-verify-main">
                              <Index.Box className="icon-position-rel">
                                {/* {values.mobileNumber.length == 12 &&
                                  errors.mobileNumber == "" && (
                                    <PageIndex.BlueButton
                                      btnLabel="Verify"
                                      className="blue-btn-content profile-top-verify"
                                      onClick={() => {
                                        mobileVeryfyFunction();
                                      }}
                                    />
                                  )} */}

                                {/* {values.mobileNumber &&
                                isValidPhoneNumber(values.mobileNumber) &&
                                !isMobileVerified ? (
                                  <PageIndex.BlueButton
                                    btnLabel="Verify"
                                    className="blue-btn-content profile-top-verify"
                                    onClick={() => {
                                      mobileVeryfyFunction(
                                        values?.mobileNumber
                                      );
                                    }}
                                  />
                                ) : isMobileVerified ? (
                                  <PageIndex.BlueButton
                                    btnLabel="Verified"
                                    disabled
                                    className="blue-btn-content profile-top-verified"
                                  />
                                ) : (
                                  ""
                                )} */}

                                {/* {(userData?.mobileNumber !=
                                  values?.mobileNumber?.substring(3) &&
                                  !errors?.mobileNumber) ||
                                (!userData?.mobileNumber &&
                                  values?.mobileNumber?.length == 13 &&
                                  !errors?.mobileNumber) ? (
                                  <PageIndex.BlueButton
                                    btnLabel="Verify"
                                    className="blue-btn-content profile-top-verify"
                                    onClick={() => {
                                      mobileVeryfyFunction(
                                        values?.mobileNumber
                                      );
                                    }}
                                  />
                                ) : userData?.mobileNumber != "" ||
                                  (userData?.isConnected &&
                                    userData?.mobileNumber != "") ||
                                  (userData?.mobileNumber ==
                                    values?.mobileNumber?.substring(3) &&
                                    values?.mobileNumber != "") ? (
                                  <PageIndex.BlueButton
                                    btnLabel="Verified"
                                    disabled
                                    className="blue-btn-content profile-top-verified"
                                  />
                                ) : (
                                  ""
                                )} */}

                                {(userData?.mobileNumber !=
                                  getNationalNumber(values?.mobileNumber) &&
                                  !errors?.mobileNumber) ||
                                (!userData?.mobileNumber &&
                                  values?.mobileNumber &&
                                  isValidPhoneNumber(values?.mobileNumber) &&
                                  !errors?.mobileNumber) ? (
                                  <PageIndex.BlueButton
                                    btnLabel="Verify"
                                    className="blue-btn-content profile-top-verify"
                                    onClick={() => {
                                      mobileVeryfyFunction(
                                        values?.mobileNumber
                                      );
                                    }}
                                  />
                                ) : userData?.mobileNumber != "" ||
                                  (userData?.isConnected &&
                                    userData?.mobileNumber != "") ||
                                  (userData?.mobileNumber ==
                                    getNationalNumber(values?.mobileNumber) &&
                                    values?.mobileNumber &&
                                    isValidPhoneNumber(
                                      values?.mobileNumber
                                    )) ? (
                                  <PageIndex.BlueButton
                                    btnLabel="Verified"
                                    disabled
                                    className="blue-btn-content profile-top-verified"
                                  />
                                ) : (
                                  ""
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Item>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column grid-user-col"
                      >
                        <Index.Box className="betting-card-btn-comman max-betting-card ">
                          {/* {(userData?.mobileNumber &&
                            values?.mobileNumber != "" &&
                            userData?.mobileNumber ==
                              values?.mobileNumber?.substring(3)) ||
                          values?.mobileNumber == "" ? (
                            <PageIndex.BlueButton
                              btnLabel="Submit"
                              className="blue-btn-content"
                              type="submit"
                              disabled={isDisabled}
                            />
                          ) : (
                            ""
                          )} */}
                          <PageIndex.BlueButton
                            btnLabel="Submit"
                            className="blue-btn-content"
                            type="submit"
                            // disabled={isDisabled}
                            //   disabled={isDisabled || (userData?.mobileNumber &&
                            //  values?.mobileNumber &&
                            //           isValidPhoneNumber(
                            //             values?.mobileNumber
                            //           ) != "" &&
                            //   userData?.mobileNumber ==
                            //     getNationalNumber(values?.mobileNumber)) ||
                            //   values?.mobileNumber &&
                            //           isValidPhoneNumber(
                            //             values?.mobileNumber
                            //           )  == "")}
                            disabled={
                              isDisabled ||
                              (values?.mobileNumber &&
                                isValidPhoneNumber(values?.mobileNumber) &&
                                !errors?.mobileNumber &&
                                isMobileVerified) ||
                              (userData?.mobileNumber == "" &&
                                getNationalNumber(values?.mobileNumber)) ||
                              getNationalNumber(values?.mobileNumber) !=
                                userData?.mobileNumber
                            }
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            )}
          </form>
        )}
      </PageIndex.Formik>
      <Index.Box className="otp-main-component">
        <MobileNumberOtpModal
          mobileOtpMobileModal={mobileOtpMobileModal}
          mobileOtpClose={mobileOtpClose}
          userId={userId}
          userMobileNumber={userMobileNumber}
          // setOtpVerifyText={setOtpVerifyText}
          otpTimerStart={otpTimerStart}
          setOtpTimerStart={setOtpTimerStart}
          setIsMobileVerified={setIsMobileVerified}
        />
      </Index.Box>
    </>
  );
};

export default UserProfile;
