import React from 'react'

export default function Penaltymens() {
        return (
                <svg width="1245" height="3634" viewBox="0 0 1245 3634" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="men-animation"  d="M1049.1 3595.6C995.1 3609.3 900.7 3612.1 865.4 3587C856.2 3580.4 851 3572 851.6 3561.3C853.8 3524.6 865.7 3486.1 874.3 3425C877.8 3400.5 880.7 3372.4 882.4 3339.3C888.1 3223.6 756.2 2949.7 767.7 2839.3C773.4 2784 776.2 2737.6 776.5 2697.3C776.8 2664.7 775.5 2636.1 772.8 2609.8C781 2611.7 929.6 2645.6 996.7 2566.2C1001 2590 1006.1 2617.9 1011.5 2649.7C1017.4 2684.7 1023.7 2724.4 1029.8 2768.5C1049.2 2909.6 1038.1 3180.5 1038.8 3277.1C1039.1 3314 1050.5 3366.7 1060.8 3418.7C1077.6 3502.7 1091.1 3584.9 1049.1 3595.6Z" fill="#FFB58B" />
                        <path class="men-animation" d="M1062.6 3418.1C1062.4 3418.2 1061.8 3418.4 1060.9 3418.7C1058 3419.7 1051.7 3421.9 1043 3424.6C1008.2 3435.2 935.3 3453.4 890.9 3434.9C884.8 3432.4 879.2 3429.1 874.4 3425.1C873.9 3424.7 873.4 3424.2 872.9 3423.8C872.9 3423.8 873.2 3413.8 873.2 3397.2C873.2 3351.8 870.6 3257.3 851.6 3183.7C841.2 3143.2 803.2 3039.7 779.1 2954.5V2954.4C767.6 2913.8 759.2 2877.4 758.4 2853.9C758.4 2853.5 758.4 2853.2 758.4 2852.8C757.7 2823.6 759.8 2786.3 762.6 2748.9C762.6 2748.6 762.6 2748.3 762.7 2748.1C764 2731.3 765.4 2714.5 766.7 2698.4C766.7 2698.3 766.7 2698.1 766.7 2698C769.7 2662.2 772.4 2630.1 772.6 2609.7C772.6 2609.7 772.7 2609.7 772.9 2609.8C781.1 2611.7 929.7 2645.6 996.8 2566.2C997.2 2565.7 997.6 2565.2 998 2564.7C998 2564.7 1016 2641.2 1038.1 2737C1039.1 2741.2 1039.9 2745.9 1040.7 2751.1C1057.8 2862.6 1034.6 3185.1 1042.8 3279C1042.8 3279.7 1042.9 3280.4 1043 3281C1052.1 3376.4 1062.6 3418.1 1062.6 3418.1Z" fill="#DEDDD7" />
                        <path class="men-animation" d="M772.6 2609.7L769.3 2669.3C769.3 2669.3 847.1 2685.3 922.5 2669.3C997.9 2653.3 1011.2 2620.9 1011.2 2620.9L998 2564.8C998 2564.7 925.3 2627.4 772.6 2609.7Z" fill="#323255" />
                        <path class="men-animation" opacity="0.28" d="M769.2 2669.2C769.2 2669.2 780.6 2703.8 778.5 2720.6C776.6 2736.6 788.7 2812.7 793.3 2854.2C807 2977.5 849.9 3085.1 872.1 3154.5C894.3 3223.9 906.8 3351.8 891.8 3429.8C876.8 3507.8 860 3549.7 863.1 3575.8C866.2 3601.9 876.6 3603.9 876.6 3603.9C876.6 3603.9 859.7 3605.2 855.1 3597.2C850.5 3589.2 843.8 3569 845.3 3550.6C845.3 3550.6 836.2 3539.5 850.5 3503C864.8 3466.5 873.3 3427.5 873.5 3376C873.7 3324.5 869.1 3244 846.4 3165C818.9 3069.4 758 2916.5 758 2852.9C758.3 2783.4 766.1 2705.4 769.2 2669.2Z" fill="#666666" />
                        <path class="men-animation" opacity="0.28" d="M1011.2 2643C1011.2 2643 1013.2 2687.7 1028.6 2776.6C1044 2865.5 1031.4 3018 1025.2 3111.2C1019 3204.4 1024.4 3379.9 1050.5 3449.3C1076.6 3518.7 1068.8 3554.9 1057.1 3578.3C1057.1 3578.3 1050.2 3595 1045.7 3601.5C1045.7 3601.5 1079.8 3585 1080.7 3569.6C1081.6 3554.2 1080.7 3550.6 1080.7 3550.6C1080.7 3550.6 1087.5 3533.7 1076.8 3482C1066.1 3430.3 1057.4 3397.2 1057.4 3397.2C1057.4 3397.2 1047.4 3342.1 1043 3281C1035.4 3174.8 1059.4 2831.4 1040.6 2750.2C1021.8 2669 1011.2 2620.7 1011.2 2620.7V2643Z" fill="#666666" />
                        <path class="men-animation"  d="M867.6 3599.3L872.4 3619.8C872.4 3619.8 873.7 3625.2 877.3 3625.3C882.4 3625.5 885.9 3626.7 887.9 3624.1C889.9 3621.5 887.9 3606 887.9 3606L867.6 3599.3Z" fill="white" />
                        <path  class="men-animation"  d="M1039.5 3603.9V3624.1C1039.5 3624.1 1042.7 3628.1 1046 3627.1C1049.3 3626.1 1058.2 3625.3 1060.2 3620C1062.2 3614.7 1064.7 3595.6 1064.7 3595.6L1039.5 3603.9Z" fill="white" />
                        <path  class="men-animation"  d="M912.6 3602.5C912.6 3602.5 916.6 3627.2 920.1 3630.8C923.6 3634.4 935.2 3635.7 938.6 3630.8C940.8 3627.7 939.4 3607.1 939.4 3607.1L912.6 3602.5Z" fill="white" />
                        <path  class="men-animation"  d="M980.7 3605C980.7 3605 980.6 3625 982 3627.9C983.4 3630.8 985.9 3635.6 997.1 3633.4C1008.3 3631.2 1009.4 3605 1009.4 3605H980.7Z" fill="white" />
                        <path class="men-animation" d="M845.6 3550.6C845.6 3550.6 842.2 3587.1 855.4 3597.1C885 3619.7 1039.7 3615.6 1070.9 3594.1C1086.3 3583.5 1080.8 3550.6 1080.8 3550.6C1080.8 3550.6 948.6 3619.4 845.6 3550.6Z" fill="#552F02" />
                        <path class="men-animation" d="M872.2 3397.2C872.2 3397.2 874.7 3422.2 864 3464.1C853.3 3506 835.8 3522.9 845.5 3550.5C855.2 3578.1 901.4 3594.2 963.7 3589.4C1026 3584.6 1070.4 3579.8 1080.7 3550.5C1091 3521.2 1059.4 3404.7 1057.3 3397.1C1055.2 3389.5 986.6 3435.7 872.2 3397.2Z" fill="#F7931E" />
                        <path class="men-animation" d="M846 2746.9C846 2746.9 802.7 2776 828.4 2919.8C854.1 3063.6 920.2 3243.4 928.7 3298.2C937.2 3353 959.5 3366.7 956.1 3286.2C952.7 3205.7 920.7 3002 912.7 2918.1C904.7 2834.2 905.4 2707.5 846 2746.9Z" fill="#EBEBEB" />
                        <path class="men-animation" d="M872.2 3532.3C872.2 3532.3 875.5 3556.7 904.4 3561.8C933.3 3566.9 1028.7 3564.9 1047.1 3549.1C1065.5 3533.3 1075 3520.4 1060 3498.6C1045 3476.8 1034.1 3414.5 1034.1 3414.5C1034.1 3414.5 940.1 3428.7 904.4 3414.5C904.4 3414.5 906.3 3446.3 882.7 3486.4C866.2 3514.8 872.2 3532.3 872.2 3532.3Z" fill="#FBB03B" />
                        <path class="men-animation"  d="M882.8 3589.6C882.8 3589.6 886.5 3603 897.3 3604.9C908.1 3606.8 982.9 3610.3 1016 3604.9C1049.1 3599.5 1057.4 3595.5 1057 3578.1C1057.1 3578.2 990.3 3605 882.8 3589.6Z" fill="#6C6E35" />
                        <path class="men-animation" d="M872.4 3409.4C872.4 3409.4 868.1 3395.6 873.4 3393.3C878.7 3391 902 3408.8 951 3409.4C1000 3410 1050.5 3392.5 1056.5 3393.1C1062.5 3393.7 1062.5 3402.8 1059.8 3404.3C1057.1 3405.8 1041.2 3419.9 968 3421.1C894.8 3422.3 871.8 3400.4 872.4 3409.4Z" fill="#573A0C" />
                        <path class="men-animation" d="M796.1 2663.9C796.1 2663.9 791.5 2628.9 810.6 2620.8C819.7 2616.9 875.3 2620.8 932.4 2602C975.6 2587.8 981.6 2634.2 961.3 2649.4C941 2664.6 830.3 2681.4 796.1 2663.9Z" fill="#68698E" />
                        <path class="men-animation" d="M1021.6 3429.3C1021.6 3429.3 1019.8 3474.3 1033.5 3489.5C1047.2 3504.7 1044.2 3544.3 1033.5 3547.8C1022.8 3551.3 1002.3 3553.4 1002.3 3553.4C1002.3 3553.4 1033.5 3531.8 1022.5 3511C1011.5 3490.2 992.4 3440.1 1002.3 3433.5C1008 3429.5 1014.9 3428 1021.6 3429.3Z" fill="#FDD089" />
                        <path class="men-animation"  d="M842.7 3530.8C842.7 3530.8 869.8 3574.6 952.9 3574.6C1036 3574.6 1073.1 3552 1082.8 3527.9C1082.8 3527.9 1090 3553.6 1067.5 3567.9C1045 3582.2 939.9 3603.9 878.2 3579.7C840.1 3564.8 842.7 3530.8 842.7 3530.8Z" fill="#B96E16" />
                        <path d="M38.9 1114.3C38.9 1114.3 37.1 1229.6 19.5 1302.2C-19.2 1462.4 59.6 1623.6 7.20004 1819.7C-3.89996 1861.1 8.60004 1917.3 38.1 1957.6C57.6 1984.2 90.4 2000.2 108 2014C152.2 2048.7 139.7 1962.8 128.7 1936.3C117.7 1909.8 130.1 1852.8 146.7 1866C163.3 1879.2 141.1 1900.5 174.3 1921.7C207.5 1942.9 191.8 1914.6 194.6 1893.4C197.4 1872.2 178.9 1792.8 155.7 1757.4C108.6 1685.7 188.6 1501.8 190 1423.7C191.4 1345.6 191.8 1330.4 189.1 1296C186.4 1261.6 215.8 1175.1 215.8 1175.1L38.9 1114.3Z" fill="#FFB58B" />
                        <path d="M38.3 1181.7L33.1 1213.3C33.1 1213.3 120.4 1236.3 156.4 1224.4C192.4 1212.5 163.3 1260.5 163.3 1339.3C163.3 1418.1 153 1501 137.6 1597.4C122.2 1693.8 127.1 1735.4 150.7 1775.7C174.3 1816 190.3 1861.7 190.3 1880C190.3 1898.3 193.5 1913.9 191.8 1917.5C190.1 1921.1 175.4 1918.3 168.8 1910.5C162.2 1902.7 161.4 1885 156.2 1873.8C151 1862.6 149.7 1856.5 138.5 1855.7C127.3 1854.9 107.3 1863.9 104.7 1903.6C102.1 1943.3 122.4 1963.3 127.5 1982.4C132.6 2001.5 135.2 2007.2 127.8 2012.4C120.4 2017.6 91.3 1998.8 72 1989.6C72 1989.6 108.9 2015.8 112.6 2018.9C116.3 2022 133.1 2029.5 136.8 2011.5C140.5 1993.5 140.9 1971.8 132 1940.4C123.1 1909 127.4 1882.1 132.6 1875.3C137.7 1868.5 142.9 1862.5 148 1869.9C153.1 1877.3 152.9 1888.7 152.9 1895.9C152.9 1903.1 168 1922.2 182 1929.3C196 1936.4 196.6 1928.5 195.4 1915.3C194.2 1902.1 196.5 1883.9 194.5 1870.7C192.5 1857.5 177.5 1798.6 162.3 1769.1C147.1 1739.6 143.9 1730.6 142.7 1721.6C141.5 1712.6 141.8 1658.9 146.3 1634.6C150.8 1610.3 189 1471.2 189.9 1420.4C190.8 1369.6 188.4 1278.2 192.6 1259.2C196.8 1240.2 215.6 1171.6 215.6 1171.6L38.3 1181.7Z" fill="#FF9670" />
                        <path d="M1025 1169.8C1025 1169.8 1054.9 1214.8 1042 1299.5C1014 1483.2 1113.9 1575.6 1107.8 1747.5C1106.5 1783.8 1083.2 1797.6 1078 1837.4C1072.8 1877.2 1082 1886.6 1069.4 1919C1056.8 1951.4 1104.5 1933.2 1115.3 1894.2C1133.9 1827.3 1134.2 1866.5 1141.9 1883.9C1152.1 1907.1 1139.9 1929.3 1121.7 1957.7C1103.5 1986.1 1114 2016.2 1148.8 2001.1C1183.6 1986 1243.8 1942.9 1244.5 1868.3C1245.2 1793.7 1222.4 1786.7 1224.1 1711.1C1227.9 1534.8 1230 1386.9 1205 1304.3C1189.4 1252.5 1184.9 1139.7 1184.9 1139.7L1025 1169.8Z" fill="#FFB58B" />
                        <path d="M1186.7 1171.5L1188.4 1194.3C1188.4 1194.3 1122.5 1212.5 1088.3 1213.3C1054.1 1214.1 1063.2 1260.5 1061.7 1287.9C1060.2 1315.3 1049.5 1421.1 1068.5 1482.7C1087.5 1544.3 1118.7 1665.4 1119.5 1695.8C1120.3 1726.2 1120.3 1777.2 1106.6 1791.7C1092.9 1806.2 1086 1852.6 1085.3 1871.6C1084.6 1890.6 1078.4 1919.2 1073.1 1921.7C1067.8 1924.2 1079.2 1938.3 1096.7 1918C1114.2 1897.7 1121.9 1852.6 1134.8 1844.9C1147.7 1837.2 1179.3 1833.3 1181.6 1875.1C1183.9 1916.9 1175.1 1930.9 1152.3 1956.8C1129.5 1982.7 1124.6 1997.1 1134.8 1999.8C1145 2002.5 1170.6 1989.4 1170.6 1989.4C1170.6 1989.4 1138.6 2012.8 1118 2005C1097.4 1997.2 1114.6 1969.2 1121.8 1957.6C1129 1946 1147.5 1910.6 1142 1888.3C1136.5 1866 1132.5 1856 1129.4 1858.9C1126.3 1861.8 1118.4 1889.1 1111.6 1904.1C1104.8 1919.1 1090.5 1932 1079.5 1934.7C1068.5 1937.4 1066.4 1934 1067.7 1924.5C1069 1915 1077.1 1901 1076.4 1886.4C1075.7 1871.8 1072.3 1831.6 1089.2 1801.2C1106.1 1770.8 1111.5 1745.6 1107.2 1705.9C1102.9 1666.2 1051.5 1501.1 1041 1420.7C1030.5 1340.3 1043.6 1294.5 1044.4 1278.6C1045.2 1262.7 1023.1 1190.6 1014.5 1171.4C1005.9 1152.2 1186.7 1171.5 1186.7 1171.5Z" fill="#FF9670" />
                        <path d="M996.8 2566.1C929.7 2645.5 781.1 2611.6 772.9 2609.7C766.6 2546.8 752.8 2497.4 735.3 2440.2C703.3 2335.8 717.7 2257.6 717.7 2257.6L972.1 2250C972.1 2250 980.3 2445.5 983.5 2483.9C984.9 2500.6 989.9 2528.2 996.8 2566.1Z" fill="#FFB58B" />
                        <path d="M495.5 2257.9C495.5 2257.9 493.5 2301.9 470.6 2373.9C449.5 2440.2 419 2526 419.5 2598.2L199.3 2591C211.9 2556.9 223.8 2528.9 224.6 2459.3C226.9 2274.5 238.1 2192 238.1 2192L495.5 2257.9Z" fill="#FFB58B" />
                        <path d="M233.1 2236.9C233.1 2236.9 229.9 2247.2 228.2 2285.7C226.5 2324.2 416.8 2337.6 432.2 2346.2C447.6 2354.8 427 2414.7 411.6 2483.2C396.2 2551.7 407.8 2586.7 407.8 2586.7L422.2 2584.3C422.2 2584.3 433.6 2512.4 446.6 2468.3C459.6 2424.2 499.6 2327.4 497.8 2277.8C496 2228.2 233.1 2236.9 233.1 2236.9Z" fill="#FF9670" />
                        <path d="M782 2610.6C782 2610.6 802.4 2551.6 781.6 2445.5C760.8 2339.4 741.1 2338.5 848.2 2329.1C952.1 2320 974.2 2298.3 974.2 2298.3L972.1 2250.2L707.3 2293C707.3 2293 726.6 2408.9 738.6 2453.4C750.6 2497.9 775.8 2576.7 772.5 2609.6L782 2610.6Z" fill="#FF9670" />
                        <path d="M812.8 265.9C812.8 265.9 842.5 269.6 840.2 313C837.9 356.4 772.1 396.8 772.1 396.8C772.1 396.8 785.4 294.2 812.8 265.9Z" fill="#FFB58B" />
                        <path d="M456.6 271.9C456.6 271.9 411 251.3 404.1 292.8C397.2 334.3 435.4 382.9 466.5 395.2C497.6 407.5 456.6 271.9 456.6 271.9Z" fill="#FFB58B" />
                        <path d="M273.8 1673.4C273.8 1673.4 237.4 1703.4 237.4 1831.2C237.4 1959 204.9 2140.2 205.2 2250C205.2 2250 230.6 2305 377.3 2305C524 2305 529.9 2290 528 2200.5C526.3 2120 586.1 1989.4 586.1 1989.4C586.1 1989.4 616.2 2109.8 639.1 2132.6C662 2155.4 670.4 2265.8 670.4 2265.8C670.4 2265.8 701.5 2322.6 821.9 2311.1C959 2298 992.1 2284.8 992 2254C991.8 2194.1 977.2 2098.4 974.9 1914.6C973.4 1796.2 963.5 1811.4 957.8 1747.2C954 1704.3 949.8 1673.4 949.8 1673.4C949.8 1673.4 320.6 1657.1 273.8 1673.4Z" fill="#1B1464" />
                        <path d="M251.9 1711C251.9 1711 239.3 1754.5 237.5 1820.6C235.7 1886.7 251.9 1806.5 285.4 1765.8C318.9 1725.1 487.2 1727.7 531.3 1729.3C575.4 1730.9 832.6 1737.6 865.4 1750.6C898.2 1763.6 920.6 1744.2 937.3 1764C954 1783.8 969.8 1856.9 975.1 1921.8C975.1 1921.8 976 1853.5 975.1 1826.8C974.2 1800.1 963.6 1749.2 963.6 1714.9C963.5 1714.8 355.4 1652.4 251.9 1711Z" fill="#140F4B" />
                        <path d="M493.2 403.1C493.2 403.1 522.9 568.1 465.8 659.9C403.3 760.5 797 756.7 782.3 699C742.1 541.1 746.5 436.2 746.5 436.2L493.2 403.1Z" fill="#FF9670" />
                        <path d="M504.6 619.4C504.6 619.4 364.6 675.6 265.6 686.1C188.5 694.3 103.2 731.7 76.7 761.4C21.9 822.7 13.3 1057.5 7.80002 1074.4C-0.699984 1100.3 -4.29998 1169 7.80002 1180.6C50 1220.7 232.7 1202.5 232.9 1171.5C233.5 1096.9 228.6 962.4 253.5 998C278.4 1033.6 309.4 1435.1 273.9 1538.2C238.4 1641.3 167.2 1744.3 384 1719.4C600.8 1694.5 792.9 1726.9 892.4 1723.2C991.9 1719.5 995.5 1694.5 995.5 1694.5C995.5 1694.5 995.5 1669.2 967.1 1569.9C938.7 1470.6 960 908.8 960 908.8C960 908.8 975 971.1 975.4 989.9C976.5 1047 998.1 1188.7 1041 1195.4C1099.3 1204.3 1159 1197.6 1213.8 1176C1238.9 1166.3 1235.3 1130.2 1219.9 1062.6C1213.3 1033.8 1207.6 1015.8 1200.1 951.5C1194.9 906.2 1188.3 775.3 1141.9 726.2C1124.1 707.4 1043.7 670 957.9 660.6C832.4 646.9 729.9 636.5 504.6 619.4Z" fill="#2E3192" />
                        <path d="M503.2 477.9C503.2 477.9 459.7 392 421.1 198.9C384.3 15.3999 594.6 31.3999 718.4 3.09995C811.3 -18.2001 777.7 77.8999 777.7 77.8999C777.7 77.8999 806.6 123.8 822.9 175.4C839.2 227 753.5 447.7 753.5 447.7C753.5 447.7 735.2 513.9 620.9 518.5C586 519.8 546 522.5 503.2 477.9Z" fill="#603813" />
                        <path d="M232.9 1171.5C232.9 1171.5 189.2 1200.8 197.5 1182.9C214.4 1146.6 222.8 1081 214 1053.9C203.9 1022.7 230.1 992.3 243.2 967.1C271.7 912.3 272.3 980.8 290 1047.6C311.7 1129.3 327.2 1218.5 323.6 1282.5C322.7 1298.3 342.6 1305.9 352.5 1293.5C371.6 1269.6 397.7 1226.9 426.2 1151.4C426.2 1151.4 400.6 1270.4 358.5 1344.9C308.9 1432.7 336.2 1476.7 317.4 1540.1C298.6 1603.5 238.6 1665.1 295.1 1689.1C351.6 1713.1 401.3 1717.5 401.3 1717.5C401.3 1717.5 274.6 1735.7 235.2 1717.5C235.2 1717.5 211.2 1706.2 245.5 1605.2C279.8 1504.2 287.6 1092 251.2 1020.7C243 1004.6 248.3 1085.3 232.9 1171.5Z" fill="#23256E" />
                        <path d="M916.7 862.1C916.7 862.1 930.4 903.2 916.7 1002.5C903 1101.8 899.6 1245.6 916.7 1315.8C933.8 1386 907.2 1502.5 905.1 1550.5C903 1598.5 976.3 1653 968.1 1684C959.9 1715 892.5 1723.2 892.5 1723.2C892.5 1723.2 987.9 1730.1 999.3 1693.5C1010.7 1656.9 981 1572.5 976.5 1545.8C972 1519.1 962 1325.9 959 1302.3C956 1278.7 955.2 1123.5 958.2 1090.7C961.2 1057.9 956.4 975.3 961 971.5C973 961.5 979 1101.4 979.5 1085.7C977.2 1154.2 975.6 1179.1 1007.8 1190.7C1040.1 1202.3 1141.9 1195.3 1141.9 1195.3C1141.9 1195.3 1036.6 1199.1 1016.1 1157.1C981.2 1085.7 1005.1 947 978.4 919C944.2 883.2 915 851 916.7 862.1Z" fill="#23256E" />
                        <path opacity="0.18" d="M529.4 1224.4C529.4 1224.4 458.4 1508.9 424.1 1569.5C389.8 1630.1 366.8 1581.9 385.9 1518.2C405 1454.5 478.9 1270.6 529.4 1224.4Z" fill="#8283BE" />
                        <path d="M725.3 494.1L738 571.4C738 571.4 665.3 562.2 530.9 567.2C510.1 568 533.2 519.1 533.2 519.1C533.2 519.1 644.3 551.5 725.3 494.1Z" fill="#FFB58B" />
                        <path opacity="0.18" d="M694.8 1253.2C694.8 1253.2 528.3 1596.4 586.5 1640.1C644.7 1683.8 692.8 1652.7 691.3 1602.4C689.8 1552.1 675.5 1301.8 694.8 1253.2Z" fill="#8283BE" />
                        <path opacity="0.18" d="M786.3 1149.3C786.3 1149.3 780.5 1367.7 864 1501.7C881.9 1530.5 922.7 1437.9 880.7 1350.9C845.5 1278 786.3 1149.3 786.3 1149.3Z" fill="#8283BE" />
                        <path d="M291.5 682.5C291.5 682.5 451.1 634.8 471.4 609.7C491.7 584.6 811.7 594.9 852.4 619.4C918.6 659.2 979.1 663.5 979.1 663.5C979.1 663.5 493.6 746.2 291.5 682.5Z" fill="#23256E" />
                        <path d="M440.8 629C440.8 629 453.3 599.4 482.9 581.2C512.6 562.9 776.2 576 803.7 575C831.2 574 830.5 612.3 847.7 617.1C847.8 617.1 564.7 667.3 440.8 629Z" fill="#E6E6E6" />
                        <path opacity="0.18" d="M647.8 722.3C647.8 722.3 859.7 721.4 955.6 688.3C1051.5 655.2 1187.4 739.5 1178.5 840.4C1175.8 871.3 1115.4 898.9 957.8 769.6C942.7 757.1 884.8 730.8 647.8 722.3Z" fill="#8283BE" />
                        <path d="M825.6 2370.1C825.6 2370.1 841.6 2507.1 869.4 2523.1C897.2 2539.1 922.4 2519.4 943 2439.1C963.6 2358.8 956.5 2331.4 956.5 2331.4C956.5 2331.4 884.3 2364 825.6 2370.1Z" fill="#FFC4A2" />
                        <path d="M262.6 2342.7C262.6 2342.7 262.9 2498 293.6 2516.2C324.3 2534.4 368.8 2479.7 396.2 2363.2C396.1 2363.3 298.1 2360.6 262.6 2342.7Z" fill="#FFC4A2" />
                        <path d="M612.7 1932.5C612.7 1932.5 632.5 2053.9 650.7 2087.8C668.9 2121.7 825.5 2008.8 825.5 2008.8C825.5 2008.8 807.3 2031.9 774.3 2069.1C760.1 2085.1 725.2 2101.5 717.6 2123.5C692.5 2196.6 680.9 2235.9 728.6 2257.3C776.3 2278.7 953.7 2285.2 972.1 2250C990.5 2214.8 943.4 2048.3 976.3 1979.7C976.3 1979.7 992.2 2197.2 992 2220.1C991.8 2243 1003.4 2260.3 988.6 2274.9C973.8 2289.5 875.2 2326.8 761.8 2312.7C648.4 2298.6 670.6 2260.5 667.7 2239.2C664.8 2217.9 660.4 2163.4 646.1 2143.1C631.8 2122.8 625.3 2116.7 613.4 2080.7C601.5 2044.7 589.5 1996.2 582.5 2005.1C575.5 2014 552.5 2076.3 540.3 2117.2C528.1 2158.1 528.2 2211.8 528.1 2219C528 2226.2 534.5 2273.4 515.1 2288.2C495.7 2303 408.7 2307.7 337.3 2303.4C265.9 2299.1 221.9 2280 205.1 2249.9C188.3 2219.8 212.9 2130.5 222.3 2036.5C222.3 2036.5 302.4 1997.2 339 1950.4C339 1950.4 283.1 2038.9 255.7 2064.8C228.3 2090.7 216.6 2225.4 228.1 2241.4C239.6 2257.4 306.6 2275.6 358.4 2273.4C410.2 2271.2 484 2272.8 502.3 2235.5C520.6 2198.2 509.2 2147.9 519.8 2112.9C530.5 2077.9 538.1 2042.9 538.1 2042.9C538.1 2042.9 499.7 2063.5 476.9 2090.1C454.1 2116.7 483.3 2046 531.3 2007.9C565 1981.1 582.3 1946.2 583.1 1930.3C583.9 1914.4 602.5 1924.1 612.7 1932.5Z" fill="#140F4B" />
                        <path opacity="0.18" d="M340 1790.2C340 1790.2 365.3 1893.4 408.7 1921.7C452.1 1950 567.8 1851.8 604.7 1826.7C641.6 1801.6 685 1832.1 678.1 1875.1C671.2 1918.1 651.6 2048.7 717.7 1980.5C786.2 1909.7 893.4 1872.4 897.6 1813.1C901.8 1753.8 748.1 1749.2 597.8 1751.5C447.5 1753.8 322.7 1736.8 340 1790.2Z" fill="#8283BE" />
                        <path opacity="0.18" d="M915 1974.6C915 1974.6 968.9 2155.9 936 2210.9C903.1 2265.9 795.6 2255.4 778.5 2221.2C761.4 2187 836.8 2164.7 823 2127C809.2 2089.3 923.6 2022.5 915 1974.6Z" fill="#8283BE" />
                        <path opacity="0.18" d="M388.2 2010.5C377.2 2019.3 259.2 2130.4 274.6 2192C290 2253.6 387.7 2245.7 426.2 2236.8C464.7 2227.9 492.2 2164.6 488.7 2142.3C485.2 2120 426 2169.7 381.1 2162.9C336.2 2156.1 406.5 2038.8 440.7 1999.8C474.9 1960.8 476.9 1939 388.2 2010.5Z" fill="#8283BE" />
                        <path opacity="0.18" d="M791.7 2008.8C791.7 2008.8 882.5 1878.6 911.6 1875.2C940.7 1871.8 925.7 1945.4 791.7 2008.8Z" fill="#8283BE" />
                        <path d="M440.8 118.7C440.8 118.7 441.2 79.4999 535.4 48.0999C629.6 16.6999 684.9 26.8999 727.2 17.2999C769.5 7.69989 769.4 45.7999 756.9 73.1999C744.4 100.6 661 88.0999 582.2 133.7C534.2 161.6 416.4 155.7 440.8 118.7Z" fill="#754C24" />
                        <path d="M492 222.2C492 222.2 510 168.1 620.8 164.1C731.6 160.1 783.1 183.2 760.2 259.5C737.3 335.8 735.1 422.5 676.9 443.1C618.7 463.7 559.3 469.4 505.7 324.4C476 244.1 492 222.2 492 222.2Z" fill="#754C24" />
                        <path opacity="0.18" d="M42.3 928.1C42.3 928.1 55.4 766.8 134.2 739.5C213 712.2 327.7 714.2 327.7 714.2C327.7 714.2 185 776.8 137 859.4C86.3 946.7 42.3 928.1 42.3 928.1Z" fill="#8283BE" />
                        <path opacity="0.18" d="M22.9 1151.7C22.9 1151.7 9.20003 1098 43.5 1074.1C77.8 1050.2 184.3 958.8 184.3 958.8C184.3 958.8 194.2 1047.8 141.7 1091.2C89.2 1134.6 36.6 1181.4 22.9 1151.7Z" fill="#8283BE" />
                        <path opacity="0.18" d="M1020.5 946C1020.5 946 1165.3 1017 1184.1 1058.2C1202.8 1099.3 1243.9 1154.1 1153.2 1149C1062.5 1143.9 1076.2 1008.5 1020.5 946Z" fill="#8283BE" />
                        <path d="M1096.5 1224.4C1096.5 1224.4 1114.8 1276.1 1154.2 1271C1193.6 1265.9 1171.9 1213.2 1171.9 1213.2C1171.9 1213.2 1127.8 1218.7 1096.5 1224.4Z" fill="#FFC4A2" />
                        <path d="M1162.2 1476.2C1162.2 1476.2 1140.2 1340.6 1171.9 1307.4C1219.1 1257.9 1206.7 1421.3 1210.1 1507C1213.5 1592.7 1202.7 1736.5 1212.9 1770.8C1223.1 1805.1 1253.3 1854.5 1232.5 1904.4C1211.7 1954.3 1187.3 1969.5 1172.7 1974.6C1158.1 1979.7 1169 1953.6 1188.4 1937.6C1207.8 1921.6 1210.1 1829.1 1188.4 1813.7C1166.7 1798.3 1152.2 1810.3 1133.8 1817.1C1115.4 1823.9 1146.2 1770.9 1151.3 1740C1156.4 1709.1 1175.2 1545.5 1162.2 1476.2Z" fill="#FFC4A2" />
                        <path d="M3.20001 1755.1C3.20001 1755.1 -7.39999 1697.1 11.9 1685.9C37.9 1670.8 155.1 1662.9 159.1 1676.9C163.1 1690.9 159.1 1738.6 159.1 1738.6C159.1 1738.6 3.20001 1778.7 3.20001 1755.1Z" fill="#9B9CCB" />
                        <path d="M15.8 1750.6C15.8 1750.6 8.50003 1695.8 22.4 1689.2C41.7 1680 118.5 1677.6 131.1 1678.9C143.7 1680.2 148.3 1724.7 138.4 1733.9C128.5 1743.1 18.6 1765.6 15.8 1750.6Z" fill="#B4B5D8" />
                        <path d="M21.8 1740.2C21.8 1740.2 18.7 1696.4 33.1 1689.8C47.5 1683.2 76.5 1683.5 76.5 1683.5C76.5 1683.5 82.5 1690.1 66.5 1691.8C50.5 1693.5 37.7 1697.4 36 1711.9C34.3 1726.4 29.6 1755.5 21.8 1740.2Z" fill="#CDCDE5" />
                        <path d="M134.2 3595.8L139 3616.3C139 3616.3 140.3 3621.7 143.9 3621.8C149 3622 152.5 3623.2 154.5 3620.6C156.5 3618 154.5 3602.5 154.5 3602.5L134.2 3595.8Z" fill="white" />
                        <path d="M320.5 3603.9V3624.1C320.5 3624.1 323.7 3628.1 327 3627.1C330.3 3626.1 339.2 3625.3 341.2 3620C343.2 3614.7 345.7 3595.6 345.7 3595.6L320.5 3603.9Z" fill="white" />
                        <path d="M193.7 3602.5C193.7 3602.5 197.7 3627.2 201.2 3630.8C204.7 3634.4 216.3 3635.7 219.8 3630.8C222 3627.7 220.6 3607.1 220.6 3607.1L193.7 3602.5Z" fill="white" />
                        <path d="M261.8 3605C261.8 3605 261.7 3625 263.1 3627.9C264.5 3630.8 267 3635.6 278.2 3633.4C289.4 3631.2 290.5 3605 290.5 3605H261.8Z" fill="white" />
                        <path d="M111.3 3564.4C111.3 3564.4 95.8 3586.3 121.8 3599.3C147.8 3612.3 316 3617.5 347.6 3602C379.2 3586.5 367.5 3564.4 367.5 3564.4H111.3Z" fill="#552F02" />
                        <path d="M369.1 3485.7C378.1 3591.3 301.7 3599.2 206.6 3584.7C111.5 3570.2 104 3581.2 123.9 3485.2C127.7 3467 141.4 3437.4 155.2 3403.2C167.3 3373.2 179.5 3339.6 185.2 3307C205.4 3191.9 175.6 3052.4 170.3 2865.3C165.3 2691.1 182.9 2635.7 199.4 2590.9L419.6 2598.1C419.7 2609.3 420.5 2620.1 422.2 2630.5C458 2847.7 361.3 3016.1 344.3 3165.5C337.9 3222.1 348.2 3304.4 358 3384.9C362.2 3419.7 366.3 3454 369.1 3485.7Z" fill="#FFB58B" />
                        <path d="M165.5 3365C165.5 3365 139.7 3434.2 125.9 3464.4C112.1 3494.6 67.4 3572.2 144.1 3584.9C220.8 3597.6 343.1 3599 367.5 3564.4C386.5 3537.5 375.5 3495.3 369 3462C363.8 3435.2 360.5 3411.5 360.5 3411.5C360.5 3411.5 180.8 3393.3 165.5 3365Z" fill="#F7931E" />
                        <path d="M358 3385C359.4 3402.3 360.6 3412.4 360.6 3412.4C360.6 3412.4 320.6 3443.2 238.6 3434.2C184.2 3428.2 162.1 3410.6 155.2 3403.2C153.3 3401.2 152.5 3400 152.5 3400C203.9 3264.5 163.6 3026.9 154.7 2864.4C145.8 2701.8 195.3 2590.8 195.3 2590.8L199.3 2590.9L419.5 2598.1H419.7C419.7 2598.1 421.4 2571.5 430.6 2712.1C439.8 2852.7 365.3 3087.5 359.5 3138C348.2 3234.9 354.1 3337.3 358 3385Z" fill="#DEDDD7" />
                        <path d="M213.6 2551C213.6 2551 185.1 2581.8 187.6 2612.3C190.1 2642.8 365.7 2682.8 425.6 2644.5C425.6 2644.5 429.1 2601.4 422 2584.3C422.1 2584.2 303.3 2610.2 213.6 2551Z" fill="#323255" />
                        <path d="M228.2 2626.6C228.2 2626.6 223 2585.5 245.1 2580.9C267.2 2576.3 274.8 2600.4 376 2595.6C376 2595.6 389.7 2598.4 388.2 2610.6C386.7 2622.8 385.2 2642.6 374.5 2645.6C363.8 2648.6 268.7 2648.6 228.2 2626.6Z" fill="#68698E" />
                        <path d="M149.5 3407.7C149.5 3407.7 145.4 3399.2 154.9 3393.3C154.9 3393.3 182.9 3421.8 251.2 3424.6C319.5 3427.4 359.8 3404.9 359.8 3404.9C359.8 3404.9 368.4 3406.1 361.9 3421.8C361.9 3421.8 329 3442.1 260.1 3440.9C191.2 3439.7 149.5 3407.7 149.5 3407.7Z" fill="#573A0C" />
                        <path d="M276.5 2873.5C276.5 2873.5 296.8 2689.5 355.5 2707C414.2 2724.5 347.6 2891.5 329 3013.4C310.4 3135.3 305.3 3339.7 307.8 3365C310.3 3390.3 290 3417.4 276.5 3349.7C263 3282 260.4 2958.6 276.5 2873.5Z" fill="#EBEBEB" />
                        <path d="M178.1 3430.7C178.1 3430.7 170.6 3467.2 152.4 3493.2C135.4 3517.6 125.6 3560.8 220.9 3559.2C316.2 3557.6 361.9 3557.6 352.7 3515C343.5 3472.4 332.8 3466.4 333.1 3446.6C333.1 3446.5 215.8 3455.2 178.1 3430.7Z" fill="#FBB03B" />
                        <path d="M187.7 3443.5C187.7 3443.5 181.3 3478.2 165.3 3494.2C149.3 3510.2 156 3548.1 200.5 3548C245 3547.9 216.2 3533.3 200.5 3516.4C184.8 3499.5 213.4 3454.3 213.4 3454.3C213.4 3454.3 196.8 3449.5 187.7 3443.5Z" fill="#FDD089" />
                        <path d="M155.2 3590.3C155.2 3590.3 147.9 3598.2 180.5 3602.5C213.1 3606.8 302.8 3605.6 316.1 3600.9C329.4 3596.2 330.5 3587.5 328.8 3587.9C301.5 3593.8 273.7 3596.7 245.8 3596.5C201.6 3596 155.8 3587.6 155.2 3590.3Z" fill="#6C6E35" />
                        <path d="M100.6 3535.9C100.6 3535.9 109.2 3558.8 166.5 3571C223.8 3583.2 342.8 3578.2 378.2 3535.9C378.2 3535.9 379.1 3563.7 354 3575.8C328.9 3587.9 294.8 3595.7 213.7 3592.2C132.6 3588.7 97.9 3580.4 100.6 3535.9Z" fill="#B96E16" />
                </svg>
        )
}
