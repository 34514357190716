import React, { useState } from "react";

import Index from "../../../../Index";
// import PageIndex from "../../../../pageIndex";

import PropTypes from "prop-types";
import UpiList from "./UpiList";
import BankDetails from "./BankDetails";
import BitcoinDetails from "./BitcoinDetails";
import CryptoAddress from "./CryptoAddress";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box className="tab-details-content-top">
          <Index.Box className="tab-details-inner">
            {children}
          </Index.Box>
        </Index.Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PaymentAdd = () => {
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  // const [paymentDetails, setPaymentDetails] = useState([]);
  const [paymentMethodTab, setPaymentMethodTab] = useState(0);
  // const [qrCode, setQrCode] = useState("");

  // Initital values
  // let initialValues = {
  //   UpiID: paymentDetails?.[0]?.UpiID ? paymentDetails?.[0]?.UpiID : "",
  //   qrCode: paymentDetails?.[0]?.qrCode ? paymentDetails?.[0]?.qrCode : "",
  // };

  // const handleFormSubmit = async (values) => {
  //   const formdata = new FormData();
  //   formdata.append("UpiID", values?.UpiID);
  //   formdata.append("qrCode", values?.qrCode);
  //   DataService.post(Api.ADMIN_PAYMENT_ADD, formdata)
  //     .then((res) => {
  //       if (res?.data?.status === 200) {
  //         toast.success(res?.data?.message);
  //         getPaymentList();
  //       }
  //     })
  //     .catch((e) => {
  //       toast.error(
  //         e.response?.data?.message ? e.response?.data?.message : e.message
  //       );
  //     });
  // };

  // get payment details
  // const getPaymentList = async () => {
  //   setLoading(true);
  //   await DataService.get(Api.ADMIN_GET_PAYMENT_DETAILS)
  //     .then((res) => {
  //       setPaymentDetails(res?.data?.data);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //     })
  //     .catch((e) => {
  //       toast.error(
  //         e.res?.data?.message ? e.res?.data?.message : e.message
  //         // navigate("/admin/login")
  //       );
  //       setLoading(false);
  //     });
  // };

  const handleChangePaymentMethodTab = (event, newValue) => {
    setPaymentMethodTab(newValue);
  };

  // useEffect(() => {
  //   getPaymentList();
  // }, []);
  return (
    <>
      {/* {loading ? (
        <Loader />
      ) : ( */}
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                {/* <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {paymentDetails?._id
                    ? "Edit Payment Method"
                    : "Payment Method"}
                </Index.Typography> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="tab-details-setting">
            <Index.Box sx={{ width: "100%" }} className="tab-account-user">
              <Index.Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="tab-border-user"
              >
                <Index.Tabs
                  value={paymentMethodTab}
                  onChange={handleChangePaymentMethodTab}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                  className="tab-content-user"
                >
                  <Index.Tab
                    label="UPI"
                    {...a11yProps(0)}
                    className="tab-btn-details"
                  />
                  <Index.Tab
                    label="Bank Account"
                    {...a11yProps(1)}
                    className="tab-btn-details"
                  />
                  <Index.Tab
                    label="Bitcoin"
                    {...a11yProps(2)}
                    className="tab-btn-details"
                  />
                  <Index.Tab
                    label="Crypto Address"
                    {...a11yProps(3)}
                    className="tab-btn-details"
                  />
                </Index.Tabs>
              </Index.Box>
              {/* update profile */}
              <CustomTabPanel
                value={paymentMethodTab}
                index={0}
                className="tabpanel-user-details"
              >
                <UpiList />
              </CustomTabPanel>
              <CustomTabPanel
                value={paymentMethodTab}
                index={1}
                className="tabpanel-user-details"
              >
                <BankDetails />
              </CustomTabPanel>
              <CustomTabPanel
                value={paymentMethodTab}
                index={2}
                className="tabpanel-user-details"
              >
                <BitcoinDetails />
              </CustomTabPanel>
              <CustomTabPanel
                value={paymentMethodTab}
                index={3}
                className="tabpanel-user-details"
              >
                <CryptoAddress />
              </CustomTabPanel>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* )} */}
    </>
  );
};
export default PaymentAdd;
