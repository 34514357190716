import React, { useEffect, useState } from "react";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { styled, alpha } from "@mui/material/styles";
import "../../../../assets/style/global.css";
import Index from "../../../Index";
import { useSelector } from "react-redux";
import Loader from "../../../../component/comman/loader/Loader";
import { useOutletContext } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const filterMaintenanceList = [
  { id: 1, name: "All", value: "all" },
  { id: 4, name: "Pending", value: "pending" },
  { id: 2, name: "Win", value: "successfully" },
  { id: 3, name: "Loss", value: "fail" },
];

const BetHistory = () => {
  const loading = useSelector((state) => state?.UserReducer?.loading);
  const [searchedData, setSearchedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [betHistory, setBetHistory] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [filterByPriority, setFilterByPriority] = useState("all");
  // const [date, setDate] = useState(null);
  const [gameList, setGameList] = useState([]);
  const [filterByGame, setFilterByGame] = useState("all");
  const {gameNewName} = useOutletContext();
  // const {
  //  gameList
  // } = useOutletContext();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const getGameList = async () => {
    await DataService.get(Api.User.USER_GAMES)
      .then((res) => {
        setGameList(res?.data?.data);
      })
      .catch((error) => {});
  };

  const getUserBetHistory = async () => {
    await DataService.get(Api.User.BET_HISTORY)
      .then((res) => {
        // setLoading(false);
        let data = res?.data?.data;
        setBetHistory(
          data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
        // setFilterData(res?.data?.data);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    setFilterData(betHistory);
  }, [betHistory]);

  useEffect(() => {
    getGameList();
    getUserBetHistory();
  }, []);

  let filtered;
  useEffect(() => {
    if (filterByPriority && filterByPriority !== "all") {
      filtered = betHistory?.filter(
        (ele) => ele?.status?.toLowerCase() === filterByPriority
      );
    } else {
      filtered = betHistory;
    }
    if (filterByGame && filterByGame !== "all") {
      filtered = filtered?.filter((ele) => ele?.gameId?._id === filterByGame);
    }
    setFilterData(filtered);
  }, [betHistory, filterByPriority, filterByGame]);

  useEffect(() => {
    if (!search) {
      setSearchedData(filterData);
    } else {
      const filtered = filterData?.filter((item) => {
        return (
          item?.betAmount
            ?.toString()
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          item?.period
            ?.toString()
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          gameNewName(item?.gameId?.gameName)
            ?.toString()
            ?.toLowerCase()
            .includes(search?.toLowerCase())
        );
      });

      if (filtered?.length > 0) {
        setSearchedData(filtered);
      } else {
        setSearchedData([]);
      }
    }
  }, [filterData, search]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Index.Box className="page-content-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex flex-w-100-transaction">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Bet History
                </Index.Typography>
              </Index.Box>

              <Index.Box className="d-flex align-items-center res-set-search gap-blue-btn-user">
                <Index.Box className="add-game-dropdown game-select">
                  <Index.Box className=" signin-drop-details">
                    <Index.FormControl className="formcontrol_login sign-in-inner-form">
                      <Index.Select
                        className="currency-select-drop"
                        displayEmpty
                        value={filterByGame}
                        label="Game"
                        onChange={(event, newValue) => {
                          setTimeout(() => {
                            setFilterByGame(event.target.value);
                          }, 1000);
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <Index.MenuItem
                          key={"all-games"}
                          value={"all"}
                          className="currency-select-menu"
                        >
                          All
                        </Index.MenuItem>
                        {gameList &&
                          gameList?.map((val) => (
                            <Index.MenuItem
                              key={val._id}
                              value={val._id}
                              className="currency-select-menu"
                            >
                              {gameNewName(val.gameName)}
                            </Index.MenuItem>
                          ))}
                      </Index.Select>
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="add-game-dropdown status-select">
                  <Index.Box className=" signin-drop-details">
                    <Index.FormControl className="formcontrol_login sign-in-inner-form">
                      <Index.Select
                        className="currency-select-drop"
                        displayEmpty
                        value={filterByPriority}
                        label="Status"
                        onChange={(event, newValue) => {
                          setTimeout(() => {
                            setFilterByPriority(
                              event.target.value?.toLowerCase()
                            );
                          }, 1000);
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {filterMaintenanceList &&
                          filterMaintenanceList?.map((val) => (
                            <Index.MenuItem
                              key={val.id}
                              value={val.value?.toLowerCase()}
                              className="currency-select-menu"
                            >
                              {val.name}
                            </Index.MenuItem>
                          ))}
                      </Index.Select>
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
                <Search className="search admin-search-comman cus-penalty-search-main">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => setSearch(e.target.value)}
                    className="cus-penalty-search"
                  />
                </Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="transaction-table-user-history">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container transaction-container-user"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main barge-table transaction-table-user dashboard-bet-table"
              >
                <Index.TableHead className="transaction-table-thead-user">
                  <Index.TableRow className="transaction-user-tr">
                    <Index.TableCell className="transaction-user-th">
                      Date and Time
                    </Index.TableCell>
                    <Index.TableCell className="transaction-user-th">
                      Period
                    </Index.TableCell>
                    <Index.TableCell
                      className="transaction-user-th"
                      align="left"
                    >
                      Game
                    </Index.TableCell>
                    <Index.TableCell
                      className="transaction-user-th"
                      align="left"
                    >
                      Selected Slot
                    </Index.TableCell>
                    <Index.TableCell
                      className="transaction-user-th"
                      align="left"
                    >
                      Bet Amount
                    </Index.TableCell>
                    <Index.TableCell
                      className="transaction-user-th"
                      align="left"
                    >
                      Win Amount
                    </Index.TableCell>
                    <Index.TableCell
                      className="transaction-user-th"
                      align="left"
                    >
                      Status
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {searchedData?.length ? (
                  searchedData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((ele) => {
                      return (
                        <Index.TableBody className="transaction-user-tbody">
                          <Index.TableRow className="transaction-user-tr">
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {ele?.createdAt
                                ? Index.moment(ele?.createdAt).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {ele?.period}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {gameNewName(ele?.gameId?.gameName)}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {["Number Betting", "Community Betting"].includes(
                                ele?.gameId?.gameName
                              )
                                ? (ele?.slotTime ? ele?.slotTime + " minutes" : "-")
                                : (ele?.selectedTime ? ele?.selectedTime + " seconds": "-")
                                }
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {ele?.betAmount}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {ele?.status === "pending"
                                ? "-"
                                : ele?.status?.toLowerCase() === "refund" ? "NA" : ele?.isWin
                                ? ele?.gameId?.gameName === "Community Betting"
                                  ? ele?.rewardAmount?.toFixed(8)
                                  : (ele?.betAmount * ele?.winningPercentage +
                                    (ele?.gameId?.gameName === "Number Betting"
                                      ? 0
                                      : ele?.betAmount))?.toFixed(8)
                                : "-" + ele?.betAmount}
                            </Index.TableCell>
                            {/* <Index.TableCell
                            align="left"
                            className="transaction-user-td"
                          >
                            {ele?.lossAmount}
                          </Index.TableCell> */}
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {ele?.status === "pending"
                                ? "Pending"
                                : ele?.status?.toLowerCase() === "refund"
                                ? "Refund"
                                : ele?.isWin ? "Win" : "Loss"}
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableBody>
                      );
                    })
                ) : (
                  <Index.NoDataFound colSpan={7} />
                )}
              </Index.Table>
              {searchedData?.length ? (
                <Index.TablePagination
                  className="paginationColor"
                  component="div"
                  page={page}
                  count={searchedData?.length}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : (
                ""
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}></Index.Stack>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default BetHistory;
