import React, { createContext, useContext, useEffect } from "react";
import { io } from "socket.io-client";
import { Api } from "../config/Api";

const socket = io(Api.common.BASE_URL);

const connectSocket = () => {
  if (!socket.connected) {
    socket.connect();
  }
};

const disconnectSocket = () => {
  if (socket.connected) {
    socket.disconnect();
  }
};

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  useEffect(() => {
    connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
