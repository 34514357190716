import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import Index from "../../../Index";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import Loader from "../../../../component/comman/loader/Loader";
import ManualDepositApprove from "../cms/paymentMethod/ManualDepositApprove";
import ManualDepositReject from "../cms/paymentMethod/ManualDepositReject";
import { toast } from "react-toastify";
// import "../../../../../assets/style/global.css";

// import ClearIcon from "@mui/icons-material/Clear";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  border: "0px",
  backgroundColor: "#090d29",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const filterMaintenanceList = [
  { id: 1, name: "All", value: "all" },
  { id: 4, name: "Pending", value: "Pending" },
  { id: 2, name: "Approved", value: "Approved" },
  { id: 3, name: "Rejected", value: "Rejected" },
  { id: 4, name: "Auto Approved", value: "Auto Approved" },
];

let cryptoShortName = {
  Bitcoin: "BTC",
  BNB: "BNB",
  Polygon: "PLG",
  Ethereum: "ETH",
  Tether: "USDT",
  "PolygonUSDT": "PolygonUSDT",
  "USDT(BNB)": "USDT(BNB)",
  "EthereumUSDT": "EthereumUSDT",
};

const Transaction = () => {
  const navigate = useNavigate();
  const permission = useSelector((state) => state.AdminReducer.adminRoleData);
  const [loading, setLoading] = useState(true);
  const [depositList, setDepositList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterByPriority, setFilterByPriority] = useState("all");
  const [search, setSearch] = useState("");
  const [searchedData, setSearchedData] = useState([]);

  const [date, setDate] = useState(null);
  const [openApprovedData, setOpenApprovedData] = useState(false);
  const [openRejectedData, setOpenRejectedData] = useState(false);

  const [approvedId, setApprovedId] = useState("");
  const [approveAmount, setApproveAmount] = useState("");
  const [rejectedId, setRejectedId] = useState("");

  const [showModal, setShowModal] = useState(true);
  const [openModalId, setOpenModalId] = useState();
  const [dateRange, setDateRange] = useState({ startRange: "", endRange: "" });

  const showDetails = (item, action) => {
    setOpenModalId(item?._id);
    if (openModalId === item?._id && action === "up") {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  const handleCloseApprovedData = () => setOpenApprovedData(false);

  const handleCloseRejectedData = () => setOpenRejectedData(false);
  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // image model

  // const [userImg, setUserImg] = useState("");
  // const [open, setOpen] = useState(false);
  // const handleOpen = (img) => {
  //   setOpen(true);
  //   setUserImg(img);
  // };
  // console.log(userImg, 122);
  // const handleClose = () => setOpen(false);

  // image model close

  const getTransactionRequest = async () => {
    try {
      const depositRes = await DataService.get(Api.ADMIN_GET_DEPOSIT_REQUEST);
      const withdrawRes = await DataService.get(Api.WITHDRAWAL_LIST);

      const combinedData = [...depositRes.data.data, ...withdrawRes.data.data];
      combinedData.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setDepositList(combinedData);
      setLoading(false);
    } catch (error) {
      toast.error(
        error.response?.data?.message
          ? error.response?.data?.message
          : error.message
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    setFilterData(depositList);
  }, [depositList]);

  useEffect(() => {
    getTransactionRequest();
  }, []);

  useEffect(() => {
    let filtered;
    if (filterByPriority && filterByPriority !== "all") {
      filtered = depositList?.filter(
        (ele) => ele?.status?.toLowerCase() === filterByPriority
      );
    } else {
      filtered = depositList;
    }
    // if (date) {
    //   filterDate = filtered.filter(
    //     (item) => Index.moment(item?.createdAt).format("DD/MM/YYYY") === date
    //   );
    //   setFilterData(filterDate);
    // } else {
    //   setFilterData(filtered);
    // }
    if (dateRange?.startRange && dateRange?.endRange) {
      const startRange = Index.moment(dateRange.startRange, "DD/MM/YYYY");
      const endRange = Index.moment(dateRange.endRange, "DD/MM/YYYY");

      const filterDate = filtered.filter((item) => {
        const itemDate = Index.moment(item?.createdAt);
        return itemDate.isBetween(startRange, endRange, "day", "[]"); // '[]' includes the start and end dates
      });

      setFilterData(filterDate);
    } else {
      setFilterData(filtered);
    }
  }, [depositList, filterByPriority, date, dateRange]);

  // Search on table new

  useEffect(() => {
    // {data.type == "Crypto Currency"
    //   ? data.tokenName
    //     ? data.tokenName
    //     : "-"
    //   : data?.userId?.currency
    //   ? data?.userId?.currency
    //   : data?.currency
    //   ? data?.currency
    //   : "-"}
    const filtered = filterData?.filter((item) => {
      return !search
        ? item
        : item?.userId?.fullName
            ?.toString()
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
            (item?.type === "Crypto Currency"
              ? item?.tokenName
                  ?.toString()
                  ?.toLowerCase()
                  .includes(search?.toLowerCase())
              : item?.userId?.currency
              ? item?.userId?.currency
                  ?.toString()
                  ?.toLowerCase()
                  .includes(search?.toLowerCase())
              : item?.currency
                  ?.toString()
                  ?.toLowerCase()
                  .includes(search?.toLowerCase())) ||
            item?.amount
              ?.toString()
              ?.toLowerCase()
              .includes(search?.toLowerCase()) ||
            item?.requestedAmount
              ?.toString()
              ?.toLowerCase()
              .includes(search?.toLowerCase()) ||
            item?.requestType
              ?.toString()
              ?.toLowerCase()
              .includes(search?.toLowerCase()) ||
            item?.tokenAmount
              ?.toString()
              ?.toLowerCase()
              .includes(search?.toLowerCase());
    });

    if (filtered?.length > 0) {
      setSearchedData(filtered);
    } else {
      setSearchedData([]);
    }
  }, [filterData, search]);


  const getNumTill8Dec = (n)=> {
    if(!n) return false;
    return Number(n).toFixed(8);
    // return Number.isInteger(Number(n)) ? n : Number(n).toFixed(8)
  }

  if (
    permission?.isAdmin === true ||
    (permission?.role?.DepositRequest?.View === true &&
      permission?.isAdmin === false)
  ) {
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Index.Box className="page-content-box">
              <Index.Box className="barge-common-box">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Transaction Request
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="d-flex align-items-center res-set-search deposit-req-main">
                      {/* <Search className="search deposit-list-search-status">
                      <Index.Box className="input-design-div deposit-req-with-border">
                        <Autocomplete
                          disablePortal
                          className="invoice-select-drop"
                          id="combo-box-demo"
                          options={filterMaintenanceList}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, option) => (
                            <Index.Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.name}
                            </Index.Box>
                          )}
                          defaultValue={filterMaintenanceList?.find(
                            (ele) => ele?.value == "all"
                          )}
                          onChange={(event, newValue) =>
                            handleStatusFilter(newValue?.value)
                          }
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Select"
                              variant="outlined"
                            />
                          )}
                        />
                      </Index.Box>
                    </Search> */}
                      {/* <Search className="search deposit-list-search-status deposit-req-filter">
                      <Index.Box className="input-design-div deposit-req-with-border">
                        <Autocomplete
                          disablePortal
                          className="invoice-select-drop"
                          id="combo-box-demo"
                          options={filterMaintenanceList}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, option) => (
                            <Index.Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                                // backgroundColor: "#171b37",
                              }}
                              {...props}
                            >
                              {option?.name}
                            </Index.Box>
                          )}
                          defaultValue={filterMaintenanceList?.find(
                            (ele) => ele?.value == "all"
                          )}
                          onChange={(event, newValue) => {
                            setTimeout(() => {
                              setFilterByPriority(
                                newValue?.name?.toLowerCase()
                              );
                            }, 1000);
                            setSearch("");
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Select"
                              variant="outlined"
                            />
                          )}
                        />
                      </Index.Box>
                    </Search> */}
                      <Index.Box className="add-game-dropdown status-select">
                        <Index.Box className=" signin-drop-details">
                          <Index.FormControl className="formcontrol_login sign-in-inner-form">
                            <Index.Select
                              className="currency-select-drop"
                              displayEmpty
                              value={filterByPriority}
                              label="Status"
                              onChange={(event, newValue) => {
                                setTimeout(() => {
                                  setFilterByPriority(
                                    event.target.value?.toLowerCase()
                                  );
                                }, 1000);
                                // setSearch("");
                              }}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              {/* <Index.MenuItem
                                value=""
                                className="currency-select-menu select-menu-hide"
                                sx={{ display: "none" }}
                                // disabled
                              >
                                Select
                              </Index.MenuItem> */}
                              {filterMaintenanceList.map((val) => (
                                <Index.MenuItem
                                  key={val.id}
                                  value={val.value?.toLowerCase()}
                                  className="currency-select-menu"
                                >
                                  {val.name}
                                </Index.MenuItem>
                              ))}
                            </Index.Select>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="transation-date-picker admin-datepicker-main cus-date-color-main">
                        {/* <DatePicker
                          clearable={true}
                          className="admin-datepicker-inner cus-date-color"
                          format="DD/MM/YYYY"
                          inputReadOnly={true}
                          onChange={(value) => {
                            let NewDate = "";
                            if (value) {
                              NewDate = Index.moment(value?.$d).format(
                                "DD/MM/YYYY"
                              );
                            }
                            setDate(NewDate);
                            // handleSearch(NewDate);
                          }}
                          showToday={false}
                          disabledDate={(current) => {
                            // Can not select days after today
                            return current && current > Index.moment().endOf('day');
                          }}
                        /> */}
                        <DatePicker.RangePicker
                    className="admin-datepicker-inner cus-date-color"
                    inputReadOnly={true}
                    onChange={(range) => {
                      if(range && range.length){
                        let [startRange, endRange] = range;
                        setDateRange({
                          startRange: Index.moment(startRange?.$d).format("DD/MM/YYYY"),
                          endRange: Index.moment(endRange?.$d).format("DD/MM/YYYY")
                        })
                      }else{
                        setDateRange({startRange: "", endRange: ""})
                      }
                      console.log("Date picker: ", range);
                    }}
                    format="DD/MM/YYYY"
                    disabledDate={(current) => {
                      // Can not select days after today
                      return current && current > Index.moment().endOf("day");
                    }}
                  />
                      </Index.Box>
                      <Search className="search admin-search-comman cus-penalty-search-main">
                        <StyledInputBase
                          placeholder="Search"
                          inputProps={{ "aria-label": "search" }}
                          // value={query}
                          // onChange={(e) => setQuery(e.target.value)}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          className="cus-penalty-search"
                        />
                      </Search>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Grid container spacing={3} mb={2}></Index.Grid>

                <Index.Box className="">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container table-game-container dep-rq-table-main"
                  >
                    <Index.Table
                      aria-label="simple table"
                      className="table-design-main barge-table table-main-game dep-rq-table"
                    >
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell align="left">Sr no</Index.TableCell>
                          {/* <Index.TableCell align="left">Image</Index.TableCell> */}

                          <Index.TableCell align="left">
                            User Name
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            Currency
                          </Index.TableCell>
                          <Index.TableCell align="left">Amount</Index.TableCell>
                          <Index.TableCell align="left">
                            Request Type
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            Date and Time
                          </Index.TableCell>
                          <Index.TableCell align="left">Status</Index.TableCell>

                          {/* <Index.TableCell
                            className="banner-management-th"
                            align="left"
                          >
                            {permission.isAdmin == true ||
                            (permission.role?.DepositRequest?.update == true &&
                              permission.isAdmin == false) ? (
                              <Index.Box>Action</Index.Box>
                            ) : (
                              <></>
                            )}
                          </Index.TableCell> */}
                          {/* <Index.TableCell align="left">Action</Index.TableCell> */}
                          <Index.TableCell align="left"></Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {searchedData?.length ? (
                          <>
                            {searchedData
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              ?.map((data, index) => (
                                <>
                                  <Index.TableRow key={index}>
                                    <Index.TableCell>
                                      {index + 1}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {data?.userId?.fullName
                                        ? data?.userId?.fullName
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {/* {data?.userId?.currency
                                        ? data?.userId?.currency
                                        : data?.currency
                                        ? data?.currency
                                        : "-"} */}
                                      {data.type === "Crypto Currency"
                                        ? data.tokenName
                                          ? data.tokenName
                                          : "-"
                                        : data?.userId?.currency
                                        ? data?.userId?.currency
                                        : data?.currency
                                        ? data?.currency
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {/* {data?.amount
                                        ? data?.amount
                                        : data?.requestedAmount
                                        ? data?.requestedAmount
                                        : data?.tokenAmount
                                        ? data?.tokenAmount
                                        : "-"} */}
                                        {getNumTill8Dec(data?.amount || data?.requestedAmount || data?.tokenAmount) || "-"}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {data?.requestType
                                        ? data?.requestType
                                            .charAt(0)
                                            .toUpperCase() +
                                          data?.requestType.slice(1)
                                          : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {data?.createdAt
                                        ? Index.moment(data?.createdAt).format(
                                            "DD/MM/YYYY hh:mm A"
                                          )
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {data?.status ? data?.status : "-"}
                                      {/* {data?.requestType === "deposit" &&
                                      data?.type === "Crypto Currency"
                                        ? customStatus[
                                            data?.status?.toLowerCase()
                                          ]
                                        : data?.status
                                        ? data?.status
                                        : "-"} */}
                                    </Index.TableCell>
                                    {/* <Index.TableCell
                                      className="banner-management-td"
                                      // sx={{ display: "flex" }}
                                    >
                                      {permission?.isAdmin == true ||
                                      (permission?.role?.DepositRequest
                                        ?.update == true &&
                                        permission?.isAdmin == false) ? (
                                        data?.status == "Pending" && (
                                          <>
                                            <Index.Button
                                              className="table-view-btn"
                                              disableRipple
                                              onClick={() => {
                                                setNotifyUserId(
                                                  data?.userId._id
                                                );
                                                setApprovedId(data?._id);
                                                setApproveAmount(data?.amount);
                                                handleOpenApprovedData();
                                              }}
                                              // disabled={data?.status === "Approved"}
                                              disabled={
                                                data?.status === "Approved" ||
                                                data?.status === "Rejected"
                                              }
                                            >
                                              <DoneIcon color="primary" />{" "}
                                            </Index.Button>
                                            <Index.Button
                                              className="table-view-btn"
                                              disableRipple
                                              onClick={() => {
                                                setNotifyUserId(
                                                  data?.userId._id
                                                );
                                                setRejectedId(data?._id);
                                                handleOpenRejectedData();
                                              }}
                                              disabled={
                                                data?.status === "Approved" ||
                                                data?.status === "Rejected"
                                              }
                                            >
                                              <ClearIcon color="error" />
                                            </Index.Button>
                                          </>
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </Index.TableCell> */}
                                    <Index.TableCell>
                                      {(data?.requestType === "Withdrawal" ||
                                        data?.requestType?.toLowerCase() ===
                                          "deposit") &&
                                        (showModal ? (
                                          data?._id === openModalId ? (
                                            <Index.IconButton
                                              aria-label="expand row"
                                              size="small"
                                            >
                                              <Index.KeyboardArrowUpIcon
                                                onClick={() =>
                                                  showDetails(data, "up")
                                                }
                                                sx={{ color: "white" }}
                                              />
                                            </Index.IconButton>
                                          ) : (
                                            <Index.IconButton
                                              aria-label="expand row"
                                              size="small"
                                            >
                                              <Index.KeyboardArrowDownIcon
                                                onClick={() =>
                                                  showDetails(data, "down")
                                                }
                                                sx={{ color: "white" }}
                                              />
                                            </Index.IconButton>
                                          )
                                        ) : (
                                          <Index.IconButton
                                            aria-label="expand row"
                                            size="small"
                                          >
                                            <Index.KeyboardArrowDownIcon
                                              onClick={() =>
                                                showDetails(data, "down")
                                              }
                                              sx={{ color: "white" }}
                                            />
                                          </Index.IconButton>
                                        ))}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                  {showModal &&
                                    data?._id === openModalId &&
                                    (data?.requestType === "Withdrawal" ? (
                                      <Index.TableRow className="dep-req-table-row">
                                        <Index.TableCell
                                          colSpan={10}
                                          className="table-view-data "
                                        >
                                          <Index.Collapse
                                            in={showModal}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            {data.type === "Fiat Currency" ? (
                                              <Index.Box className="set-edit-timing-box pd-loading-details">
                                                <Index.Grid
                                                  container
                                                  spacing={2}
                                                >
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                  >
                                                    <Index.Box className="title-header-flex order-loading-details">
                                                      <Index.Box className="title-main order-loading-content">
                                                        <Index.Typography
                                                          variant="p"
                                                          component="p"
                                                          className="page-title order-loading-title"
                                                        >
                                                          Details
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        Payment Method
                                                      </Index.Typography>
                                                      <span>
                                                        {data?.paymentMethod
                                                          ? data?.paymentMethod
                                                          : "-"}
                                                      </span>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        {data.paymentMethod ===
                                                        "Bank Account"
                                                          ? "Bank Account Name"
                                                          : "Upi ID"}
                                                      </Index.Typography>
                                                      <span>
                                                        {data.paymentMethod ===
                                                        "Bank Account"
                                                          ? data.bankAccount
                                                              ?.bankName
                                                            ? data.bankAccount
                                                                ?.bankName
                                                            : "-"
                                                          : data.upiId
                                                          ? data.upiId
                                                          : "-"}
                                                      </span>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  {data.paymentMethod ===
                                                    "Bank Account" && (
                                                    <>
                                                      <Index.Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={3}
                                                      >
                                                        <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                          <Index.Typography
                                                            variant="p"
                                                            component="p"
                                                            className="page-title order-loading-title"
                                                          >
                                                            Branch
                                                          </Index.Typography>
                                                          <span>
                                                            {data.bankAccount
                                                              ? data.bankAccount
                                                                  ?.branch
                                                              : "-"}
                                                          </span>
                                                        </Index.Box>
                                                      </Index.Grid>
                                                      <Index.Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={3}
                                                      >
                                                        <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                          <Index.Typography
                                                            variant="p"
                                                            component="p"
                                                            className="page-title order-loading-title"
                                                          >
                                                            Account Holder
                                                          </Index.Typography>
                                                          <span>
                                                            {data.bankAccount
                                                              ? data.bankAccount
                                                                  ?.accountHolder
                                                              : "-"}
                                                          </span>
                                                        </Index.Box>
                                                      </Index.Grid>
                                                      <Index.Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={3}
                                                      >
                                                        <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                          <Index.Typography
                                                            variant="p"
                                                            component="p"
                                                            className="page-title order-loading-title"
                                                          >
                                                            Account Number
                                                          </Index.Typography>
                                                          <span>
                                                            {data.bankAccount
                                                              ? data.bankAccount
                                                                  ?.accountNumber
                                                              : "-"}
                                                          </span>
                                                        </Index.Box>
                                                      </Index.Grid>
                                                      <Index.Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={3}
                                                      >
                                                        <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                          <Index.Typography
                                                            variant="p"
                                                            component="p"
                                                            className="page-title order-loading-title"
                                                          >
                                                            IFSC Code
                                                          </Index.Typography>
                                                          <span>
                                                            {data.bankAccount
                                                              ? data.bankAccount
                                                                  ?.IFSCCode
                                                              : "-"}
                                                          </span>
                                                        </Index.Box>
                                                      </Index.Grid>
                                                    </>
                                                  )}
                                                  {/* <Index.Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={3}
                                            >
                                              <Index.Box className="input-design-div with-border">
                                                <Index.Typography
                                                  variant="p"
                                                  component="p"
                                                  className="page-title order-loading-title"
                                                >
                                                  Upi ID
                                                </Index.Typography>

                                                {item?.emailgf
                                                  ? item?.emailt
                                                  : "-"}
                                              </Index.Box>
                                            </Index.Grid> */}
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        Email
                                                      </Index.Typography>
                                                      <span>
                                                        {data?.email
                                                          ? data?.email
                                                          : "-"}
                                                      </span>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                </Index.Grid>
                                              </Index.Box>
                                            ) : (
                                              <Index.Box className="set-edit-timing-box pd-loading-details">
                                                <Index.Grid
                                                  container
                                                  spacing={2}
                                                >
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                  >
                                                    <Index.Box className="title-header-flex order-loading-details">
                                                      <Index.Box className="title-main order-loading-content">
                                                        <Index.Typography
                                                          variant="p"
                                                          component="p"
                                                          className="page-title order-loading-title"
                                                        >
                                                          Details
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        Amount
                                                      </Index.Typography>
                                                      <span>
                                                        {getNumTill8Dec(data.requestedAmount) +
                                                          " " +
                                                          (data.tokenName
                                                            ? cryptoShortName[
                                                                data.tokenName
                                                              ]
                                                            : "-")}
                                                      </span>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        No. of coins
                                                      </Index.Typography>
                                                      <span>
                                                        {getNumTill8Dec(data.userCoinValue) || "-"}
                                                        {/* {Number(
                                                          data.userCoinValue
                                                        )?.toFixed(2)} */}
                                                      </span>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        Wallet address
                                                      </Index.Typography>
                                                      <span>
                                                        {data.walletAddress}
                                                      </span>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                </Index.Grid>
                                              </Index.Box>
                                            )}
                                          </Index.Collapse>
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    ) : (
                                      <Index.TableRow className="dep-req-table-row">
                                        <Index.TableCell
                                          colSpan={10}
                                          className="table-view-data "
                                        >
                                          <Index.Collapse
                                            in={showModal}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            {data?.type ===
                                            "Crypto Currency" ? (
                                              <Index.Box className="set-edit-timing-box pd-loading-details">
                                                <Index.Grid
                                                  container
                                                  spacing={2}
                                                >
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                  >
                                                    <Index.Box className="title-header-flex order-loading-details">
                                                      <Index.Box className="title-main order-loading-content">
                                                        <Index.Typography
                                                          variant="p"
                                                          component="p"
                                                          className="page-title order-loading-title"
                                                        >
                                                          Details
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        Amount
                                                      </Index.Typography>
                                                      <span>
                                                        {getNumTill8Dec(data.tokenAmount) +
                                                          " " +
                                                          (data.tokenName
                                                            ? cryptoShortName[
                                                                data.tokenName
                                                              ]
                                                            : "-")}
                                                      </span>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        No. of coins
                                                      </Index.Typography>
                                                      <span>
                                                        {getNumTill8Dec(data.totalCoin) || "-"}
                                                        {/* {Number(
                                                          data.totalCoin
                                                        )?.toFixed(2)} */}
                                                      </span>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border withdrwal-detail-box custom-table-details">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        Wallet address
                                                      </Index.Typography>
                                                      <span>
                                                        {data.walletAddress}
                                                      </span>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                </Index.Grid>
                                              </Index.Box>
                                            ) : (
                                              <Index.Box className="set-edit-timing-box pd-loading-details">
                                                <Index.Grid
                                                  container
                                                  spacing={2}
                                                >
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                  >
                                                    <Index.Box className="title-header-flex order-loading-details">
                                                      <Index.Box className="title-main order-loading-content">
                                                        <Index.Typography
                                                          variant="p"
                                                          component="p"
                                                          className="page-title order-loading-title"
                                                        >
                                                          Details
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        Payment Method
                                                      </Index.Typography>
                                                      <span>
                                                        {data?.UPIMethod
                                                          ? data?.UPIMethod
                                                          : "-"}
                                                      </span>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        Reference number
                                                      </Index.Typography>
                                                      <span className="reference-no">
                                                        {data?.UTRId
                                                          ? data?.UTRId
                                                          : "-"}
                                                      </span>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="p"
                                                        component="p"
                                                        className="page-title order-loading-title"
                                                      >
                                                        Phone Number
                                                      </Index.Typography>

                                                      {data?.mobileNumber
                                                        ? data?.mobileNumber
                                                        : "-"}
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                  >
                                                    <Index.Box className="input-design-div with-border">
                                                      {/* <Index.Typography
                                                    variant="p"
                                                    component="p"
                                                    className="page-title order-loading-title"
                                                  >
                                                    Image
                                                  </Index.Typography> */}
                                                      {data?.transactionScreenShort && (
                                                        <Index.Button
                                                          onClick={() => {
                                                            window.open(
                                                              `${
                                                                process.env
                                                                  .REACT_APP_IMG +
                                                                data?.transactionScreenShort
                                                              }`
                                                            );
                                                          }}
                                                          // onClick={() => {
                                                          //   window.open(
                                                          //     `${
                                                          //       "http://localhost:3032/api/images/" +
                                                          //       data?.transactionScreenShort
                                                          //     }`
                                                          //   );
                                                          // }}
                                                        >
                                                          {data?.transactionScreenShort !=
                                                          ""
                                                            ? data?.transactionScreenShort
                                                            : "-"}
                                                        </Index.Button>
                                                      )}
                                                    </Index.Box>
                                                  </Index.Grid>
                                                </Index.Grid>
                                              </Index.Box>
                                            )}
                                          </Index.Collapse>
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    ))}
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            <Index.NoDataFound
                              colSpan={10}
                              message={"No transaction found"}
                            />
                          </>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                  {searchedData?.length ? (
                    <Index.TablePagination
                      className="paginationColor"
                      component="div"
                      page={page}
                      count={searchedData?.length}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>

              <Index.Modal
                open={openApprovedData}
                onClose={handleCloseApprovedData}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal-comman-details"
              >
                <Index.Box sx={style} className="modal-comman-inner-style">
                  <ManualDepositApprove
                    handleClose={handleCloseApprovedData}
                    approvedId={approvedId}
                    approveAmount={approveAmount}
                    getDepositRequest={getTransactionRequest}
                  />
                </Index.Box>
              </Index.Modal>

              <Index.Modal
                open={openRejectedData}
                onClose={handleCloseRejectedData}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal-comman-details"
              >
                <Index.Box sx={style1}>
                  <ManualDepositReject
                    handleClose={handleCloseRejectedData}
                    rejectedId={rejectedId}
                    getDepositRequest={getTransactionRequest}
                  />
                </Index.Box>
              </Index.Modal>
            </Index.Box>
          </>
        )}
      </>
    );
  } else {
    navigate("/admin");
  }
};

export default Transaction;
