import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Index from "../../../Index";
import PageIndex from "../../../pageIndex";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";
import Parser from "html-react-parser";
import { handleCurrentGame } from "../../../../redux/user/userSlice";

export default function TwoColorGameRules() {
  const navigate = useNavigate();
  const [userGameRulesId, setUserGameRulesId] = useState("");
  const htmlString = userGameRulesId;
  const withoutImgTag = htmlString.replace(/<img[^>]*>/g, "");
  // const [open, setOpen] = useState(false);
  const [selectedSecond, setSeletedSecond] = useState("");
  const dispatch = useDispatch();
  const { setOpen, openChatDrawer, setOpenChatDrawer } = useOutletContext();
  const gameDetail = JSON.parse(localStorage.getItem("userGameId"));
  // const referralCode = useSelector(
  //   (state) => state?.UserReducer?.userData?.referralCode
  // )
  const referralCode = useSelector(
    (state) => state?.UserReducer?.userData?.referralCode
  );

  // more function start
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  // more function end

  const userGameRules = () => {
    DataService.get(Api.User.USER_GAME_RULES + "/" + gameDetail?._id)
      .then((res) => {
        setUserGameRulesId(res?.data?.data?.gameRules);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    userGameRules();
  }, []);
  // }, [userGameRulesId]);

  useEffect(() => {
    if (selectedSecond) {
      dispatch(
        handleCurrentGame({
          selectedSecond,
          // ele: gameDetail,
        })
      );
      navigate("/user/two-color-trading", {
        state: {
          selectedSecond,
          ele: gameDetail,
        },
      });
    }
  }, [selectedSecond]);

  return (
    <>
      <Index.Box className="dashbaord-user-main-page">
        <Index.Box className="dasboard-flex-user-main">
          <Index.Box className="left-dashboard-main-user">
            <Index.Box className="page-content-game-details">
              <Index.Box className="seconds-select-details-modal">
                <Index.Box className="seconds-select-content">
                  <Index.Box className="flex-bet-rules-details">
                    {/* seconds two show in ascending order */}
                    {gameDetail?.gameSecond
                      ?.sort(function (a, b) {
                        return a - b;
                      })
                      .map((ele, index) => {
                        return (
                          <>
                            <Index.Box
                              className="bet-flex-main-rules"
                              key={index}
                            >
                              <Index.Box className="bet-details-img">
                                <img
                                  src={
                                    process.env.REACT_APP_IMG +
                                    gameDetail?.gameImage
                                  }
                                  alt="two-color-bet"
                                  className="center-bet-img"
                                ></img>
                              </Index.Box>
                              <Index.Box className="seconds-three-flex">
                                <Index.Box
                                  className={
                                    ele == selectedSecond
                                      ? "second-divide-content second-onclick-active"
                                      : "second-divide-content"
                                  }
                                  onClick={() => setSeletedSecond(ele)}
                                >
                                  <Index.Box className="second-check-content">
                                    <Index.Box className="second-main-border">
                                      <Index.Box className="pd-second-content">
                                        <Index.Box className="sec-inner-border-content">
                                          <Index.Box className="inner-pd-content-details">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="second-title"
                                            >
                                              {ele}{" "}
                                              <span className="second-title-list">
                                                {" "}
                                                Sec
                                              </span>
                                            </Index.Typography>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  {/* <Index.Box className="check-content-img-list">
                                    <img
                                      src={PageIndex.Svg.seconduncheck}
                                      className="comman-check-img check-img-sec"
                                    ></img>
                                    <img
                                      src={PageIndex.Svg.secondcheck}
                                      className="comman-check-img  uncheck-img-sec"
                                    ></img>
                                  </Index.Box> */}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </>
                        );
                      })}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="game-rules-content">
                <Index.Box className="game-rules-color-bets">
                  <Index.Box className="game-list-title">
                    <Index.Typography
                      className="game-title"
                      component="p"
                      variant="p"
                    >
                      {" "}
                      Game Rules
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="game-list-details-content">
                    <Index.List className="game-list-main">
                      <Index.ListItem
                        className={`game-listitem-details ${
                          !showMore && withoutImgTag?.length > 250
                            ? "more-button-custom"
                            : ""
                        }`}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: withoutImgTag?.slice(
                              0,
                              showMore ? withoutImgTag?.length : 250
                            ),
                          }}
                        ></span>
                        {!showMore && withoutImgTag?.length > 250 ? (
                          <Index.Box className="more-right-record">
                            <Index.Box
                              className="more-link-content"
                              onClick={toggleShowMore}
                            >
                              <i>Read more</i>
                            </Index.Box>
                          </Index.Box>
                        ) : showMore ? (
                          <Index.Box className="more-right-record">
                            <Index.Box
                              className="more-link-content"
                              onClick={toggleShowMore}
                            >
                              <i>Read less</i>
                            </Index.Box>
                          </Index.Box>
                        ) : null}
                      </Index.ListItem>
                    </Index.List>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="game-rules-btns-list">
                <Index.Box className="game-back-rule">
                  <PageIndex.BlueOutlineButton
                    className="outline-blue-btn-content"
                    btnLabel="Back"
                    onClick={() => navigate("/user")}
                  />
                </Index.Box>
                {/* <Index.Box className="proceed-btn-blue">
                                    <PageIndex.BlueButton btnLabel="Proceed" className="blue-btn-content"
                                        onClick={() => {
                                            if (selectedSecond == "") {
                                                toast.error('Please select game time', {
                                                    toastId: "customId"
                                                })
                                            } else {

                                                setSeletedSecond(selectedSecond);
                                                navigate("/user/two-color-betting", {
                                                    state: {
                                                        selectedSecond: selectedSecond,
                                                        ele: gameDetail?.ele,
                                                    },
                                                })
                                            }

                                        }}
                                    />
                                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box
            className={
              !openChatDrawer
                ? "right-dashboard-main-user header-left-active"
                : "right-dashboard-main-user"
            }
          >
            <PageIndex.UserChat
              openChatMenu={!openChatDrawer}
              setOpenChatMenu={setOpenChatDrawer}
              setOpen={setOpen}
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
