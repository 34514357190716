import moment from "moment";
// export function checkGameAvailability(gameDetail, selectedSecond) {
//     const {
//       gameTimeFrom,
//       gameTimeTo,
//       gameDurationFrom,
//       gameDurationTo,
//       gameSecond,
//     } = gameDetail;
//     const currentDateTime = moment();
//     if (currentDateTime.isBetween(moment(gameTimeFrom), moment(gameTimeTo))) {
//       const currentDayStart = currentDateTime.clone().startOf('day');
//       const dailyStartDateTime = moment(currentDayStart.format("YYYY-MM-DD") + " " + gameDurationFrom, "YYYY-MM-DD hh:mm A");
//       const dailyEndDateTime = moment(currentDayStart.format("YYYY-MM-DD") + " " + gameDurationTo, "YYYY-MM-DD hh:mm A");

//       if (currentDateTime.isBetween(dailyStartDateTime, dailyEndDateTime)) {
//         return true;
//       }
//     }
//     return false;
// }
export function checkGameAvailability(
  gameDetail,
  selectedSecond,
  checkSlot = false
) {
  const {
    gameTimeFrom,
    gameTimeTo,
    gameDurationFrom,
    gameDurationTo,
    gameSecond,
    gameName,
    gameHours,
    isRepeat,
  } = gameDetail;
  const currentDateTime = moment();
  const gameStartDateTime = moment(gameTimeFrom).set({
    hour: moment(gameDurationFrom, "hh:mm A").hour(),
    minute: moment(gameDurationFrom, "hh:mm A").minute(),
  });

  const gameEndDateTime = moment(gameTimeTo).set({
    hour: moment(gameDurationTo, "hh:mm A").hour(),
    minute: moment(gameDurationTo, "hh:mm A").minute(),
  });
  let expected_endtime = moment(gameStartDateTime).add(
    Number(gameHours),
    "minutes"
  );
//   console.log({
//     gameName,
//     currentDateTime: currentDateTime._d,
//     gameStartDateTime: gameStartDateTime._d,
//     gameEndDateTime: gameEndDateTime._d,
//   });

  if (currentDateTime.isBetween(gameStartDateTime, gameEndDateTime)) {
    if (expected_endtime >= currentDateTime || isRepeat) {   
        return checkSlot ? gameSecond?.includes(selectedSecond) : true;
    }
  }
  return false;
}
