import React, { useEffect, useState } from "react";
import Index from "../../../Index";
// import "./AddGame.css";
import PageIndex from "../../../pageIndex";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ViewQuery() {
  const location = useLocation();
  const navigate = Index.useNavigate();
  const row = location?.state?.queryData;
  const permission = useSelector((state) => state.AdminReducer.adminRoleData);
  if (
    permission?.isAdmin === true ||
    (permission?.role?.Query?.View === true && permission?.isAdmin === false)
  ) {
    return (
      <>
        <Index.Box className="page-content-box">
          <Index.Box className="barge-common-box">
            <Index.Box className="title-header">
              <Index.Box className="title-header-flex">
                <Index.Box className="title-main mb-10">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Query Details
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="view-query-manage-details">
              <Index.Grid
                container
                columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                rowSpacing={{ xs: 3, sm: 3, md: 3 }}
              >
                <Index.Grid item xs={12} sm={12} md={12}>
                  <Index.Box className="input-design-div with-border">
                    <label htmlFor="upload-photo">
                      <Index.Box className="banner-border-profile ">
                        <Index.Avatar
                          alt="Remy Sharp"
                          className="add-game-img"
                          src={
                            row?.queryDocument &&
                            process.env.REACT_APP_IMG + row?.queryDocument
                          }
                        />
                      </Index.Box>
                    </label>
                  </Index.Box>
                </Index.Grid>

                <Index.Grid item xs={12} sm={4} md={4}>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-design-div with-border view-game-details-content view-mb-label"
                  >
                    Name
                  </Index.Typography>
                  <Index.Box className="input-label-box-admin">
                    {row?.userName}
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12} sm={4} md={4}>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-design-div with-border view-game-details-content view-mb-label"
                  >
                    Email
                  </Index.Typography>
                  <Index.Box className="input-label-box-admin">
                    {row?.email}
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12} sm={4} md={4}>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-design-div with-border view-game-details-content view-mb-label"
                  >
                    Number
                  </Index.Typography>
                  <Index.Box className="input-label-box-admin">
                    {row?.mobileNumber}
                  </Index.Box>
                </Index.Grid>

                <Index.Grid item xs={12} sm={12} md={12}>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-design-div with-border view-game-details-content view-mb-label"
                  >
                    Description
                  </Index.Typography>
                  <Index.Box className="textarea-label-box-admin">
                    {row?.description}
                  </Index.Box>
                </Index.Grid>
              </Index.Grid>
              <Index.Box
                className="common-button blue-button res-blue-button"
                marginTop={5}
              >
                <PageIndex.Link
                  to="/admin/query"
                  className="no-text-decoration"
                  //onClick={(e) => handleEdit(e)}
                >
                  {" "}
                  <Index.Button variant="contained" disableRipple>
                    <img
                      src={PageIndex.Png.back}
                      className="back-btn-spacing"
                    />
                    Back
                  </Index.Button>
                </PageIndex.Link>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </>
    );
  } else {
    navigate("/admin");
  }
}
