import React, {  useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Index from "../../../../Index";
import PageIndex from "../../../../pageIndex";

import {
  BNBBettingObje,
  DepositAndWithdrawObj,
  ETHBettingObj,
} from "../../../../../component/user/Connectivity/ContractObject";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
let networkArr = [
  //   {
  //     chain_id: 0,
  //     network_type: "Bitcoin",
  //   },
  {
    chain_id: 97,
    network_type: "BNB",
  },
  {
    chain_id: 80001,
    network_type: "Polygon",
  },
  {
    chain_id: 11155111,
    network_type: "Ethereum",
  },
];
const CryptoAddress = () => {
  const location = useLocation();
  const row = location?.state?.selectedData;
  const permission = useSelector((state) => state.AdminReducer.adminRoleData);

  const [loading, setLoading] = useState(false);
  const { walletProvider } = useWeb3ModalProvider();
  const { isConnected, address } = useWeb3ModalAccount();
  // Initital values

  let initialValues = {
    networkName: "",
    walletAddress: "",
  };

  const changeRecepeintAddress = async (values) => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      let changeRecepeintAddress;
      if (values.networkName === "Polygon") {
        const maticObj = await DepositAndWithdrawObj(signer);
        changeRecepeintAddress = await maticObj.changeReceiverAddress(
          values.walletAddress
        );
      } else if (values.networkName === "BNB") {
        const bnbObj = await BNBBettingObje(signer);
        changeRecepeintAddress = await bnbObj.changeReceiverAddress(
          values.walletAddress
        );
      } else if (values.networkName === "Ethereum") {
        const ethObj = await ETHBettingObj(signer);
        changeRecepeintAddress = await ethObj.changeReceiverAddress(
          values.walletAddress
        );
      } else {
        toast.error("Please select a network");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.error?.message);
    }
  };

  const handleFormSubmit = async (values) => {
    console.log("values: ", values);
    changeRecepeintAddress(values);
    // setLoading(true);
    // const formdata = new FormData();
    // formdata.append("gameName", values.gameName);
    // if (row?._id) {
    //   formdata.append("gameId", row?._id);
    // }
    // DataService.post(Api.ADMIN_ADD, formdata)
    //   .then((res) => {
    //     toast.success(res.data.message, {
    //       toastId: "customId",
    //     });

    //     // setLoading(false);
    //   })
    //   .catch((e) => {
    //     toast.error(
    //       e.response?.data?.message ? e.response?.data?.message : e.message,
    //       {
    //         toastId: "customId",
    //       }
    //     );
    //     // setLoading(false);
    //   });
  };

  return (
    <Index.Box className="page-content-box">
      <Index.Box className="barge-common-box">
        <Index.Box className="title-header"></Index.Box>
        <PageIndex.Formik
          enableReinitialize
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          //   validationSchema={cryptoAddressSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Index.Stack
              component="form"
              spacing={2}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Index.Box className="">
                <Index.Grid container spacing={3}>
                  <Index.Grid item xs={12} sm={6} md={6} lg={4}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label width"
                    >
                      Network
                    </Index.Typography>
                    <Index.Box className="add-game-dropdown">
                      <Index.Box className=" signin-drop-details">
                        <Index.FormControl className="formcontrol_login sign-in-inner-form">
                          <Index.Select
                            onChange={handleChange}
                            value={values.networkName}
                            name="networkName"
                            // placeholder="mode"
                            className="currency-select-drop"
                            displayEmpty
                            renderValue={
                              values?.networkName !== ""
                                ? undefined
                                : () => "Select your network"
                            }
                          >
                            {networkArr.map((ele) => {
                              return (
                                <Index.MenuItem
                                  key={ele?.network_type}
                                  value={ele.network_type}
                                  className="menuitem"
                                >
                                  {ele?.network_type}
                                </Index.MenuItem>
                              );
                            })}
                          </Index.Select>
                        </Index.FormControl>
                        {errors.networkName && touched.networkName && (
                          <Index.FormHelperText error>
                            {errors.networkName}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={6} lg={4}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Crypto Address
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Crypto Address"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="walletAddress"
                        // type="number"
                        onBlur={handleBlur}
                        value={values.walletAddress}
                        onChange={(e) => {
                          !isNaN(e.target.value) && handleChange(e);
                        }}
                        // InputProps={{
                        //   inputProps: {
                        //     maxLength: 4,
                        //   },
                        // }}
                        onKeyPress={(e) => {
                          // Check if the pressed key is not a dot
                          if (e.key === ".") {
                            e.preventDefault();
                          }
                        }}
                        // onChange={handleChange}
                        helperText={
                          touched.walletAddress && errors.walletAddress
                        }
                        error={Boolean(
                          errors.walletAddress && touched.walletAddress
                        )}
                      />
                    </Index.Box>
                  </Index.Grid>
                </Index.Grid>
                <Index.Box className="add-game-button">
                  {/* <Index.Box className="common-button blue-button flex-start save-btn add-submit-btn">
                    <Index.Button
                      variant="contained"
                      onClick={() => navigate("/admin/game-management")}
                    >
                      <img
                        src={PageIndex.Png.back}
                        className="back-btn-spacing"
                      />
                      Back
                    </Index.Button>
                  </Index.Box> */}

                  <Index.Box className="common-button blue-button flex-start save-btn add-submit-btn">
                    {permission.isAdmin == true ||
                    (permission.role?.CMS?.PaymentMethod?.update == true &&
                      permission.isAdmin == false) ? (
                      <Index.Button
                        variant="contained"
                        type="submit"
                        disabled={loading}
                      >
                        {/* {row?._id ? "Update" : "Submit"} */}
                        {loading ? (
                          <Index.CircularProgress color="secondary" size={20} />
                        ) : row?._id ? (
                          "Update"
                        ) : (
                          "Submit"
                        )}
                      </Index.Button>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Stack>
          )}
        </PageIndex.Formik>
      </Index.Box>
    </Index.Box>
  );
};
export default CryptoAddress;
