import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import Index from "../../../Index";
import PageIndex from "../../../pageIndex";
import moment from "moment";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { snapImageStore } from "../../../../redux/user/userSlice";
import { toast } from "react-toastify";
import { useSocket } from "../../../../context/SocketContext";
import { Chat } from "@mui/icons-material";
import { useSocketChat } from "../../../../context/ChatContext";

// var socket;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  p: 4,
};
const UserChat = ({ snapImage }) => {
  const dispatch = useDispatch();
  // const socket = useSocket()
  // const {childState}=ChatSocketContext()
  const { chat, setChat, socket, isImage, setIsImage } = useSocketChat();

  const [imgOpen, setImgOpen] = useState(false);
  const [chatImage, setChatImage] = useState("");
  // const snapImgLink = localStorage.getItem("winnerSnapShot");

  // const handleImageOpen = () => setImgOpen(true);
  const handleImageOpen = (img) => {
    setImgOpen(true);
    setChatImage(img);
  };

  const handleImageClose = () => setImgOpen(false);
  const user = useSelector((state) => state.UserReducer.userData);
  const userToken = useSelector((state) => state?.UserReducer?.token);
  // const socket = useSocket();
  const messagesEndRef = useRef(null);
  const lastMessageRef = useRef(null);
  const [message, setMessage] = useState("");
  const imageInputRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);

  // const [snapImg,set]
  // const [chat, setChat] = useState([]);
  // const [isImage, setIsImage] = useState(false);

  const handleClose = () => {
    setSelectedImage(null);
  };
  const { setOpen, openChatDrawer, setOpenChatDrawer } = useOutletContext();
  const executeScroll = () => {
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chat, openChatDrawer]);
  const referralCode = useSelector(
    (state) => state?.UserReducer?.userData?.referralCode
  );
  const { snapImage: base64ImageState } = useSelector(
    (state) => state?.UserReducer
  );

  // useEffect(() => {
  //   socket = io(Api.common.BASE_URL);

  //   // setTimeout(() => {
  //   //   executeScroll();
  //   // }, 100);

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);
  useEffect(() => {
    // setChat(Chat)
    messagesEndRef?.current?.scrollTo(0, messagesEndRef?.current?.scrollHeight);
  }, [isImage, chat]);
  // useEffect(() => {
  //   let data = {
  //     room_id: 1,
  //     user_id: user?._id,
  //   };
  //   socket.emit("JoinChat", data);

  //   // Listen for messages
  //   socket.on("Message", (chat) => {
  //     setChat(chat);
  //     messagesEndRef?.current?.scrollTo(
  //       0,
  //       messagesEndRef?.current?.scrollHeight
  //     );
  //   });

  //   // Clean up event listener
  //   return () => {
  //     socket.off("Message");
  //   };
  // }, [isImage]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // setSelectedImage(file);
    if (file) {
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/svg+xml",
      ];
      const fileType = file.type;
      if (allowedTypes.some((type) => fileType.includes(type))) {
        setSelectedImage(file);
      } else {
        e.target.value = "";
        setSelectedImage("");
        toast.error("Community chat only allowed jpg, jpeg or png file.");
      }
    }
  };

  const handleCameraButtonClick = () => {
    imageInputRef.current.click();
  };

  const SendMessage = (e) => {
    e.preventDefault();
    if (message != "") {
      let data = {
        room_id: 1,
        user_id: user?._id,
        message: message,
      };
      setMessage("");
      setSelectedImage(null);
      socket.emit("NewMessage", data);
      localStorage.removeItem("winnerSnapShot");
    }
    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    if (selectedImage) {
      if (selectedImage && validImageTypes.includes(selectedImage.type)) {
        const data = new FormData();
        data.append("image", selectedImage);
        data.append("user_id", user?._id);
        data.append("room_id", 1);
        if (message) {
          data.append("message", message);
        }
        DataService.post(Api.common.CHAT_IMAGE_UPLOAD, data)
          .then((res) => {
            // toast.success("Image uploaded");

            setIsImage(true);
            setChat(res?.data?.data[0].messages);
            setSelectedImage(null);
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            setSelectedImage("");
          });
      } else {
        toast.error("Invalid image type");
        setSelectedImage("");
      }
    }

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // executeScroll();
  };

  // const getRandomNoFromStr = (str) => {
  //   if (!str) return;
  //   let n = "";
  //   for (let char of str) {
  //     if (!isNaN(char)) n += char;
  //   }
  //   return n + "-BD";
  // };

  // const [screenSize, setScreenSize] = useState({
  //   width: 0,
  //   height: 0,
  // });

  // Function to update screen size
  const updateScreenSize = () => {
    // setScreenSize({
    //   width: window.innerWidth,
    //   height: window.innerHeight,
    // });
  };

  useEffect(() => {
    updateScreenSize();
  }, []);

  const formatTime = (timeStamp) => {
    const now = moment(); // current date and time
    const messageDate = moment(timeStamp);
    const formattedTime = now.isSame(messageDate, "day")
      ? messageDate.calendar() // today
      : messageDate.format("DD/MM/YYYY h:mm A");
    return formattedTime;
  };

  const isGuestUser = () => {
    if (!referralCode) {
      setOpen(true);
      return true;
    }
    return false;
  };

  //Handle image base64 to Blob
  const base64ToBlob = (base64) => {
    const parts = base64.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  };
  // const base64ToFile = (base64String, fileName) => {
  //   const arr = base64String.split(",");
  //   const mime = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const u8arr = new Uint8Array(n);

  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }

  //   return new File([u8arr], fileName, { type: mime });
  // };
  const handleConvert = async (base64String) => {
    try {
      const blob = base64ToBlob(base64String);
      const file = new File([blob], "image.png", { type: "image/png" });
      setSelectedImage(file);
      dispatch(snapImageStore(null));
    } catch (error) {}
  };

  useEffect(() => {
    if (base64ImageState) {
      handleConvert(base64ImageState);
    }
  }, [base64ImageState]);

  return (
    <>
      <Index.Box
        onClick={() => {
          document.body.classList[!openChatDrawer ? "add" : "remove"](
            "chat-override"
          );
        }}
        className={
          !openChatDrawer
            ? "chat-overlay-section chat-mobile-show chat-overlay-mobile-active"
            : "chat-overlay-section chat-mobile-show"
        }
        id="overlays"
      >
        {" "}
      </Index.Box>

      <Index.Box className="chat-details-inner" id={"chat-content"}>
        <Index.Box className="chat-container">
          <Index.Box className="main-chat-back">
            <Index.Box className="chat-head">
              <Index.Box className="chat-heading-text">
                <Index.Typography className="chat-heading-main-text">
                  <Index.Box className="chat-betting-main">
                    <img
                      src={PageIndex.Svg.chat}
                      className="chat-icon"
                      alt="chat"
                    />
                  </Index.Box>
                  Community chat
                </Index.Typography>
                <Index.Button
                  className="add-chat-cancel"
                  onClick={() => {
                    setOpenChatDrawer(!openChatDrawer);
                  }}
                >
                  <img
                    src={PageIndex.Svg.cancelmodal}
                    className="close-icons"
                  ></img>
                </Index.Button>
              </Index.Box>
            </Index.Box>
            <Index.Box className="chatbox" ref={messagesEndRef}>
              {chat?.map((row, ind) => (
                <Index.Box
                  className="chat-gaming-content"
                  ref={ind === chat.length - 1 ? lastMessageRef : null}
                  key={ind}
                >
                  {row?.user_id?._id === user?._id ? (
                    // me
                    <Index.Box className="common-chat-content-details">
                      <Index.Box className="chat-user-flex chat-right">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="description-chat-live "
                        >
                          {row?.image ? (
                            <div>
                              <Index.Button
                                onClick={() => {
                                  handleImageOpen(row?.image);
                                }}
                                className="chat-image-space pointer-event-cursor"
                              >
                                <Index.Box className="chat-images chat-image-space ">
                                  {row?.image ? (
                                    <img
                                      src={
                                        // `${baseUrl}/images/${row?.image}`
                                        process.env.REACT_APP_IMG + row?.image
                                      }
                                      className="uploaded-img"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Index.Box>
                              </Index.Button>

                              <Index.Modal
                                open={imgOpen}
                                onClose={handleImageClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                className="chat-img-modal"
                              >
                                <Index.Box sx={style}>
                                  <Index.Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                  >
                                    <Index.Box>
                                      <img
                                        src={
                                          // `${baseUrl}/images/${chatImage}`
                                          process.env.REACT_APP_IMG + chatImage
                                        }
                                        className="chat-image-modal-show"
                                      />
                                    </Index.Box>
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Modal>
                            </div>
                          ) : (
                            ""
                          )}

                          <Index.Box
                            className={
                              row?.message ? "user-chat-msg" : "not-use-class"
                            }
                          >
                            {row.message}
                          </Index.Box>
                          <Index.Box className="user-chat-timing">
                            {formatTime(row?.time)}
                          </Index.Box>
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  ) : (
                    // sender
                    <Index.Box className="common-chat-content-details left-user-chat">
                      <Index.Box className="chat-user-flex">
                        <Index.Box className="user-icons-chat">
                          <img
                            src={
                              row.user_id?.profile
                                ? process.env.REACT_APP_IMG +
                                  row.user_id?.profile
                                : // ? "http://localhost:3032/api/images/" + row.user_id?.profile
                                  PageIndex.Png.usericon
                            }
                            className="chat-icon-list"
                          ></img>
                        </Index.Box>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="description-chat-live"
                        >
                          <Index.Box className="user-naming">
                            {row?.from ||
                              (row?.user_id
                                ? `CB-99X-${row.user_id.toString().slice(-2)} `
                                : "")}
                          </Index.Box>
                          {row?.image ? (
                            <div className="chat-image-space">
                              <Index.Button
                                onClick={() => {
                                  handleImageOpen(row?.image);
                                }}
                                className="pointer-event-cursor"
                              >
                                <Index.Box className="chat-images">
                                  {row?.image ? (
                                    <img
                                      src={
                                        // `${baseUrl}/images/${row?.image}`
                                        process.env.REACT_APP_IMG + row?.image
                                      }
                                      className="uploaded-img"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Index.Box>
                              </Index.Button>
                              <Index.Modal
                                open={imgOpen}
                                onClose={handleImageClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Index.Box sx={style}>
                                  <Index.Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                  >
                                    <Index.Box>
                                      <img
                                        src={
                                          // `${baseUrl}/images/${row?.image}`
                                          process.env.REACT_APP_IMG + chatImage
                                        }
                                        className="chat-image-modal-show"
                                      />
                                    </Index.Box>
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Modal>
                            </div>
                          ) : (
                            ""
                          )}

                          <Index.Box
                            className={
                              row?.message ? "user-chat-msg" : "not-use-class"
                            }
                          >
                            {row.message}
                          </Index.Box>
                          <Index.Box className="user-chat-timing">
                            {formatTime(row?.time)}
                          </Index.Box>
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  )}
                </Index.Box>
              ))}
              <Index.Box className="empty-mobile-box"></Index.Box>
              {/* <div ref={messagesEndRef} className="scrolldiv" /> */}
            </Index.Box>
          </Index.Box>

          <Index.Box className="bottom-sent-chat">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {selectedImage && (
                <Index.Box className="chat-preview-image">
                  <img
                    src={selectedImage && URL.createObjectURL(selectedImage)}
                    alt="preview-image"
                    className="preview-image"
                  />
                  <Index.Box className="modal-cancel-btn close-button">
                    <Index.Button className="btn btn-cancel custom-btn-cancel">
                      <img
                        src={PageIndex.Svg.cancelmodal}
                        className="cancel-modal"
                        alt="modal-cancel"
                        onClick={handleClose}
                      />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              )}
              <Index.Box
                className="input-design-div user-chat-input-main with-border chat-top-border"
                onClick={isGuestUser}
              >
                <Index.TextField
                  className="admin-input-design input-placeholder user-chat-input"
                  fullWidth
                  placeholder="Enter your message..."
                  value={selectedImage ? selectedImage?.name : message}
                  onKeyUp={(e) => {
                    if (e.key == "Enter") SendMessage(e);
                  }}
                  onChange={(e) => {
                    if (!isGuestUser()) {
                      setMessage(
                        e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ")
                      );
                    }
                  }}
                />
                <Index.Box className="chat-icon-details">
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(e) => handleImageChange(e)}
                    ref={imageInputRef}
                  />
                  <Index.IconButton
                    type="button"
                    edge="end"
                    className="camera-icon-box"
                    onClick={() => handleCameraButtonClick()}
                    disabled={!userToken}
                  >
                    <Index.UploadFileIcon className="camera-icon" />
                  </Index.IconButton>
                  <Index.IconButton
                    edge="end"
                    className="send-icon-box"
                    type="button"
                    onClick={(e) => SendMessage(e)}
                    disabled={!userToken}
                  >
                    <Index.SendIcon className="send-icon" />
                  </Index.IconButton>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default memo(UserChat);
