import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../pageIndex";
import { toast } from "react-toastify";
import { ErrorMessage, Form } from "formik";
import { useLocation } from "react-router-dom";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { useSelector } from "react-redux";

const NotificationAdd = () => {
  const navigate = Index.useNavigate();
  const location = useLocation();
  const row = location?.state?.selectedData;
  const [loading, setLoading] = useState(false);
  const permission = useSelector((state) => state.AdminReducer.adminRoleData);

  let initialValues = {
    title: "",
    body: "",
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    setLoading(true);
    const data = new URLSearchParams();
    data.append("title", values?.title);
    data.append("body", values?.body);
    DataService.post(Api.ADMIN_ADD_NOTIFICATION, data)
      .then((res) => {
        resetForm();
        setLoading(false);
        toast.success(res?.data?.message, { toastId: "customId" });
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message ? e.response?.data?.message : e.message,
          {
            toastId: "customId",
          }
        );
        setLoading(false);
      });
  };

  if (
    permission.isAdmin == true ||
    (permission.role?.Notification?.View == true && permission.isAdmin == false)
  ) {
    return (
      <>
        <Index.Box className="page-content-box">
          <Index.Box className="barge-common-box">
            <Index.Box className="title-header">
              <Index.Box className="title-header-flex">
                <Index.Box className="title-main mb-10">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Notification
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <PageIndex.Formik
              enableReinitialize
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={PageIndex.validationSchemaAdminNotification}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setErrors,
                resetForm,
              }) => (
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="">
                    <Index.Grid container spacing={3}>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Title
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Title"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            autoComplete="off"
                            name="title"
                            type="text"
                            onBlur={handleBlur}
                            value={values.title}
                            onChange={handleChange}
                            // onChange={(e) => {
                            //   const value = e.target.value;
                            //   const regex = /^[A-Za-z][A-Za-z\s]*$/;

                            //   if (regex.test(value) || value === "") {
                            //     setFieldValue("bannerName", value.slice(0, 50))
                            //   }
                            // }}
                            InputProps={{
                              inputProps: {
                                maxLength: 20,
                              },
                            }}
                            onKeyPress={(e) => {
                              // Check if the pressed key is a special symbol or a number
                              if (/[^\w\s]/.test(e.key) || /\d/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            helperText={touched.title && errors.title}
                            error={Boolean(errors.title && touched.title)}
                            onKeyDown={(e) => {
                              if (
                                e.key === " " &&
                                e.target.value.trim() === ""
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label width"
                        >
                          Message
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border banner-textarea main-content-textarea">
                          <Index.TextareaAutosize
                            type="text"
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Message"
                            variant="filled"
                            className="admin-input-design input-placeholder form-control custom-auth-user-control textarea-content-comman cus-texarea-main"
                            autoComplete="off"
                            name="body"
                            onBlur={handleBlur}
                            value={values.body}
                            onChange={(e) => {
                              if (e.target.value.toString().length <= 2500) {
                                handleChange(e);
                              }
                            }}
                            // onChange={(e) => {
                            //   const value = e.target.value;
                            //   const regex = /^[A-Za-z][A-Za-z\s]*$/;

                            //   if (regex.test(value) || value === "") {
                            //     setFieldValue("bannerDescription", value.slice(0, 250))
                            //   }
                            // }}
                            // InputProps={{
                            //   inputProps: {
                            //     maxLength:250,
                            //   },
                            // }}
                            onKeyDown={(e) => {
                              if (
                                e.key === " " &&
                                e.target.value.trim() === ""
                              ) {
                                e.preventDefault();
                              }
                            }}
                            sx={{ mb: 3 }}
                          />
                          {touched.body && errors.body && (
                            <Index.FormHelperText error>
                              {errors.body}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    <Index.Box className="add-game-button">
                      {permission.isAdmin == true ||
                      (permission.role?.Notification?.create == true &&
                        permission.isAdmin == false) ? (
                        <Index.Box className="common-button blue-button flex-start save-btn add-submit-btn">
                          <Index.Button
                            variant="contained"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <Index.CircularProgress
                                color="secondary"
                                size={20}
                              />
                            ) : row?._id ? (
                              "Update"
                            ) : (
                              "Send"
                            )}
                          </Index.Button>
                        </Index.Box>
                      ) : (
                        ""
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Stack>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      </>
    );
  } else {
    navigate("/admin");
  }
};
export default NotificationAdd;
