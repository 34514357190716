import React, { useState, useEffect } from "react";
import Index from "../../Index";
import PageIndex from "../../PageIndex";
import { Link, useLocation } from "react-router-dom";
import PagesIndex from "../../PageIndex";
import { useSelector, useDispatch } from "react-redux";
import { dashboardAmount, logout } from "../../../redux/user/userSlice";
import { useDisconnect, useWeb3ModalAccount } from "@web3modal/ethers5/react";

import { useNavigate } from "react-router-dom/dist";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
import moment from "moment";
import { toast } from "react-toastify";
import { userAmountNew } from "../../../redux/user/userReducer";
import { checkGameAvailability } from "../../comman/CommonFunction/CheckGameAvailability";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function Sidebar(props) {
  const [totalAmount, setTotalAmount] = useState();
  const [openBalances, setOpenBalances] = useState(false);
  const [openAccount, setOpenAccount] = useState(true);
  // const [gameList, setGameList] = useState([]);

  const [meargeData, setMeargeData] = useState([]);

  const path = useLocation()?.pathname;
  const locationState = useLocation()?.state;
  const location = useLocation();
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();
  let dispatch = useDispatch();
  const { isConnected, address } = useWeb3ModalAccount();
  let token = localStorage.getItem("token");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const referralCode = useSelector(
    (state) => state?.UserReducer?.userData?.referralCode
  );

  const {
    openMenu,
    setOpenMenu,
    setOpen,
    setUserGameId,
    setOpenModal,
    setDate,
    selectedVal,
    setSelectedVal,
    isUserPlayingGame,
    setIsUserPlayingGame,
    leaveGamePage,
    setLeaveGamePage,
    navigateToPage,
    setNavigateToPage,
    goBackModal,
    setGoBackModal,
    setCurrentGameDetail,
    fetchGameListAgain,
    gameList,
    setGameList,
    gameNewName
  } = props;
  const handleClickAccount = () => {
    setOpenAccount(!openAccount);
  };

  const handleOpenBalances = () => setOpenBalances(true);
  const handleCloseBalances = () => setOpenBalances(false);

  /* user signout function*/
  const handleSignOutUser = async () => {
    await disconnect();
    await dispatch(logout());
    await localStorage.removeItem("token");
    await removeClass();
    await navigate("/user");
  };

  useEffect(() => {
    // Function to update window width state
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /* user wallet total amount */
  const userAmountDeposit = async () => {
    await DataService.get(Api.User.USER_AMOUNT_DEPOSIT)
      .then((res) => {
        setTotalAmount(res?.data?.data?.tokenDollorValue);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (token) {
      userAmountDeposit();
    }
  }, []);

  const userGameList = async () => {
    await DataService.get(Api.User.USER_GAMES)
      .then((res) => {
        setGameList(res?.data?.data);
        res?.data?.data.forEach((ele) => {
          const currentGame = JSON.parse(localStorage.getItem("userGameId"));
          setTimeout(() => {
            if (ele?.gameName === currentGame?.gameName) {
              setCurrentGameDetail(ele);
            }
          }, [1000]);
          if (ele?.gameName === "Community Betting") {
            localStorage.setItem("communityBetting", JSON.stringify(ele));
          }
          if (ele?.gameName === "Card Betting") {
            localStorage.setItem("cardBetting", JSON.stringify(ele));
          }
        });
        // setRetingId(res?.data?.data.map((e) => userGameRating(e?._id)))
      })
      .catch((error) => {});
  };
  useEffect(() => {
    userGameList();
  }, [fetchGameListAgain]);

  const removeClass = () => {
    setOpenMenu((e) => !e);
    document.body.classList[openMenu ? "add" : "remove"]("header-override");
    document
      .getElementById("admin-sidebar")
      .classList[openMenu ? "add" : "remove"]("active");
  };

  /* slider */
  const [sidebarslider, setslider] = useState({
    ourgameslider: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1024: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  });

  const staticData = [
    {
      gameName: "Home",
      isActive: true,
    },
  ];

  const lastData = [
    {
      gameName: "Live Bets",
      isActive: true,
    },
    {
      gameName: "Offers",
      isActive: false,
    },
    {
      gameName: "Query",
      isActive: true,
    },
  ];

  const handleNavigate = (ele) => {
    setSelectedVal(ele?.gameName);
    localStorage.setItem("userGameId", JSON.stringify(ele));
    setCurrentGameDetail(ele);
    setUserGameId("");

    if (referralCode) {
      setDate(ele);
      setUserGameId(ele);
      if (ele?.gameName === "Home") {
        navigate("/user");
      } else if (ele?.gameName === "Dashboard" && referralCode) {
        dispatch(userAmountNew())
          .then((res) => {
            if (res) {
              dispatch(dashboardAmount(res?.payload));
            }
          })
          .catch((error) => {});
        navigate("/user/dashboard_details");
      } else if (ele?.gameName === "Transaction History" && referralCode) {
        navigate("/user/transation-history");
      } else if (ele?.gameName === "Live Bets" && referralCode) {
        navigate("/user#live-bets");
      } else if (ele?.gameName === "Offers" && referralCode) {
      } else if (ele?.gameName === "Query" && referralCode) {
        navigate("/user/query");
      }
      const isGame = gameList?.some(
        (gameDetail) => gameDetail.gameName === ele.gameName
      );
      let isGameAvailable = isGame ? checkGameAvailability(ele) : false;
      const gameRoutes = {
        "2 Color Betting": "/user/two-color-gamerules",
        "Penalty Betting": "/user/penalty-trading-game-rules",
        "3 Color Betting": "/user/three-color-gamerules",
        "Community Betting": "/user/community-trading-gamerules",
        "Card Betting": "/user/card-trading-gamerules",
        "Number Betting": "/user/number-trading-gamerules",
      };
      if (gameRoutes[ele?.gameName]) {
        if (isGameAvailable && ele?.isRepeat) {
          navigate(gameRoutes[ele?.gameName], { state: { ele } });
          setOpenModal(false);
        } else {
          setOpenModal(true);
          navigate("/user");
        }
      }
    } else {
      setSelectedVal("Home");
      setOpen(true);
      setUserGameId("");
      navigate("/user");
    }
  };
  useEffect(() => {
    if (token) {
      if (gameList?.length) {
        const mergedArray = [
          ...staticData,
          ...gameList,
          {
            gameName: "Dashboard",
            isActive: true,
          },
          ...lastData,
          {
            gameName: "Transaction History",
            isActive: true,
          },
        ];
        setMeargeData(mergedArray);
      } else {
        const mergedArray = [...staticData, ...lastData];
        setMeargeData(mergedArray);
      }
    } else {
      if (gameList?.length) {
        const mergedArray = [...staticData, ...gameList, ...lastData];
        setMeargeData(mergedArray);
      } else {
        const mergedArray = [...staticData, ...lastData];
        setMeargeData(mergedArray);
      }
    }
    if (gameList?.length) {
      const currentGameDetail = JSON.parse(localStorage.getItem("userGameId"));
      const updatedGameDetail = gameList.find(
        (game) => game?.gameName === currentGameDetail?.gameName
      );

      if (updatedGameDetail) {
        localStorage.setItem("userGameId", JSON.stringify(updatedGameDetail));
      }
    }
  }, [token, gameList]);

  useEffect(() => {
    if ((path === "/user" || path === "/user/setting") && location.hash !== '#live-bets') {
      setSelectedVal("Home");
    }
  }, [path, selectedVal]);

  useEffect(() => {
    if (locationState?.message === "loginRequired") {
      setOpen(true);
    } else {
      if (locationState?.ele?.gameName) {
        setSelectedVal(locationState?.ele?.gameName);
        setUserGameId(locationState?.ele);
      }
    }
  }, [locationState]);

  return (
    <>
      <Index.Box className="user-sidebar-main-flex" id={"admin-sidebar"}>
        <Index.Box className="main-user-logo-content">
          <Link
            to="/user"
            onClick={() => {
              setUserGameId("");
            }}
            className="user-logo-redirect"
          >
            <img
              src={PageIndex.Svg.userlogo}
              alt="logo"
              onClick={() => {
                window.innerWidth <= 768 && removeClass();
              }}
            />
          </Link>
        </Index.Box>
        <Index.Box className="list-user-details-main">
          <Index.List className="list-user-sidebar">
            {meargeData?.map((ele, index) => {
              return (
                <Index.ListItem
                  key={index}
                  className={`listitem-user-inner ${
                    ele?.gameName === selectedVal && ele?.isActive
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();

                    if (ele?.isActive) {
                      if (isUserPlayingGame) {
                        setNavigateToPage(ele);
                        setLeaveGamePage(true);
                      } else {
                        handleNavigate(ele);
                      }
                    }
                  }}
                >
                  <Link
                    className="inner-user-redirect-page"
                    onClick={() => {
                      setUserGameId("");
                    }}
                  >
                    <Index.Box className="user-flex-menu">
                      <Index.Box className="user-icon-list">
                        {ele?.gameName === "Home" ? (
                          <>
                            <img src={PageIndex.Png.home} alt="home" />
                          </>
                        ) : ele?.gameName === "Dashboard" ? (
                          <>
                            <img
                              src={PageIndex.Png.dashbaord}
                              alt="dashbaord"
                            />
                          </>
                        ) : ele?.gameName === "Live Bets" ? (
                          <>
                            <img
                              src={PageIndex.Png.onlinebetting}
                              alt="onlinebetting"
                            />
                          </>
                        ) : ele?.gameName === "Offers" ? (
                          <>
                            <img src={PageIndex.Png.offers} alt="offers" />
                          </>
                        ) : ele?.iconImage ? (
                          <>
                            <img
                              src={process.env.REACT_APP_IMG + ele?.iconImage}
                              alt="offers"
                            />
                          </>
                        ) : ele?.gameName === "Query" ? (
                          <>
                            <img src={PageIndex.Png.query} alt="query" />
                          </>
                        ) : ele?.gameName === "Transaction History" ? (
                          <>
                            <img
                              src={PageIndex.Png.transactionhistory}
                              alt="transactionhistory"
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </Index.Box>

                      <Index.Typography
                        component="p"
                        variant="p"
                        className="sidebar-user-title"
                      >
                        {gameNewName(ele?.gameName)}
                      </Index.Typography>
                      {/* )} */}
                    </Index.Box>
                  </Link>
                </Index.ListItem>
              );
            })}
            <Index.ListItem
              className={`term-condition listitem-user-inner ${
                selectedVal === "Terms and Conditions" ? "active" : ""
              }`}
              onClick={() => {
                window.innerWidth <= 768 && removeClass();
                if (isUserPlayingGame) {
                  setNavigateToPage({
                    gameName: "Terms and Conditions",
                    isActive: true,
                    path: "/user/terms-condition",
                  });
                  setLeaveGamePage(true);
                } else {
                  setSelectedVal("Terms and Conditions");
                  navigate("/user/terms-condition");
                }
              }}
            >
              <Link
                className="inner-user-redirect-page"
                onClick={() => {
                  setUserGameId("");
                }}
              >
                <Index.Box className="user-flex-menu">
                  <Index.Box className="user-icon-list">
                    <>
                      <img
                        src={PageIndex.Png.term_condition}
                        alt="Terms and Conditions"
                      />
                    </>
                  </Index.Box>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="sidebar-user-title"
                  >
                    Terms and Conditions
                  </Index.Typography>
                </Index.Box>
              </Link>
            </Index.ListItem>
            <Index.ListItem
              className={`term-condition listitem-user-inner ${
                selectedVal === "Privacy Policy" ? "active" : ""
              }`}
              onClick={() => {
                window.innerWidth <= 768 && removeClass();
                if (isUserPlayingGame) {
                  setNavigateToPage({
                    gameName: "Privacy Policy",
                    isActive: true,
                    path: "/user/privacy-policy",
                  });
                  setLeaveGamePage(true);
                } else {
                  setSelectedVal("Privacy Policy");
                  navigate("/user/privacy-policy");
                }
              }}
            >
              <Link
                className="inner-user-redirect-page"
                onClick={() => {
                  setUserGameId("");
                }}
              >
                <Index.Box className="user-flex-menu">
                  <Index.Box className="user-icon-list">
                    <>
                      <img
                        src={PageIndex.Png.privacy_policy}
                        alt="Privacy Policy"
                      />
                    </>
                  </Index.Box>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="sidebar-user-title"
                  >
                    Privacy Policy
                  </Index.Typography>
                </Index.Box>
              </Link>
            </Index.ListItem>
          </Index.List>
        </Index.Box>
        {gameList?.length ? (
          <Index.Box className="user-sidebar-owl-carousel">
            <Index.OwlCarousel
              className="owl-theme owl-slider-main-content"
              loop
              margin={10}
              nav={false}
              dots={true}
              autoplay={true}
              autoplayTimeout={3000}
              responsive={sidebarslider.sidebarslider}
              items={1}
            >
              {gameList.map((gameInfo) => (
                <div className="item" key={gameInfo?._id}>
                  <Index.Box
                    className="sidebar-owl-main"
                    onClick={(e) => {
                      if (gameInfo?.isActive) {
                        if (isUserPlayingGame) {
                          setNavigateToPage(gameInfo);
                          setLeaveGamePage(true);
                        } else {
                          handleNavigate(gameInfo);
                        }
                      }
                    }}
                  >
                    <Index.Box className="img-card-sidebar">
                      <img
                        src={
                          gameInfo?.gameImage
                            ? process.env.REACT_APP_IMG + gameInfo?.gameImage
                            : PagesIndex.Png.sidebarowl
                        }
                        className="sidebar-card-img-inner"
                        alt="sidebar-card"
                      />
                    </Index.Box>
                    <Index.Box className="owl-bg-sidebar">
                      <Index.Box className="pd-owl-sidebar">
                        <Index.Box className="slider-bg-details">
                          <Index.Typography
                            component="h4"
                            variant="h4"
                            className="community-title-slider"
                          >
                            {gameNewName(gameInfo?.gameName)}
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="community-details-slider"
                          >
                            {gameInfo?.description &&
                            gameInfo.description.length > 100
                              ? `${gameInfo.description.slice(0, 100)}...`
                              : gameInfo.description}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </div>
              ))}
            </Index.OwlCarousel>
          </Index.Box>
        ) : (
          ""
        )}
      </Index.Box>

      {/* mobile menu balnce start */}

      <Index.Modal
        open={openBalances}
        onClose={handleCloseBalances}
        aria-labelledby="modal-modal-title"
        className="modal-comman-details"
        aria-describedby="modal-modal-description"
      >
        <Index.Box
          sx={style}
          className="modal-comman-inner-style deposit-modal-inner"
        >
          <Index.Box className="balance-title-details">
            <Index.Typography
              variant="h6"
              component="h6"
              className="balances-main-content"
            >
              Balance
            </Index.Typography>
          </Index.Box>
          <Index.Box className="balance-mobile-value">
            <Index.Box className="wallet-bg-main">
              <Index.Box className="wallet-pd-content">
                <img
                  src={PageIndex.Svg.wallet}
                  className="wallet-main"
                  alt="wallet"
                />
                <Index.Typography
                  component="p"
                  variant="p"
                  className="wallet-number"
                >
                  {isConnected && totalAmount
                    ? Number(totalAmount)?.toFixed(2)
                    : 0}
                </Index.Typography>
                <Index.Button className="btn-plus-circle-file">
                  <img
                    src={PageIndex.Svg.pluscirclefill}
                    className="pluscirclefill-main"
                    alt="pluscirclefill"
                  />
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      {/* mobile menu balance end */}

      {/* leave game page confirmation start */}
      <Index.Modal
        open={leaveGamePage}
        onClose={() => {
          setLeaveGamePage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-comman-details modal-blur-common-remove "
      >
        <Index.Box sx={style} className="modal-comman-inner-style">
          <Index.Box className="modal-cancel-btn">
            <Index.Button className="btn btn-cancel">
              <img
                src={PageIndex.Svg.cancelmodal}
                className="cancel-modal"
                alt="modal-cancel"
                onClick={() => {
                  setLeaveGamePage(false);
                }}
              />
            </Index.Button>
          </Index.Box>
          <Index.Box>
            <Index.Box className="delete-game-data-main">
              <Index.Box className="number-list-details">
                <Index.Typography
                  className="number-bet-coin are-you-sure-text"
                  component="p"
                  variant="p"
                >
                  You want to leave game
                </Index.Typography>
              </Index.Box>
              <Index.Box className="deleteModel-btna1">
                <Index.Box className="btn-col">
                  <PagesIndex.BlueOutlineButton
                    variant="contained"
                    color="error"
                    btnLabel="No"
                    className="outline-blue-btn-content"
                    onClick={() => {
                      setLeaveGamePage(false);
                      setNavigateToPage({});
                    }}
                  />
                </Index.Box>
                <Index.Box className="btn-col">
                  <PagesIndex.BlueOutlineButton
                    variant="contained"
                    color="error"
                    btnLabel="Yes"
                    className="outline-blue-btn-content"
                    onClick={() => {
                      setLeaveGamePage(false);
                      setIsUserPlayingGame(false);
                      if (
                        navigateToPage?.gameName === "Privacy Policy" ||
                        navigateToPage?.gameName === "Terms and Conditions" ||
                        navigateToPage?.gameName === "Account setting"
                      ) {
                        setSelectedVal(navigateToPage?.gameName);
                        navigate(navigateToPage?.path, {
                          state: navigateToPage?.state,
                        });
                      } else {
                        handleNavigate(navigateToPage);
                      }
                    }}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      {/* leave game page confirmation end */}
      {/* go back  confirmation modal start*/}
      <Index.Modal
        open={goBackModal}
        onClose={() => {
          setGoBackModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-comman-details modal-blur-common-remove "
      >
        <Index.Box sx={style} className="modal-comman-inner-style">
          <Index.Box className="modal-cancel-btn">
            <Index.Button className="btn btn-cancel">
              <img
                src={PageIndex.Svg.cancelmodal}
                className="cancel-modal"
                alt="modal-cancel"
                onClick={() => {
                  setGoBackModal(false);
                }}
              />
            </Index.Button>
          </Index.Box>
          <Index.Box>
            <Index.Box className="delete-game-data-main">
              <Index.Box className="number-list-details">
                <Index.Typography
                  className="number-bet-coin are-you-sure-text"
                  component="p"
                  variant="p"
                >
                  You want to leave game
                </Index.Typography>
              </Index.Box>
              <Index.Box className="deleteModel-btna1">
                <Index.Box className="btn-col">
                  <PagesIndex.BlueOutlineButton
                    variant="contained"
                    color="error"
                    btnLabel="No"
                    className="outline-blue-btn-content"
                    onClick={() => {
                      setGoBackModal(false);
                    }}
                  />
                </Index.Box>
                <Index.Box className="btn-col">
                  <PagesIndex.BlueOutlineButton
                    variant="contained"
                    color="error"
                    btnLabel="Yes"
                    className="outline-blue-btn-content"
                    onClick={() => {
                      setGoBackModal(false);
                      setIsUserPlayingGame(false);
                    }}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      {/* go back  confirmation modal end */}
    </>
  );
}
