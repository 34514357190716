import React from "react";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";

export const UserPrivateRoute = () => {
  const outletContext = useOutletContext();

  // Check token expiry
  const isValidToken = (accessToken) => {
    // console.log("calling query inside fun", accessToken);

    if (!accessToken) {
      // console.log("calling false ");

      return false;
    } else {
      return true;
    }
  };
  const token = localStorage.getItem("token");
  return isValidToken(token) ? (
    <Outlet context={outletContext} />
  ) : (
    <Navigate to="/user" />
  );
};

export const UserPrivateRouteWalletAddress = () => {
  // Check token expiry
  const isValidTokenAndWalletAddress = (walletAddress) => {
    return true;
  };

  const walletAddress = localStorage.getItem("walletAddress");
  return isValidTokenAndWalletAddress(walletAddress) ? (
    <Outlet />
  ) : (
    <Navigate to="/user" />
  );
};
