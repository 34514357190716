import React from "react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";

export function useEthersSigner() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  try {
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);

    const signer = ethersProvider.getSigner();
    const balance = ethersProvider.getBalance(address);

    return signer;
    // console.log(signer,chainId,address , "Data");
  } catch (error) {
    // console.log(error);
  }
}
