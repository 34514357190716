import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import { styled } from "@mui/material/styles";
import PageIndex from "../../../../pageIndex";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { useSelector } from "react-redux";
import { cryptoNetworkSchema } from "../../../../../validation/Validation";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BitcoinDetails = () => {

    const [cryptoNetworkDetails, setCryptoNetworkDetails] = useState({});
    const [loading, setLoading] = useState(false);
  const permission = useSelector((state) => state.AdminReducer.adminRoleData);

  
    let initialValues = {
        walletAddress: cryptoNetworkDetails?.walletAddress || "",
    };
    const handleCryptoNetwork = (values) => {
      const data = {walletAddress: values.walletAddress, networkName: "Bitcoin"};
      if (cryptoNetworkDetails?._id) {
        data.id = cryptoNetworkDetails?._id;
      }
      DataService.post(Api.ADD_EDIT_CRYPTO_NETWORK, data)
        .then((res) => {
          toast.success(res?.data?.message, {
            toastId: "customId",
          });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            toastId: "customId",
          });
        });
    };
  
    const getCryptoNetwork = async () => {
      setLoading(true);
      await DataService.post(Api.GET_CRYPTO_NETWORK, {networkName: "Bitcoin"})
        .then((res) => {
          setCryptoNetworkDetails(res?.data?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((e) => {
          toast.error(
            e.res?.data?.message ? e.res?.data?.message : e.message
            // navigate("/admin/login")
          );
          setLoading(false);
        });
    };
  
    useEffect(() => {
        getCryptoNetwork();
    }, []);

  return (
    <PageIndex.Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={cryptoNetworkSchema}
      onSubmit={handleCryptoNetwork}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,

        errors,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              className="grid-row-details"
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                //   sm: "span 6",
                //   md: "span 4",
                //   lg: "span 6",
                }}
                className="grid-column grid-user-col"
              >
                <Item className="grid-item">
                  <Index.Box className="form-group-main deposit-form-content setting-pass-main mb-30px-form">
                    <Index.FormHelperText className="title-label-comman-user">
                      Wallet address
                    </Index.FormHelperText>
                    <Index.Box className="input-design-div admin-design-div input-design-div-set-back setting-password">
                      <Index.Box className="input-details-main">
                        <Index.Box className="input-box cm-search-box">
                          <Index.Box className="form-group">
                            <Index.Box className="form-group pass_group_main">
                              <Index.TextField
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                placeholder="Enter wallet address"
                                type="text"
                                name="walletAddress"
                                onBlur={handleBlur}
                                value={values.walletAddress}
                                onChange={(e)=>{
                                  let value = e.target.value.replace(/[^a-z0-9]/gi, '');
                                  setFieldValue("walletAddress", value);
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.value.trim() === ""
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors.walletAddress && touched.walletAddress && (
                                <Index.FormHelperText error>
                                  {errors.walletAddress}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Item>
              </Index.Box>
              <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column grid-user-col"
                  >
                    {permission.isAdmin == true ||
                    (permission.role?.CMS?.PaymentMethod?.update == true &&
                      permission.isAdmin == false) ? (
                      <>
                        <Index.Box className="betting-card-btn-comman max-betting-card">
                          <PageIndex.BlueButton
                            btnLabel="Submit"
                            className="blue-btn-content"
                            type="submit"
                          />
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
            </Index.Box>
          </Index.Box>
        </form>
      )}
    </PageIndex.Formik>
  );
};

export default BitcoinDetails;
