import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../pageIndex";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Height } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { object } from "prop-types";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { MuiOtpInput } from "mui-one-time-password-input";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { CircularProgress } from "@mui/material";
import {
  getTotalCoins,
  // loginUser,
  // mobileOTPVerify,
  // userProfile,
  // setOtpTimerStart,
} from "../../../../redux/user/userReducer";
import { mobileOTPVerification } from "../../../../redux/user/userSlice";
import { parsePhoneNumber } from "libphonenumber-js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  height: "340px",
  p: 4,
};

const MobileNumberOtpModal = ({
  mobileOtpMobileModal,
  mobileOtpClose,
  userId,
  userMobileNumber,
  // setOtpVerifyText,
  otpVeriFyFunctionCheck,
  otpTimerStart,
  setOtpTimerStart,
  setIsMobileVerified,
}) => {
  // const [showWinnewModal, setShowWinnewModal] = useState(false);
  // const [showTimeWinnerModal, setShowTimeWinnerModal] = useState(false);
  // const navigate = useNavigate();
  const [otpError, setOtpError] = useState("");
  const [otp, setOtp] = useState();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [resendOtpDisabled, setResendOtpDisabled] = useState(false);
  // const [resendOtpData, setResendOtpData] = useState(false);
  // otp timer state
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);

  // OTP verify API call
  const handleFormSubmit = () => {
    const data = new URLSearchParams();
    let mobileNumberDetails = userMobileNumber
      ? parsePhoneNumber(userMobileNumber)
      : "";
    data.append("otp", otp);
    data.append("userId", userId);
    // data.append("mobileNumber", userMobileNumber?.substring(3));
    data.append("mobileNumber", mobileNumberDetails?.nationalNumber);
    data.append(
      "countryCode",
      mobileNumberDetails?.countryCallingCode
        ? mobileNumberDetails?.countryCallingCode
        : ""
    );
    data.append(
      "country",
      mobileNumberDetails?.country ? mobileNumberDetails?.country : ""
    );
    // setDisable(true);
    if (!isNaN(otp) && otp.length === 4) {
      setOtpError("");

      DataService.post(Api.User.MOBILE_OTP_VERIFY, data)
        .then((res) => {
          if (res.data.status == 200) {
            dispatch(mobileOTPVerification(res?.data?.data));
            toast.success(res?.data?.message, {
              toastId: "customId",
            });
            setOtp("");
            // setOtpVerifyText(true);
            setIsMobileVerified(false);
            mobileOtpClose();
            dispatch(getTotalCoins());
          } else {
            mobileOtpClose();
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            toastId: "customId",
          });
          if (error?.response?.data?.status) {
            setOtp("");
          }
        });
      // dispatch(mobileOTPVerify(data))
      //   .then((res) => {
      //     console.log(res, 124);
      //     if (res.payload.status == 200) {
      //       console.log(res.payload.status, 124);
      //       setOtp("");
      //       setOtpVerifyText(true);
      //       mobileOtpClose();
      //       dispatch(getTotalCoins());
      //     } else {
      //       mobileOtpClose();
      //     }
      //   })
      //   .catch((err) => {
      //     toast.error(err?.response?.data?.message, {
      //       toastId: "customId",
      //     });
      //     if (err?.response?.data?.status) {
      //       setOtp("");
      //     }
      //   });
    } else {
      setOtpError("OTP is required");
      setDisable(false);
    }
  };

  // OTP change function
  const handleChange = (newValue) => {
    setOtp(newValue);
    if (!isNaN(newValue) && newValue.length === 4) {
      setOtpError();
    }
  };

  const resendOtp = () => {
    setResendOtpDisabled(true);
    const data = new URLSearchParams();
    // data.append("mobileNumber", userMobileNumber?.substring(3));
    let mobileNumberDetails = userMobileNumber
      ? parsePhoneNumber(userMobileNumber)
      : "";
    data.append("mobileNumber", mobileNumberDetails?.nationalNumber);
    data.append(
      "countryCode",
      mobileNumberDetails?.countryCallingCode
        ? mobileNumberDetails?.countryCallingCode
        : ""
    );
    data.append(
      "country",
      mobileNumberDetails?.country ? mobileNumberDetails?.country : ""
    );
    data.append("userId", userId);
    DataService.post(Api.User.SEND_MOBILE_OTP, data)
      .then((res) => {
        // setIsDisabled(false);
        setTimeout(() => {
          setMinutes(1);
          setSeconds(59);
          setResendOtpDisabled(false);
        }, 1000);

        toast.success(res?.data?.message, {
          toastId: "customId",
        });

        // navigate("/user");
        // dispatch(userProfile());
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          toastId: "customId",
        });
        setResendOtpDisabled(false);
      });
  };

  // otp timer
  useEffect(() => {
    if (otpTimerStart) {
      setMinutes(1);
      setSeconds(59);
    }
  }, [otpTimerStart]);

  useEffect(() => {
    if (otpTimerStart) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, otpTimerStart]);
  return (
    <>
      <Index.Modal
        open={mobileOtpMobileModal}
        onClose={() => {
          mobileOtpClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-comman-details bg-noblur"
      >
        <Index.Box
          sx={style}
          className="signin-main-content modal-comman-inner-style"
        >
          {" "}
          <Index.Box className="modal-cancel-btn">
            <Index.Button className="btn btn-cancel">
              <img
                // src={PagesIndex.Svg.cancelmodal}
                src={PageIndex.Svg.cancelmodal}
                className="cancel-modal"
                alt="modal-cancel"
                onClick={() => {
                  mobileOtpClose();
                  setOtp("");
                  setMinutes(1);
                  setSeconds(59);
                }}
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="signin-content-details">
            <Index.Typography
              className="user-auth-title-comman"
              variant="h6"
              component="h6"
            >
              OTP
            </Index.Typography>
            <Index.Typography
              className="user-auth-details-comman"
              variant="p"
              component="p"
            >
              Enter your OTP.
            </Index.Typography>
          </Index.Box>
          <Index.Box className="comman-details-auth-user">
            <Index.Box className="otp-flex-main-user otp-custom-details">
              <MuiOtpInput
                className="otp-input"
                length={4}
                value={otp}
                onChange={handleChange}
                name="otp"
                inputProps={[{ inputMode: "numeric" }]}
                validateChar={(val) => !isNaN(val)}
              />
            </Index.Box>
            {otpError && (
              <Index.FormHelperText error>{otpError}</Index.FormHelperText>
            )}
            <Index.Box className="resend-pass-right">
              {/* <Index.Button
                className="redirect-resend"
                disabled={resendOtpDisabled}
                onClick={() => resendOtp()}
              >
                Resend OTP
              </Index.Button> */}
            </Index.Box>
            <Index.Box className="countdown-text reset--otp-main">
              {seconds > 0 || minutes > 0 ? (
                <p>
                  Time Remaining:
                  <span style={{ fontSize: "17px" }}>
                    {" "}
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                </p>
              ) : (
                ""
              )}
              {!(seconds > 0 || minutes > 0) && (
                <Index.Box className="resend-button resend-pass-right">
                  {/* <Index.Link
                                          disabled={seconds > 0 || minutes > 0}
                                          // style={{
                                          //   color:
                                          //     seconds > 0 || minutes > 0
                                          //       ? "#DFE3E8"
                                          //       : "#FF5630",
                                          // }}
                                          onClick={resendOTP}
                                        >
                                          Resend OTP
                                        </Index.Link> */}

                  <Index.Button
                    type="button"
                    btnLabel="Resend Otp"
                    onClick={() => resendOtp()}
                    className="redirect-resend"
                  >
                    {resendOtpDisabled ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      "Resend Otp"
                    )}
                  </Index.Button>
                </Index.Box>
              )}
            </Index.Box>
            <Index.Box className="btn-list-login-content">
              <Index.Box className="login-btn-list">
                <PageIndex.BlueButton
                  btnLabel="Submit"
                  className="blue-btn-content"
                  disabled={disable}
                  onClick={() => {
                    handleFormSubmit();
                  }}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default MobileNumberOtpModal;
