import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { styled, alpha } from "@mui/material/styles";
import PageIndex from "../../../pageIndex";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";
import Loader from "../../../../component/comman/loader/Loader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function WinnerDeclartionTabs() {
  const permission = useSelector((state) => state.AdminReducer.adminRoleData);
  const [gamelist, setGamelist] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [buttonRowID, setButtonRowID] = useState({});
  const navigate = useNavigate();
  const {gameNewName} = useOutletContext()

  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // old
  // const handleCommunityBetting = (gameId, gameName, gameType, periodFor) => {
  //   console.log("edit called", 48);
  //   // console.log(gameId,gameName,gameType,"uves45")
  //   if (gameType == "numberBetting") {
  //     navigate(`/admin/periods-table`, {
  //       state: { gameId: gameId, gameName: gameName, gameType: gameType },
  //     });
  //   } else if (gameType == "3colorBetting") {
  //     navigate(`/admin/periods-table`, {
  //       state: {
  //         gameId: gameId,
  //         gameName: gameName,
  //         gameType: gameType,
  //         periodFor: periodFor,
  //       },
  //     });
  //   } else if (gameType == "communityBetting") {
  //     navigate(`/admin/periods-table`, {
  //       state: { gameId: gameId, gameName: gameName, gameType: gameType },
  //     });
  //   } else if (gameType == "2colorBetting") {
  //     navigate(`/admin/periods-table`, {
  //       state: {
  //         gameId: gameId,
  //         gameName: gameName,
  //         gameType: gameType,
  //         periodFor: periodFor,
  //       },
  //     });
  //   } else if (gameType == "cardBetting") {
  //     navigate(`/admin/periods-table`, {
  //       state: {
  //         gameId: gameId,
  //         gameName: gameName,
  //         gameType: gameType,
  //         periodFor: periodFor,
  //       },
  //     });
  //   } else if (gameType == "penaltyBetting") {
  //     navigate(`/admin/periods-table`, {
  //       state: {
  //         gameId: gameId,
  //         gameName: gameName,
  //         gameType: gameType,
  //         periodFor: periodFor,
  //       },
  //     });
  //   }
  // };
  const handleCommunityBetting = (
    gameId,
    gameName,
    gameType,
    periodFor,
    period
  ) => {
    if (gameType == "numberBetting") {
      navigate(`/admin/Number-trading-edit`, {
        state: {
          gameId: gameId,
          gameName: gameName,
          gameType: gameType,
          period: period,
        },
      });
    } else {
      navigate(`/admin/community-trading-swap`, {
        state: {
          gameId: gameId,
          gameName: gameName,
          gameType: gameType,
          period: period,
        },
      });
    }
  };
  // search

  const handleSearch = (searched) => {
    if (!searched) return setSearchedData(gamelist);
    setSearchedData(
      gamelist.filter(
        (item) =>
          gameNewName(item?.gameName)
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.count
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.betAmount
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setSearchedData(gamelist);
  }, [gamelist]);

  const getAllGamesList = async (refresh) => {
    if (!refresh) {
      setLoading(true);
    }
    // await DataService.post(Api.ADMIN_GET_UPDATE_WINNERS_USER)
    await DataService.get(Api.ADMIN_GET_UPDATE_WINNERS_USER)
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          setGamelist(res?.data?.data);
        }, 1000);
      })
      .catch((e) => {
        // toast.error(
        //   e.response.data.message ? e.response.data.message : e.message
        // );
        setLoading(false);
      });
  };

  //   ADMIN_GET_USERS_WINNERS_COMMUNITY_BETTING
  useEffect(() => {
    getAllGamesList();
    const interval = setInterval(() => {
      getAllGamesList("refresh");
    }, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // winner declaration apis
  const colorWin = (gameId, selectedTime, period, color) => {
    setBtnLoading(true);
    setButtonRowID({
      period: period,
      color: color,
    });

    const data = {
      gameId: gameId,
      winColour: color,
      winnerId: period,
      periodFor: selectedTime,
    };
    DataService.post(Api.ADMIN_DECLARE_WINNER_COLOR_BETTING, data)
      .then((res) => {
        if (res) {
          toast.success(`Win ${color} is added successfully!`);
          getAllGamesList("refresh");
          setTimeout(() => {
            setBtnLoading(false);
            setButtonRowID({});
          }, 1000);
        }
      })
      .catch((e) => {
        toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setBtnLoading(false);
      });
  };

  // penalty winner fun
  // Winner model
  const penaltyWin = (gameId, selectedTime, period, color) => {
    setBtnLoading(true);
    setButtonRowID({
      period: period,
      color: color,
    });
    const data = {
      gameId: gameId,
      winBetSide: color,
      winnerId: period,
      periodFor: selectedTime,
    };
    DataService.post(Api.ADMIN_DECLARE_WINNER_PENALTY_BETTING, data)
      .then((res) => {
        if (res) {
          toast.success(`Win ${color} is added successfully!`);
          getAllGamesList("refresh");
          // setBtnLoading(false);
          setTimeout(() => {
            setBtnLoading(false);
            setButtonRowID({});
          }, 1000);
        }
      })
      .catch((e) => {
        // toast.error(
        //   e.response.data.message ? e.response.data.message : e.message
        // );
        setBtnLoading(false);
      });
  };

  // card betting edit

  const cardWin = (gameId, selectedTime, period, color) => {
    setBtnLoading(true);
    setButtonRowID({
      period: period,
      color: color,
    });
    const data = {
      gameId: gameId,
      winCard: color,
      winnerId: period,
      periodFor: selectedTime,
    };
    DataService.post(Api.ADMIN_DECLARE_WINNER_CARD_BETTING, data)
      .then((res) => {
        if (res) {
          toast.success(`Win ${color} is added successfully!`);
          getAllGamesList("refresh");
          setTimeout(() => {
            setBtnLoading(false);
            setButtonRowID({});
          }, 1000);
        }
      })
      .catch((e) => {
        setBtnLoading(false);
      });
  };

  if (
    permission?.isAdmin == true ||
    (permission?.role?.WinnerDeclaration?.View == true &&
      permission?.isAdmin == false)
  ) {
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <Index.Box className="page-content-box">
            {/* <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                Winner Declaration
              </Index.Typography>
            </Index.Box> */}
            <Index.Box className="title-header">
              <Index.Box className="title-header-flex res-title-header-flex ipad-flex-small-content">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Winner Declaration
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="d-flex align-items-center res-set-search">
                  <Search className="search admin-search-comman cus-penalty-search-main">
                    <StyledInputBase
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                      onChange={(e) => handleSearch(e.target.value)}
                      className="cus-penalty-search"
                    />
                  </Search>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* <Index.Box className="table-search-winner">
              <Index.Box className="input-search-details res-set-search">
                <Index.Box className="search admin-search-comman ">
                  <Search className="search admin-search-comman">
                    <StyledInputBase
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </Search>
                </Index.Box>
              </Index.Box>
            </Index.Box> */}

            <Index.Box className="table-winner-details">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  aria-label="simple table"
                  className="table-design-main barge-table banner-management-table winner-management-table"
                >
                  <Index.TableHead className="number-bet-thead">
                    <Index.TableRow className="number-bet-tr">
                      <Index.TableCell className="number-bet-th">
                        Sr no
                      </Index.TableCell>
                      <Index.TableCell className="number-bet-th" align="left">
                        Game Name
                      </Index.TableCell>
                      <Index.TableCell className="number-bet-th" align="left">
                        Total User
                      </Index.TableCell>
                      <Index.TableCell className="number-bet-th" align="left">
                        Session
                      </Index.TableCell>
                      <Index.TableCell className="number-bet-th" align="left">
                        Duration
                      </Index.TableCell>
                      <Index.TableCell className="number-bet-th" align="left">
                        Total Bet Amount
                      </Index.TableCell>
                      {/* <Index.TableCell className="number-bet-th" align="left">
                  Date. of Completion
                </Index.TableCell>
                <Index.TableCell className="number-bet-th" align="left">
                  Time of Completion
                </Index.TableCell> */}
                      <Index.TableCell className="number-bet-th" align="left">
                        {/* Action */}
                        {permission?.isAdmin == true ||
                        (permission?.role?.WinnerDeclaration?.update == true &&
                          permission?.isAdmin == false)
                          ? "Action"
                          : ""}
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="number-bet-tbody">
                    {searchedData?.length ? (
                      <>
                        {searchedData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((ele, index) => {
                            // console.log(ele?.type?.[0]?.betAmount, 245);
                            // console.log(ele?.type?.[0], 245);

                            return (
                              <Index.TableRow className="number-bet-tr">
                                <Index.TableCell className="number-bet-td">
                                  <Index.Box className="sr-no-details">
                                    {index + 1}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="number-bet-td">
                                  <Index.Box className="game-name-details">
                                    {gameNewName(ele?.gameName)}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="number-bet-td">
                                  <Index.Box className="game-name-details">
                                    {ele?.totalUsers}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="number-bet-td">
                                  <Index.Box className="game-name-details">
                                    {ele?.period}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="number-bet-td">
                                  <Index.Box className="game-name-details">
                                    {ele?.selectedTime}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className="number-bet-td">
                                  <Index.Box className="game-name-details">
                                    {ele?.totalBetAmount}
                                  </Index.Box>
                                </Index.TableCell>
                                {/* <Index.TableCell className="number-bet-td">
                      <Index.Box className="date-coin-details">
                        27-09-2023
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell className="number-bet-td">
                      <Index.Box className="time-coin-details">13:30</Index.Box>
                    </Index.TableCell> */}
                                <Index.TableCell className="number-bet-td">
                                  <Index.Box
                                    className="action-details"
                                    sx={{ display: "flex" }}
                                  >
                                    {/* <Index.IconButton> */}
                                    {permission?.isAdmin == true ||
                                    (permission?.role?.WinnerDeclaration
                                      ?.update == true &&
                                      permission?.isAdmin == false) ? (
                                      <>
                                        {ele?.gameType == "numberBetting" ||
                                        ele?.gameType == "communityBetting" ? (
                                          <Index.Button
                                            className="table-view-btn"
                                            onClick={() =>
                                              handleCommunityBetting(
                                                ele?.gameId,
                                                ele?.gameName,
                                                ele?.gameType,
                                                ele?.periodFor,
                                                ele?.period
                                              )
                                            }
                                          >
                                            <img
                                              src={PageIndex.Svg.pencil}
                                              className="view-icon-btn"
                                            />
                                          </Index.Button>
                                        ) : (
                                          ""
                                        )}
                                        {ele?.gameType == "2colorBetting" ||
                                        ele?.gameType == "3colorBetting" ? (
                                          <>
                                            <Index.Button
                                              onClick={() =>
                                                colorWin(
                                                  ele?.gameId,
                                                  ele?.selectedTime,
                                                  ele?.period,
                                                  "red"
                                                )
                                              }
                                              // disabled={btnLoading}
                                              // disabled={
                                              //   btnLoading &&
                                              //   buttonRowID?.period == ele?.period &&
                                              //   ele?.type?.some((item) =>
                                              //     console.log(
                                              //       item.bet,
                                              //       buttonRowID?.color,
                                              //       456
                                              //     )
                                              //   )
                                              // ||
                                              // ele?.type?.some(
                                              //   (item) =>
                                              //     item.bet === buttonRowID?.color
                                              // )
                                              // }

                                              disabled={
                                                btnLoading &&
                                                buttonRowID?.period ==
                                                  ele?.period &&
                                                buttonRowID?.color == "red"
                                              }
                                              style={{
                                                fontWeight: "bold",
                                                color: "white",
                                                backgroundColor: "red",
                                                marginRight: "5px",
                                              }}
                                              // disabled={
                                              //   !ele?.type?.some(
                                              //     (e) =>
                                              //       e?.bet == "red" && e?.betAmount
                                              //   )
                                              // }
                                              // disabled={btnLoading}
                                            >
                                              {ele?.type?.find(
                                                (item) => item.bet === "red"
                                              )?.betAmount || 0}
                                            </Index.Button>

                                            <Index.Button
                                              variant="contained"
                                              style={{
                                                fontWeight: "bold",
                                                color: "white",
                                                backgroundColor: "green",
                                                marginRight: "5px",
                                              }}
                                              // disabled={
                                              //   !ele?.type?.some(
                                              //     (e) =>
                                              //       e?.bet == "green" && e?.betAmount
                                              //   )
                                              // }
                                              disabled={
                                                btnLoading &&
                                                buttonRowID?.period ==
                                                  ele?.period &&
                                                buttonRowID?.color == "green"
                                              }
                                              onClick={() =>
                                                colorWin(
                                                  ele?.gameId,
                                                  ele?.selectedTime,
                                                  ele?.period,
                                                  "green"
                                                )
                                              }
                                              // disabled={btnLoading}
                                              // onClick={() => navigate("/admin/banner")}
                                            >
                                              {/* Green */}
                                              {/* {ele?.type?.map((items) => {
                                          if (items?.bet == "green") {
                                            return items?.betAmount;
                                          } else {
                                            return 0;
                                          }
                                        })} */}
                                              {ele?.type?.find(
                                                (item) => item.bet === "green"
                                              )?.betAmount || 0}
                                            </Index.Button>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {ele?.gameType == "3colorBetting" ? (
                                          <Index.Button
                                            style={{
                                              fontWeight: "bold",
                                              color: "white",
                                              backgroundColor: "orange",
                                              marginRight: "5px",
                                            }}
                                            // disabled={
                                            //   !ele?.type?.some(
                                            //     (e) =>
                                            //       e?.bet == "orange" && e?.betAmount
                                            //   )
                                            // }
                                            disabled={
                                              btnLoading &&
                                              buttonRowID?.period ==
                                                ele?.period &&
                                              buttonRowID?.color == "orange"
                                            }
                                            onClick={() =>
                                              colorWin(
                                                ele?.gameId,
                                                ele?.selectedTime,
                                                ele?.period,
                                                "orange"
                                              )
                                            }
                                            // onClick={() => navigate("/admin/banner")}
                                          >
                                            {/* Orange */}
                                            {/* {ele?.type?.map((items) => {
                                        if (items?.bet == "orange") {
                                          return items?.betAmount;
                                        } else {
                                          return 0;
                                        }
                                      })} */}
                                            {ele?.type?.find(
                                              (item) => item.bet === "orange"
                                            )?.betAmount || 0}
                                          </Index.Button>
                                        ) : (
                                          ""
                                        )}
                                        {ele?.gameType == "penaltyBetting" ? (
                                          <>
                                            <Index.Button
                                              style={{
                                                minWidth: "134px",
                                                justifyContent: "flex-start",
                                                fontWeight: "bold",
                                                color: "white",
                                                backgroundColor: "green",
                                                marginRight: "5px",
                                              }}
                                              //                                        gameId: id,
                                              // winBetSide: winnerPenalty,
                                              // winnerId: periodId,
                                              // periodFor: time,
                                              onClick={() =>
                                                penaltyWin(
                                                  ele?.gameId,
                                                  ele?.selectedTime,
                                                  ele?.period,
                                                  "left"
                                                )
                                              }
                                              disabled={
                                                btnLoading &&
                                                buttonRowID?.period ==
                                                  ele?.period &&
                                                buttonRowID?.color == "left"
                                              }
                                            >
                                              Left{" "}
                                              <span
                                                style={{
                                                  marginLeft: "15px",
                                                  color: "red",
                                                }}
                                              >
                                                {ele?.type.find(
                                                  (item) => item?.bet === "left"
                                                )?.betAmount || 0}
                                              </span>
                                            </Index.Button>
                                            <Index.Button
                                              style={{
                                                minWidth: "134px",
                                                justifyContent: "flex-start",
                                                fontWeight: "bold",
                                                color: "white",
                                                backgroundColor: "green",
                                                marginRight: "5px",
                                              }}
                                              onClick={() =>
                                                penaltyWin(
                                                  ele?.gameId,
                                                  ele?.selectedTime,
                                                  ele?.period,
                                                  "right"
                                                )
                                              }
                                              disabled={
                                                btnLoading &&
                                                buttonRowID?.period ==
                                                  ele?.period &&
                                                buttonRowID?.color == "right"
                                              }
                                              // disabled={
                                              //   !ele?.type?.some(
                                              //     (e) =>
                                              //       e?.bet == "right" && e?.betAmount
                                              //   )
                                              // }
                                            >
                                              {/* Right
                                          {ele?.type?.map((items) => {
                                            if (items?.bet == "right") {
                                              return items?.betAmount;
                                            } else {
                                              return 0;
                                            }
                                          })} */}
                                              {/* Right
                                          {ele?.type?.map((items, index) => (
                                            <span
                                              key={index}
                                              style={{
                                                color:
                                                  items?.bet === "right"
                                                    ? "red"
                                                    : "inherit",
                                              }}
                                            >
                                              {items?.bet === "right"
                                                ? items?.betAmount
                                                : 0}
                                            </span>
                                          ))} */}
                                              Right{" "}
                                              <span
                                                style={{
                                                  marginLeft: "15px",
                                                  color: "red",
                                                }}
                                              >
                                                {ele?.type.find(
                                                  (item) => item.bet === "right"
                                                )?.betAmount || 0}
                                              </span>
                                            </Index.Button>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {ele?.gameType == "cardBetting" ? (
                                          <>
                                            {" "}
                                            <Index.Button
                                              style={{
                                                minWidth: "134px",
                                                justifyContent: "flex-start",
                                                fontWeight: "bold",
                                                color: "white",
                                                backgroundColor: "green",
                                                marginRight: "5px",
                                              }}
                                              onClick={() =>
                                                cardWin(
                                                  ele?.gameId,
                                                  ele?.selectedTime,
                                                  ele?.period,
                                                  "low"
                                                )
                                              }
                                              disabled={
                                                btnLoading &&
                                                buttonRowID?.period ==
                                                  ele?.period &&
                                                buttonRowID?.color == "low"
                                              }
                                              // disabled={
                                              //   !ele?.type?.some(
                                              //     (e) =>
                                              //       e?.bet == "low" && e?.betAmount
                                              //   )
                                              // }
                                            >
                                              {/* Low */}
                                              {/* {ele?.type?.map((items) => {
                                            if (items?.bet == "low") {
                                              return items?.betAmount;
                                            } else {
                                              return 0;
                                            }
                                          })} */}
                                              Low{" "}
                                              <span
                                                style={{
                                                  marginLeft: "15px",
                                                  color: "red",
                                                }}
                                              >
                                                {ele?.type.find(
                                                  (item) => item.bet === "low"
                                                )?.betAmount || 0}
                                              </span>
                                            </Index.Button>
                                            <Index.Button
                                              style={{
                                                minWidth: "134px",
                                                justifyContent: "flex-start",
                                                fontWeight: "bold",
                                                color: "white",
                                                backgroundColor: "green",
                                                marginRight: "5px",
                                              }}
                                              onClick={() =>
                                                cardWin(
                                                  ele?.gameId,
                                                  ele?.selectedTime,
                                                  ele?.period,
                                                  "high"
                                                )
                                              }
                                              disabled={
                                                btnLoading &&
                                                buttonRowID?.period ==
                                                  ele?.period &&
                                                buttonRowID?.color == "high"
                                              }
                                              // disabled={
                                              //   !ele?.type?.some(
                                              //     (e) =>
                                              //       e?.bet == "high" && e?.betAmount
                                              //   )
                                              // }
                                            >
                                              {/* High */}
                                              {/* {ele?.type?.map((items) => {
                                            if (items?.bet == "high") {
                                              return items?.betAmount;
                                            } else {
                                              return 0;
                                            }
                                          })} */}
                                              High{" "}
                                              <span
                                                style={{
                                                  marginLeft: "15px",
                                                  color: "red",
                                                }}
                                              >
                                                {ele?.type.find(
                                                  (item) => item?.bet === "high"
                                                )?.betAmount || 0}
                                              </span>
                                            </Index.Button>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {/* </Index.IconButton> */}
                                    {/* <Index.Box className="add-game-button"> */}
                                    {/* <Index.Box className="common-button blue-button flex-start save-btn add-submit-btn">
                                <Index.Button
                                  variant="contained"
                                  // onClick={() => navigate("/admin/banner")}
                                >
                                  Red
                                </Index.Button>
                              </Index.Box>

                              <Index.Box className="common-button blue-button flex-start save-btn add-submit-btn">
                                <Index.Button
                                  variant="contained"
                                  // onClick={}
                                >
                                  Green
                                </Index.Button>
                              </Index.Box> */}
                                  </Index.Box>
                                  {/* </Index.Box> */}
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        <Index.NoDataFound
                          colSpan={7}
                          message={"No winner declaration found"}
                        />
                      </>
                    )}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
              {searchedData?.length ? (
                <Index.TablePagination
                  className="paginationColor"
                  component="div"
                  page={page}
                  count={searchedData?.length}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={(page) =>
                    `Records ${page.from} to ${
                      page.to === -1 ? page.count : page.to
                    } of ${page.count}`
                  }
                />
              ) : (
                ""
              )}
            </Index.Box>
          </Index.Box>
        )}
      </>
    );
  } else {
    navigate("/admin");
  }
}
