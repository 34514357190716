import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { Api } from "../config/Api";
import { useSelector } from "react-redux";

var socket = io(Api.common.BASE_URL);

const connectSocket = () => {
  if (!socket.connected) {
    socket.connect();
  }
};

const disconnectSocket = () => {
  //   if (socket.connected) {
  //     socket.disconnect();
  //   }
};

const ChatSocketContext = createContext();

export const SocketProviderChat = ({ children }) => {
  const user = useSelector((state) => state.UserReducer.userData);
  const [isImage, setIsImage] = useState(false);

  const [chat, setChat] = useState([]);
  useEffect(() => {
    let data = {
      room_id: 1,
      user_id: user?._id,
    };

    if (socket) {
      socket.emit("JoinChat", data);
      socket.on("Message", (chat) => {
        setChat(chat);

      });


      return () => {
        socket.off("Message");
      };
    }

  }, [socket, isImage]);

  useEffect(() => {
    connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);
  return (
    <ChatSocketContext.Provider value={{ socket, chat, setChat, setIsImage, Image }}>{children}</ChatSocketContext.Provider>
  );
};

export const useSocketChat = () => {
  return useContext(ChatSocketContext);
};
