import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { styled, alpha } from "@mui/material/styles";
import "../../../../assets/style/global.css";
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import Loader from "../../../../component/comman/loader/Loader";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const filterGameList = [
  { id: 1, name: "All", value: "all" },
  { id: 2, name: "2 Color Betting", value: "2 Color Betting" },
  { id: 3, name: "3 Color Betting", value: "3 Color Betting" },
  { id: 4, name: "Card Betting", value: "Card Betting" },
  { id: 5, name: "Number Betting", value: "Number Betting" },
  { id: 6, name: "Community Betting", value: "Community Betting" },
];
const BetHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const permission = useSelector((state) => state.AdminReducer.adminRoleData);
  const [loading, setLoading] = useState(true);
  const [betList, setBetList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterByPriority, setFilterByPriority] = useState("all");
  const [search, setSearch] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [date, setDate] = useState(null);
  const [dateRange, setDateRange] = useState({ startRange: "", endRange: "" });
  const {gameNewName} = useOutletContext()
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getBetHistory = async () => {
    await DataService.get(Api.ADMIN_BET_HISTORY_LIST)
      .then((res) => {
        setLoading(false);
        // setBetList(res?.data?.data?.reverse());
        setBetList(
          res?.data?.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        );
        // setFilterData(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getBetHistoryByTime = async () => {
    await DataService.post(Api.ADMIN_GET_BET_HISTORY_BY_TIME, {
      filterBy: "Today",
    })
      .then((res) => {
        setLoading(false);
        // setBetList(res?.data?.data);
        setBetList(
          res?.data?.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        );

        // setFilterData(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (location?.pathname === "/admin/bet-history-of-24-hrs") {
      getBetHistoryByTime();
    } else {
      getBetHistory();
    }
  }, [location?.pathname]);

  // Initial data filter by game
  useEffect(() => {
    let filtered;
    let filterDate = [];
    if (filterByPriority && filterByPriority !== "all") {
      filtered = betList?.filter(
        (ele) =>
          ele?.gameId?.gameName?.toLowerCase() ===
          filterByPriority.toLowerCase()
      );
    } else {
      filtered = betList;
    }
    if (filtered?.length > 0) {
      if (dateRange?.startRange && dateRange?.endRange) {
        const startRange = Index.moment(dateRange.startRange, "DD/MM/YYYY");
        const endRange = Index.moment(dateRange.endRange, "DD/MM/YYYY");
  
        const filterDate = filtered.filter((item) => {
          const itemDate = Index.moment(item?.createdAt);
          return itemDate.isBetween(startRange, endRange, "day", "[]"); // '[]' includes the start and end dates
        });
  
        setFilterData(filterDate);
      } else {
        setFilterData(filtered);
      }
    } else {
      setFilterData([]);
    }
    setPage(0);
  }, [betList, filterByPriority, date, dateRange]);

  // Search on table new

  useEffect(() => {
    const filtered = filterData?.filter((item) => {
      return !search
        ? item
        : gameNewName(item?.gameId?.gameName)
            ?.toString()
            ?.toLowerCase()
            ?.includes(search?.toLowerCase()) ||
            item?.period
              ?.toString()
              ?.toLowerCase()
              ?.includes(search?.toLowerCase()) ||
            item?.userId?.fullName
              ?.toString()
              ?.toLowerCase()
              ?.includes(search?.toLowerCase());
    });

    if (filtered?.length > 0) {
      setSearchedData(filtered);
    } else {
      setSearchedData([]);
    }
    setPage(0);
  }, [filterData, search]);
  if (
    permission?.isAdmin == true ||
    (permission?.role?.BettingRecords?.View == true &&
      permission?.isAdmin == false)
  ) {
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Index.Box className="page-content-box">
              <Index.Box className="barge-common-box">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        {location?.pathname === "/admin/bet-history-of-24-hrs"
                          ? "Bet Made In 24 Hours"
                          : "Betting Records"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="d-flex align-items-center res-set-search deposit-req-main">
                     
                      <Index.Box className="add-game-dropdown game-select">
                        <Index.Box className=" signin-drop-details">
                          <Index.FormControl className="formcontrol_login sign-in-inner-form">
                            <Index.Select
                              className="currency-select-drop"
                              displayEmpty
                              value={filterByPriority}
                              label="Game"
                              onChange={(event, newValue) => {
                                setTimeout(() => {
                                  setFilterByPriority(event.target.value);
                                }, 1000);
                              }}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              {filterGameList &&
                                filterGameList.map((val) => (
                                  <Index.MenuItem
                                    key={val.id}
                                    value={val.value}
                                    className="currency-select-menu"
                                  >
                                    {gameNewName(val.name)}
                                  </Index.MenuItem>
                                ))}
                            </Index.Select>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="transation-date-picker admin-datepicker-main cus-date-color-main">
                        {location?.pathname !== "/admin/bet-history-of-24-hrs" && (
                          <DatePicker.RangePicker
                          className="admin-datepicker-inner cus-date-color"
                          inputReadOnly={true}
                          onChange={(range) => {
                            if(range && range.length){
                              let [startRange, endRange] = range;
                              setDateRange({
                                startRange: Index.moment(startRange?.$d).format("DD/MM/YYYY"),
                                endRange: Index.moment(endRange?.$d).format("DD/MM/YYYY")
                              })
                            }else{
                              setDateRange({startRange: "", endRange: ""})
                            }
                            console.log("Date picker: ", range);
                          }}
                          format="DD/MM/YYYY"
                          disabledDate={(current) => {
                            // Can not select days after today
                            return current && current > Index.moment().endOf("day");
                          }}
                        />
                        )}
                      </Index.Box>
                      <Search className="search admin-search-comman cus-penalty-search-main">
                        <StyledInputBase
                          placeholder="Search"
                          inputProps={{ "aria-label": "search" }}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          className="cus-penalty-search"
                        />
                      </Search>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Grid container spacing={3} mb={2}></Index.Grid>

                <Index.Box className="">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container role-management-conatiner "
                  >
                    <Index.Table
                      aria-label="simple table"
                      className="table-design-main barge-table table-user-management"
                    >
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell align="left">Sr no</Index.TableCell>
                          <Index.TableCell align="left">
                            Period Id
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            User Name
                          </Index.TableCell>
                          <Index.TableCell align="left">Game</Index.TableCell>
                          <Index.TableCell align="left">
                            Bet amount
                          </Index.TableCell>
                          <Index.TableCell align="left">Result</Index.TableCell>
                          <Index.TableCell align="left">
                            Date & Time
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {searchedData?.length ? (
                          <>
                            {" "}
                            {searchedData
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              ?.map((data, index) => (
                                <Index.TableRow key={index}>
                                  <Index.TableCell>{index + 1}</Index.TableCell>
                                  <Index.TableCell>
                                    {data?.period ? data?.period : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    {data?.userId?.fullName
                                      ? data?.userId?.fullName
                                      : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    {data?.gameId?.gameName
                                      ? gameNewName(data?.gameId?.gameName)
                                      : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    {data?.betAmount ? data?.betAmount : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    {data?.status === "pending"
                                      ? "Pending"
                                      : data?.status?.toLowerCase() === "refund"
                                      ? "Refund"
                                      : data?.isWin ? "Win" : "Loss"}
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    {data?.createdAt
                                      ? Index.moment(data?.createdAt).format(
                                          "DD/MM/YYYY HH:mm"
                                        )
                                      : "-"}
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))}
                          </>
                        ) : (
                          <>
                            <Index.NoDataFound
                              colSpan={7}
                              message={"No betting records found"}
                            />
                          </>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                  {searchedData?.length ? (
                    <Index.TablePagination
                      className="paginationColor"
                      component="div"
                      page={page}
                      count={searchedData?.length}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </>
        )}
      </>
    );
  } else {
    navigate("/admin");
  }
};

export default BetHistory;
