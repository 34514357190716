export function formatCompactNumber(number) {
  const formatter = Intl.NumberFormat("en", {
    notation: "compact",
    maximumFractionDigits: 1,
  });

  const formattedNumber = formatter.format(number);
  

  const suffixes = ["K", "M", "B", "T"];
  for (const suffix of suffixes) {
    if (formattedNumber.endsWith(suffix) && !formattedNumber.includes(".")) {
      const baseValue = parseFloat(
        (number / Math.pow(10, 3 * (suffixes.indexOf(suffix) + 1))).toFixed(1)
      );
      return `${baseValue}${suffix}`;
    }
  }

  return formattedNumber;
}
