import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import "../../../../assets/style/global.css";
import PagesIndex from "../../../pageIndex";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";
import "../../../../assets/style/global.css";
import PageIndex from "../../../pageIndex";
import DeleteIcon from "@mui/icons-material/Delete";
import { Select, MenuItem } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Loader from "../../../../component/comman/loader/Loader";

// const queryStatusArr = ["Pending", "In Progress", "Resolved", "Rejected"];
const Query = () => {
  const [queryData, setQueryData] = useState([]);
  const permission = useSelector((state) => state.AdminReducer.adminRoleData);
  const [loading, setLoading] = useState(false);

  const navigate = PagesIndex.useNavigate();
  // const [statusArr, setStatusArr] = useState(queryStatusArr);
  // Pagination states and methods
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  // End Pagination

  const handleView = (queryData) => {
    navigate("/admin/viewquery", { state: { queryData } });
  };

  async function smpFunc(refresh) {
    if (!refresh) {
      setLoading(true);
    }
    const urlencoded = new URLSearchParams();
    await DataService.get(Api.ADMIN_GET_QUERY, urlencoded)
      .then((res) => {
        setLoading(false);
        setQueryData(res.data ? res.data.data : "No query available");
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    smpFunc();
  }, []);

  const queryAprrove = async (id, status) => {
    const data = new URLSearchParams();
    data.append("queryId", id);
    data.append("status", status);
    await DataService.post(Api.ADMIN_QUERY_APPROVE_REJECT, data)
      .then((res) => {
        toast.success(res?.data?.message, {
          toastId: "customId",
        });
        if (res?.data?.status === 200) {
          smpFunc("refresh");
        }
      })
      .catch((e) => {
        toast.error(e.res?.val?.message ? e.res?.val?.message : e.message);
      });
  };

  const queryDelete = async (id) => {
    const data = new URLSearchParams();
    data.append("queryId", id);
    await DataService.post(Api.ADMIN_QUERY_DELETE, data)
      .then((res) => {
        toast.success(res?.data?.message, {
          toastId: "customId",
        });
        if (
          res?.data?.status === 200 ||
          res?.data?.status === 400 ||
          res?.data?.status === 404
        ) {
          smpFunc("refresh");
        }
      })
      .catch((e) => {
        toast.error(e.res?.val?.message ? e.res?.val?.message : e.message);
      });
  };

  const handleStatusChange = (id, event) => {
    const status = event.target.value;
    queryAprrove(id, status);
  };

  const getStatusAvaliableForUser = (currentStatus) => {
    if (currentStatus === "Pending") {
      return ["In Progress", "Resolved", "Rejected"];
    } else if (currentStatus === "In Progress") {
      return ["Resolved", "Rejected"];
    } else {
      return ["Pending", "In Progress", "Resolved", "Rejected"];
    }
  };

  if (
    permission?.isAdmin === true ||
    (permission?.role?.Query?.View === true && permission?.isAdmin === false)
  ) {
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Query
                    </Index.Typography>
                  </Index.Box>
                  <Index.IconButton className="deposit-refresh-main">
                    <RefreshIcon
                      className="deposit-refresh-main-icon"
                      onClick={() => {
                        smpFunc("refresh");
                      }}
                    />
                  </Index.IconButton>
                </Index.Box>
              </Index.Box>
              <Index.Grid container spacing={3} mb={2}></Index.Grid>

              <Index.Box className="">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container query-table-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main  barge-table query-table-details"
                  >
                    <Index.TableHead className="query-table-head">
                      <Index.TableRow className="query-table-tr">
                        <Index.TableCell className="query-table-th">
                          Name
                        </Index.TableCell>
                        <Index.TableCell className="query-table-th">
                          Email
                        </Index.TableCell>
                        <Index.TableCell
                          className="query-table-th"
                          align="left"
                        >
                          Phone Number
                        </Index.TableCell>
                        <Index.TableCell
                          className="query-table-th"
                          align="left"
                        >
                          Description
                        </Index.TableCell>
                        <Index.TableCell
                          className="query-table-th"
                          align="left"
                        >
                          {/* Status */}
                          {permission?.isAdmin === true ||
                          (permission?.role?.Query?.update === true &&
                            permission?.isAdmin === false)
                            ? "Status"
                            : ""}
                        </Index.TableCell>
                        <Index.TableCell
                          className="query-table-th"
                          align="left"
                        >
                          Action
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="query-table-tbody">
                      {queryData.length ? (
                        queryData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((val) => {
                            return (
                              <Index.TableRow
                                key={val}
                                className="query-table-tr"
                              >
                                <Index.TableCell className="query-table-td">
                                  {val.userName}
                                </Index.TableCell>
                                <Index.TableCell className="query-table-td">
                                  {val.email}
                                </Index.TableCell>
                                <Index.TableCell className="query-table-td">
                                  {val.mobileNumber}
                                </Index.TableCell>
                                <Index.TableCell className="query-table-td">
                                  <Index.Box className="description-ellipsis-comman">
                                    {val?.description &&
                                    val.description.length > 50
                                      ? `${val.description.slice(0, 50)}...`
                                      : val.description}
                                  </Index.Box>
                                </Index.TableCell>

                                <Index.TableCell className="query-table-td">
                                  {permission?.isAdmin === true ||
                                  (permission?.role?.Query?.update === true &&
                                    permission?.isAdmin === false) ? (
                                    <>
                                      <Select
                                        value={val?.status || "Pending"}
                                        onChange={(event) =>
                                          handleStatusChange(val?._id, event)
                                        }
                                        displayEmpty
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                        className="status-select custom-status-select"
                                        style={{ color: "#f9f9f9" }}
                                        MenuProps={{
                                          PaperProps: {
                                            style: {
                                              backgroundColor: "#333",
                                              color: "#f9f9f9",
                                            },
                                          },
                                        }}
                                        disabled={
                                          val?.status === "Rejected" ||
                                          val?.status === "Resolved"
                                        }
                                      >
                                        <MenuItem
                                          value={val?.status}
                                          // hidden
                                          style={{
                                            display: "none",
                                            color: "#f9f9f9",
                                            fontSize: "12px",
                                          }}
                                          // disabled={val?.status !== statusValue && val?.status !== "In Progress"}
                                        >
                                          {val?.status}
                                        </MenuItem>
                                        {getStatusAvaliableForUser(
                                          val?.status
                                        ).map((statusValue) => (
                                          <MenuItem
                                            value={statusValue}
                                            key={statusValue}
                                            style={{
                                              color: "#f9f9f9",
                                              fontSize: "12px",
                                            }}
                                            // disabled={val?.status !== statusValue && val?.status !== "In Progress"}
                                          >
                                            {statusValue}
                                          </MenuItem>
                                        ))}
                                        {/* <MenuItem
                                    value="Pending"
                                    style={{ color: '#f9f9f9', fontSize: '12px' }}
                                    disabled={val?.status !== "Pending" && val?.status !== "In Progress"}
                                  >
                                    Pending
                                  </MenuItem>
                                  <MenuItem
                                    value="Resolved"
                                    style={{ color: '#f9f9f9', fontSize: '12px' }}
                                    disabled={val?.status === "Rejected" || val?.status === "Resolved"}
                                  >
                                    Resolved
                                  </MenuItem>
                                  <MenuItem
                                    value="Rejected"
                                    style={{ color: '#f9f9f9', fontSize: '12px' }}
                                    disabled={val?.status === "Rejected" || val?.status === "Resolved"}
                                  >
                                    Rejected
                                  </MenuItem>
                                  <MenuItem
                                    value="In Progress"
                                    style={{ color: '#f9f9f9', fontSize: '12px' }}
                                    disabled={val?.status === "Rejected" || val?.status === "Resolved"}
                                  >
                                    In Progress
                                  </MenuItem> */}
                                      </Select>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Index.TableCell>

                                <Index.TableCell className="query-table-td">
                                  <Index.IconButton
                                    onClick={(e) => {
                                      setQueryData(val);
                                      // handleClickMenu(e);
                                    }}
                                  >
                                    {permission?.isAdmin === true ||
                                    (permission?.role?.Query?.View === true &&
                                      permission?.isAdmin === false) ? (
                                      <>
                                        <Index.Button
                                          className="table-view-btn"
                                          onClick={() => {
                                            handleView(val);
                                          }}
                                        >
                                          <img
                                            src={PageIndex.Svg.eye}
                                            className="view-icon-btn"
                                            alt=""
                                          />
                                        </Index.Button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Index.IconButton>

                                  {permission?.isAdmin === true ||
                                  (permission?.role?.Query?.delete === true &&
                                    permission?.isAdmin === false) ? (
                                    <>
                                      <Index.Button
                                        className="table-view-btn"
                                        disableRipple
                                        onClick={() => {
                                          queryDelete(val?._id);
                                        }}
                                      >
                                        <DeleteIcon color="error" />
                                      </Index.Button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                      ) : (
                        <Index.NoDataFound
                          colSpan={6}
                          message={"No query found"}
                        />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>

                {queryData?.length && (
                  <Index.TablePagination
                    className="paginationColor"
                    component="div"
                    page={page}
                    count={queryData?.length}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    // rowsPerPageOptions={[10, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={(page) =>
                      `Records ${page.from} to ${
                        page.to === -1 ? page.count : page.to
                      } of ${page.count}`
                    }
                  />
                )}
              </Index.Box>
              {/* <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Pagination
              count={count}
              page={currentPage}
              onChange={handlePageChange}
              />
            </Index.Stack>
          </Index.Box> */}
            </Index.Box>
          </Index.Box>
        )}
      </>
    );
  } else {
    navigate("/admin");
  }
};

export default Query;
