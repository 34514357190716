// import loginbg from './images/png/login-bg.jpg';
import casino from './images/png/casino.jpg'
// import home from './images/png/home.jpg';
// import offers from './images/png/offers.jpg';
// import threecolorbettingicon from './images/png/3-color-betting.jpg';
// import communitybettingicon from './images/png/community-betting.jpg';
// import cardbettingicon from './images/png/card-betting.jpg';
// import numberbettingicon from './images/png/number-betting.jpg';






const Jpg = {
    // loginbg,
    casino,
    // home,
    // offers,
    // threecolorbettingicon,
    // communitybettingicon,
    // cardbettingicon,
    // numberbettingicon,
}

export default Jpg;