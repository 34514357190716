import React from 'react'
import "../comman/loader/Loader.css";
const Loader = () => {
  return (
    <div className="loader-centre">
    <div className="loader loader-1">
      <div className="loader-outter"></div>
      <div className="loader-inner"></div>
    </div>
  </div>
  )
}

export default Loader