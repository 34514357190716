import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../pageIndex";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import * as htmlToImage from "html-to-image";
import DataService from "../../../../config/DataService";
import { useDispatch } from "react-redux";
import { snapImageStore } from "../../../../redux/user/userSlice";
import "./autoWinnerModel.css";
import { useOutletContext } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  zIndex: 9999,
  transform: "translate(-50%, -50%)",
  maxWidth: 390,
  width: "90%",
  backgroundColor: "#090d29",
  boxShadow: 24,
  borderRadius: "15px",
  fontFamily: "Arial, sans-serif",
};
const style1 = {
  position: "absolute",
  transform: "translate(-50%, -50%)",
  maxWidth: 390,
  zIndex: 9999,
  width: "90%",
  backgroundColor: "#090d29",
  boxShadow: 24,
  borderRadius: "15px",
  fontFamily: "Arial, sans-serif",
  top: "-90%", // Move off-screen
  left: "-90%", // Move off-screen
  pointerEvents: "none", // Prevent interaction
};

const AutoWinnerModal = ({
  autoWinModal,
  autoModalClose,
  winnerUser,
  gameName,
  name,
  type,
  winnerSingleBetPrice,
  winnerCard,
  winnerUserAmount,
  socketTimer,
  isNumberCard,
  isHidden,
  closeModalTime=5000
}) => {
  const dispatch = useDispatch();
  const [imagePath, setImagePath] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const {gameNewName} = useOutletContext()
  const modalRef = useRef(null);
  let APP_ID = "453580690493803";

  const handleTelegramClick = () => {
    const telegramUrl = `https://telegram.me/share/url?url=${imagePath}&text=Check+this+out`;

    setImagePath("");

    window.open(telegramUrl, "_blank");
    autoModalClose();
  };
  const handleFacebookClick = () => {
    const facebookUrl = `https://www.facebook.com/dialog/share?app_id=${APP_ID}&display=popup&href=${encodeURIComponent(
      imagePath
    )}`;
    window.open(facebookUrl, "_blank");
    setImagePath("");
    autoModalClose();
  };
  const handleChatClick = () => {
    if (imageFile) {
      dispatch(snapImageStore(imageFile));
      setImageFile(null);
    }
    setImagePath("");

    autoModalClose();
  };

  const printImage = () => {
    let element = modalRef.current;
    htmlToImage
      .toPng(element)
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = `Test.png`;
        link.href = dataUrl;
        handleConvert(dataUrl);
        setImageFile(dataUrl);
      })
      .catch((e) => {});
  };
  const base64ToBlob = (base64) => {
    const parts = base64.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  };

  const handleConvert = async (base64String) => {
    try {
      const blob = base64ToBlob(base64String);
      const file = new File([blob], "image.png", { type: "image/png" });
      const formData = new FormData();
      formData.append("winnerScreenshot", file);
      const response = await DataService.post(
        "user/winner-screenshot",
        formData
      );

      setImagePath(`${process.env.REACT_APP_IMG}${response?.data?.data}`);
      // setImagePath(`http://localhost:3032/api/images/${response?.data?.data}`);
    } catch (error) {}
  };

  useEffect(() => {
    if (autoWinModal == true && imagePath && !isNumberCard) {
      setTimeout(() => {
        autoModalClose();
        setImagePath("");
      }, closeModalTime);
    }
  }, [imagePath]);

  useEffect(() => {
    if (autoWinModal == true) {
      setTimeout(() => {
        printImage();
      }, 1500);
    }
  }, [autoWinModal]);

  useEffect(() => {
    if (!isHidden) {
      const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          handleDlgClose();
        }
      };
      if (autoWinModal) {
        window.addEventListener("mousedown", handleOutsideClick);
      }
      return () => {
        window.removeEventListener("mousedown", handleOutsideClick);
      };
    }
  }, [autoWinModal, isHidden]);

  const handleDlgClose = () => {
    setImagePath("");
    autoModalClose();
  };

  return (
    <>
      <Index.Modal
        // keepMounted
        open={autoWinModal}
        // onClose={handleDlgClose}
        onClose={() => {
          if (!isHidden) {
            handleDlgClose();
          }
        }}
        disableScrollLock={isHidden ? true : false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ position: "static", zIndex: 9999 }}
        className="modal-comman-details bg-noblur"
        // className={`modal-comman-details bg-noblur ${isHidden ? 'hidden-modal' : ''}`}
      >
        <Index.Box sx={isHidden ? style1 : style}>
          <Index.Box
            className={`${isHidden ? "hidden-modal" : ""} modal-cancel-btn`}
          >
            <Index.Button className="btn btn-cancel custom-btn-cancel">
              <img
                src={PageIndex.Svg.cancelmodal}
                className="cancel-modal"
                alt="modal-cancel"
                onClick={() => {
                  handleDlgClose();
                  setImagePath("");
                }}
              />
            </Index.Button>
          </Index.Box>
          <Index.Box
            id="winner-modal"
            ref={modalRef}
            className="modal-comman-inner-style modal-winner-inner-style winner-wrap"
            // className={`modal-comman-inner-style modal-winner-inner-style winner-wrap ${isHidden ? 'hidden-modal' : ''}`}
          >
            <Index.Box className="modal-inner">
              <Index.Box className="modal-winner-icon">
                <img
                  src={PageIndex.Png.winnerImage}
                  alt="trophy-icon"
                  className="trophy-icon"
                />
              </Index.Box>
              <Index.Typography className="congratulation-text" component="p">
                Congratulations!
              </Index.Typography>

              <Index.Typography className="game-name-main" component="p">
                {gameNewName(gameName)}
              </Index.Typography>
              <Index.Box className="game-colour-side-text">
                <Index.Typography className="game-name-text1" component="p">
                  {name} :
                </Index.Typography>
                <Index.Typography className="game-name-text" component="p">
                  {type ? winnerCard : winnerUser}
                </Index.Typography>
              </Index.Box>
              <Index.Typography className="are-you-sure-text" component="p">
                {type ? winnerSingleBetPrice : Number(winnerUserAmount)?.toFixed(8)} Coins
              </Index.Typography>

              <Index.Box className="details-icon-main-test auto-modal-main-box">
                <Index.IconButton
                  className={
                    imagePath ? "icon-button-box" : "icon-button-box-disabled"
                  }
                  onClick={imagePath ? handleFacebookClick : undefined}
                  aria-disabled={!imagePath}
                  disabled={!imagePath}
                >
                  <Index.FacebookOutlinedIcon className="details-icon" />
                </Index.IconButton>
                <Index.IconButton
                  className={
                    imagePath ? "icon-button-box" : "icon-button-box-disabled"
                  }
                  onClick={imagePath ? handleTelegramClick : undefined}
                  aria-disabled={!imagePath}
                  disabled={!imagePath}
                >
                  <Index.TelegramIcon className="details-icon" />
                </Index.IconButton>
                {gameName !== "Number Betting" && (
                  <Index.IconButton
                    className={
                      imagePath
                        ? "icon-button-box icon-button-box-chat"
                        : "icon-button-box-disabled"
                    }
                    aria-disabled={!imagePath}
                    onClick={imagePath ? handleChatClick : undefined}
                    disabled={!imagePath}
                  >
                    <ChatBubbleOutlineOutlinedIcon className="details-icon" />
                  </Index.IconButton>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AutoWinnerModal;
