import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../pageIndex";
import PageIndex from "../../../pageIndex";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getTotalCoins, userAmount } from "../../../../redux/user/userReducer";
import moment from "moment";
import { io } from "socket.io-client";
import AutoWinnerModal from "../autoWinnerModal/AutoWinnerModal";
import { formatCompactNumber } from "../../../../component/comman/CommonFunction/InternationNumber";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { checkGameAvailability } from "../../../../component/comman/CommonFunction/CheckGameAvailability";

const HtmlTooltip = Index.styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    disableFocusListener
    disableHoverListener
    disableTouchListener
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 30],
            },
          },
        ],
      },
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box className="tab-details-content-top three-main-tabes">
          <Index.Box className="tab-details-inner three-inner-tabs-details">
            {children}
          </Index.Box>
        </Index.Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

var socket;

export default function TwoColorBetting() {
  const [expanded, setExpanded] = React.useState("panel1");
  let timeRef = useRef(null);

  const handleChangeAccro = (panel, expanded) => {
    setExpanded(expanded == panel ? "" : panel);
  };

  const location = useLocation();
  const navigate = useNavigate();
  // const selectedSecond = location?.state?.selectedSecond;
  const storedValue = localStorage.getItem("userGameId");
  const gameId = JSON.parse(storedValue);

  const userAmounts = useSelector(
    (state) => state?.UserReducer?.totalCoins?.totalCoin
  );
  const selectedSecond = useSelector(
    (state) => state?.UserReducer?.currentGameDetail.selectedSecond
  );

  // congrestulation auto box
  const [autoWinModal, setAutoWinModal] = useState(false);
  const [autoWinAmount, setAutoWinAmount] = useState();
  const [snapImage, setSnapImage] = useState();
  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipOpen = () => setOpenTooltip(true);
  const handleTooltipClose = () => setOpenTooltip(false);
  const autoModalClose = () => {
    setAutoWinModal(false);
  };

  const snapUploadInChat = (img) => {
    setSnapImage(img);
  };

  const gameType = "2colorBetting";
  const [responseMessage, setResponseMessage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isActiveOrange, setIsActiveOrange] = useState(false);
  const [isActiveBlue, setIsActiveBlue] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [IncDisableButtons, setIncDisableButtons] = useState(false);
  const [DncDisableButtons, setDncDisableButtons] = useState(false);
  const [battleModal, setBattleModal] = useState(false);
  const [showWinnewModal, setShowWinnewModal] = useState(false);
  const [count, setCount] = useState(gameId?.gameMinimumCoin);
  // const [count, setCount] = useState(1500);

  const [selectedColor, setSelectedColor] = useState("");
  const [GameWinnerUser, setGameWinnerUser] = useState("");
  const [winnerUser, setWinnerUser] = useState();
  const [winnerUserAmount, setWinnerUserAmount] = useState();
  const dispatch = useDispatch();
  const [singleuserList, setSingleUserList] = useState([]);
  const [getallGameData, setGetallGameData] = useState([]);
  // const [userGameRulesId, setUserGameRulesId] = useState();
  const [show, setShow] = useState(false);
  const [periodData, setPeriodData] = useState({});
  const startDate = moment(gameId?.gameTimeFrom).format("YYYY/MM/DD ");
  const endDate = moment(gameId?.gameTimeTo).format("YYYY/MM/DD");
  const startDateTime = moment(startDate + " " + gameId?.gameDurationFrom);
  const endDateTime = moment(endDate + " " + gameId?.gameDurationTo);
  const currentDateTime = moment();
  const [winnerAnnounced, setWinnerAnnounced] = useState(false);
  const [disableBetConfirmBtn, setDisableBetConfirmBtn] = useState(false);
  const [checkWinnerApiCalled, setCheckWinnerApiCalled] = useState(false);
  const [previousPeriodWinnerChecked, setPreviousPeriodWinnerChecked] =
    useState(false);
  const [socketTimer, setSocketTimer] = useState("");
  const [firstTwentyAllRecords, setFirstTwentyAllRecord] = useState([]);
  const [isHidden, setIsHidden] = useState(false);
  const [disableIfWrongCount, setDisableIfWrongCount] = useState(false);

  const {
    // open,
    setOpen,
    setOpenDeposit,
    openChatDrawer,
    setOpenChatDrawer,
    setIsUserPlayingGame,
    currentGameDetail,
    fetchGameListAgain,
    setFetchGameListAgain,
  } = useOutletContext();

  //socket state

  //socket call

  const socketCall = () => {
    const data = {
      gameId: location?.state?.ele?._id,
      second: selectedSecond,
    };
    
    socket.emit("requestPeriod", data);
    socket.on("updateTimer", (data) => {
      if (
        data.periodData?.gameId?._id == location?.state?.ele?._id &&
        data.periodData?.periodFor == selectedSecond
      ) {
        setSocketTimer(data?.seconds);
        setPeriodData(data?.periodData);
      }
      // if (data?.gameId == location?.state?.ele?._id) {

      // }
    });
    socket.on("disconnect", () => {
      // Reconnect after a delay
      setTimeout(() => socketCall(), 3000);
    });
  };
  useEffect(() => {
    socket = io(Api.common.TIMER_SOCKET);
  }, []);
  useEffect(() => {
    socketCall();
  }, []);

  useEffect(() => {
    if (startDateTime >= currentDateTime && endDateTime <= currentDateTime) {
      navigate("/user");
    }
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        (document.visibilityState === "visible" && periodData?.length > 0) ||
        (selectedSecond && gameId?._id)
      ) {
        getGamePeriod().then(() => {
          setTimeout(() => {
            getAllData();
          }, 2000);
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // const getCountdown = (endtime) => {
  //   var currentDate = moment().format("YYYY/MM/DD");

  //   const endDateTime = moment(currentDate + " " + endtime);
  //   // another date
  //   var now = moment();
  //   var duration = moment.duration(endDateTime.diff(now));
  //   //Get Days and subtract from duration
  //   var days = duration.days();
  //   duration.subtract(days, "days");

  //   //Get hours and subtract from duration
  //   var hours = duration.hours();
  //   duration.subtract(hours, "days2");

  //   //Get Minutes and subtract from duration
  //   var minutes = duration.minutes();
  //   duration.subtract(minutes, "minutes");

  //   //Get seconds
  //   var seconds = duration.seconds();

  //   let countDown = `${String(hours).padStart(2, "0")}:${String(
  //     minutes
  //   ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  //   if (hours <= 0 && minutes <= 0 && seconds <= 0) {
  //     return "00:00:00";
  //   }

  //   return countDown;
  // };

  const getSingleData = async () => {
    await DataService.get(
      `${Api.User.GET_SINGLE_GAME_PERIOAD}/${gameId?._id}?second=${selectedSecond}`
    )

      .then((res) => {
        setSingleUserList(res?.data?.data);
      })

      .catch((error) => {
        toast.error(error?.data?.message, {
          toastId: "customId",
        });
      });
  };

  const getAllData = async (t) => {
    await DataService.get(
      `${Api.User.GET_ALL_GAME_PERIOAD}/${gameId?._id}?second=${selectedSecond}`
    )

      .then((res) => {
        let data = res?.data?.data;
        data?.reverse();
        // if (t == 1) {
        //   data = data.slice(1);
        // }

        setGetallGameData(data);
      })

      .catch((error) => {
        toast.error(error?.data?.message, {
          toastId: "customId",
        });
      });
  };

  // let firstTwentyAllRecords =
  //   window.innerWidth < 768
  //     ? getallGameData?.slice(0, 15)
  //     : getallGameData?.slice(0, 20);
  let firstTwentyMyRecords = singleuserList.slice(0, 10);
  // let firstTwentyMyRecords=[];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setFirstTwentyAllRecord(getallGameData?.slice(0, 15));
      } else {
        setFirstTwentyAllRecord(getallGameData?.slice(0, 21));
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getallGameData]);

  const isMoreGameRecords = () => {
    const isMobile = window.innerWidth < 768;
    return isMobile
      ? getallGameData?.length >= 3
      : getallGameData?.length >= 20;
  };

  useEffect(() => {
    if (socketTimer <= 10) {
      setDisableButtons(true);
      setBattleModal(false);
      setIsActiveOrange(false);
      setIsActiveBlue(false);
      setIsActive(false);
      if (isActiveOrange) {
        document
          .querySelector(".button-landscape")
          ?.classList.remove("bg-orange");
        document
          .querySelector(".onclick-color-text")
          ?.classList.remove("block-onlick-text");
        document
          .querySelector(".button-landscape-text")
          ?.classList.remove("choose-color-text-remove");
        document
          .querySelector(".button-control-inner")
          ?.classList.remove("cursor");
      }
      if (isActiveBlue) {
        document
          .querySelector(".button-landscape")
          ?.classList.remove("bg-blue");
        document
          .querySelector(".onclick-color-text")
          ?.classList.remove("block-onlick-text");
        document
          .querySelector(".button-landscape-text")
          ?.classList.remove("choose-color-text-remove");
        document
          .querySelector(".button-control-inner")
          ?.classList.remove("cursor");
      }
      if (isActive) {
        document
          .querySelector(".button-landscape")
          ?.classList.remove("bg-green");
        document
          .querySelector(".onclick-color-text")
          ?.classList.remove("block-onlick-text");
        document
          .querySelector(".button-landscape-text")
          ?.classList.remove("choose-color-text-remove");
        document
          .querySelector(".button-control-inner")
          ?.classList.remove("cursor");
      }
    } else {
      setDisableButtons(false);
    }

    if (socketTimer <= 10 && show == true) {
      setIsActiveOrange(false);
      setIsActiveBlue(false);
      setIsActive(false);
      if (isActiveOrange) {
        document
          .querySelector(".button-landscape")
          ?.classList.remove("bg-orange");
        document
          .querySelector(".onclick-color-text")
          ?.classList.remove("block-onlick-text");
        document
          .querySelector(".button-landscape-text")
          ?.classList.remove("choose-color-text-remove");
        document
          .querySelector(".button-control-inner")
          ?.classList.remove("cursor");
      }
      if (isActiveBlue) {
        document
          .querySelector(".button-landscape")
          ?.classList.remove("bg-blue");
        document
          .querySelector(".onclick-color-text")
          ?.classList.remove("block-onlick-text");
        document
          .querySelector(".button-landscape-text")
          ?.classList.remove("choose-color-text-remove");
        document
          .querySelector(".button-control-inner")
          ?.classList.remove("cursor");
      }
      if (isActive) {
        document
          .querySelector(".button-landscape")
          ?.classList.remove("bg-green");
        document
          .querySelector(".onclick-color-text")
          ?.classList.remove("block-onlick-text");
        document
          .querySelector(".button-landscape-text")
          ?.classList.remove("choose-color-text-remove");
        document
          .querySelector(".button-control-inner")
          ?.classList.remove("cursor");
      }
    }
  }, [socketTimer]);

  const winnerDeclaration = async () => {
    await DataService?.get(
      `${Api.User.GAME_WINNER_LIST}/${gameType}/${gameId?._id}/${periodData?.period}?second=${selectedSecond}`
    )
      .then((res) => {
        if (res?.data?.data?.length) {
          setWinnerUser(res?.data?.data?.[0]?.colourName);
          setWinnerUserAmount(res?.data?.data?.[0]?.winnerAmount);

          setGameWinnerUser(res?.data?.data?.[0]?.colourName);

          setAutoWinAmount(res?.data?.data?.[0].totalBetAmount);
        } else {
          setResponseMessage(res?.data?.message);
        }
      })
      .catch((error) => {});
  };

  const checkColorWinner = async (previousPeriod) => {
    try {
      // let urlencoded = new URLSearchParams();
      // urlencoded.append("gameType", "2colorBetting");
      // urlencoded.append("gameId", gameId?._id);
      // urlencoded.append("period", previousPeriod || periodData?.period);
      // urlencoded.append("selectedTime", selectedSecond);
      // const colorWinner = await DataService.post(
      //   Api.User.CHECK_COLOR_WINNER,
      //   urlencoded
      // );
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (
      socketTimer < 10 &&
      socketTimer > 5 &&
      periodData?.period &&
      !checkWinnerApiCalled
    ) {
      checkColorWinner();
      setCheckWinnerApiCalled(true);
    }
    if (socketTimer == 7) {
      winnerDeclaration();
      setIsHidden(true);
    }
    if (socketTimer == 5) {
      // winnerDeclaration();
      setFetchGameListAgain(!fetchGameListAgain);
      // autoModalClose();
      // setIsHidden(true)
    }
    if (socketTimer == 5) {
      singleuserList.find((item) => {
        if (item.period == periodData?.period) {
          if (
            GameWinnerUser?.toLowerCase() == item?.colourName?.toLowerCase()
          ) {
            setAutoWinModal(true);
          }
        }
      });
    }
    if (socketTimer == 0) {
      setIsHidden(false);
      handleWinnerColor();
      dispatch(getTotalCoins());
      // singleuserList.find((item) => {
      //   if (item.period == periodData?.period) {
      //     if (
      //       GameWinnerUser?.toLowerCase() == item?.colourName?.toLowerCase()
      //     ) {
      //       setAutoWinModal(true);
      //     }
      //   }
      // });
      if (responseMessage) {
        setShowWinnewModal(true);
        setTimeout(() => {
          handleCloseWinnerModal();
        }, 3000);
      }
      setTimeout(() => {
        setShow(false);
        setGameWinnerUser("");
        setShowWinnewModal(false);
        setResponseMessage("");
        // socketCall();
      }, 1000);
      setCheckWinnerApiCalled(false);
      
      if(isGameAvailable()){
        getGamePeriod().then(() => {
          getAllData();
        });
      }
      getSingleData();
    }
  }, [socketTimer]);

  // function checkGameAvailability() {
  //   const {
  //     gameTimeFrom,
  //     gameTimeTo,
  //     gameDurationFrom,
  //     gameDurationTo,
  //     gameSecond,
  //   } = currentGameDetail;
  
  //   const currentDateTime = moment();
  
  //   // First, check if current time is within the overall game period
  //   if (currentDateTime.isBetween(moment(gameTimeFrom), moment(gameTimeTo))) {
  //     // Now, check if the current time falls within the daily duration
  //     const currentDayStart = currentDateTime.clone().startOf('day');
  //     const dailyStartDateTime = moment(currentDayStart.format("YYYY-MM-DD") + " " + gameDurationFrom, "YYYY-MM-DD hh:mm A");
  //     const dailyEndDateTime = moment(currentDayStart.format("YYYY-MM-DD") + " " + gameDurationTo, "YYYY-MM-DD hh:mm A");
  
  //     if (currentDateTime.isBetween(dailyStartDateTime, dailyEndDateTime) && gameSecond?.includes(selectedSecond)) {
  //       // Game is available
  //       return true;
  //     }
  //   }
  
  //   // If any condition fails, show error and navigate
  //   setTimeout(() => {
  //     toast.error("Game will start soon");
  //     setIsUserPlayingGame(false);
  //     navigate("/user");
  //   }, 2000);
  //   return false;
  // }
  
  function isGameAvailable() {
    if(!checkGameAvailability(currentGameDetail, selectedSecond, true)){
      setTimeout(() => {
        toast.error("Game will start soon");
        setIsUserPlayingGame(false);
        navigate("/user");
      }, 2000);
      return false;
    }
    return true;
  }
  

  useEffect(() => {
    isGameAvailable();
    return () => clearInterval(timeRef.current); // to clean up on unmount
  }, []);

  useEffect(() => {
    if (selectedSecond) {
      document
        .querySelector(".button-landscape-inner")
        ?.classList.add("choose-span-remove");
      document
        .getElementById("line-single-id")
        ?.classList.add("line-single-none");
      document
        .getElementById("choose-multiple-id")
        ?.classList.add("choose-d-block");
      document
        .querySelector(".multiline-title")
        ?.classList.add("multiline-title-added");
    }
  }, [selectedSecond]);

  setTimeout(function () {
    document.querySelector(".timer-title")?.classList.add("timer-remover");
  }, 15000);

  setTimeout(function () {
    document
      .getElementById(".gaming-circle-count")
      ?.classList.add("gaming-cricle-first-sec");
  }, 90000);
  setTimeout(function () {
    document
      .getElementById(".gaming-circle-count")
      ?.classList.remove("gaming-cricle-first-sec");
  }, 10000);

  setTimeout(function () {
    setTimeout(function () {
      document.querySelector(".timer-title")?.classList.remove("timer-remover");
    }, 15000);
  }, 15000);

  const getGamePeriod = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("gameId", gameId?._id);
    urlencoded.append("specificSecond", selectedSecond);
    await DataService?.post(Api.User.CREATE_GAME_PERIOD, urlencoded)
      .then((res) => {
        socketCall();
      })
      .catch((error) => {});
  };
  // useEffect(() => {
  //   if (selectedSecond && gameId?._id) {
  //     getGamePeriod().then(() => {
  //       setTimeout(() => {
  //         getAllData();
  //       }, 2000);
  //     });
  //   }
  // }, []);

  useEffect(() => {
    // getSingleData();
    // getAllData(1);
  }, []);

  const handleClickGreen = () => {
    if (userAmounts <= 0 || count > userAmounts) {
      setOpenDeposit(true);
      return;
    }
    setIsActiveOrange(false);
    setIsActiveBlue(false);
    setSelectedColor("green");
    if (isActive) {
      document.querySelector(".button-landscape")?.classList.remove("bg-green");
      document
        .querySelector(".onclick-color-text")
        ?.classList.remove("block-onlick-text");
      document
        .querySelector(".button-landscape-text")
        ?.classList.remove("choose-color-text-remove");
      document
        .querySelector(".button-control-inner")
        ?.classList.remove("cursor");
    } else {
      document.querySelector(".button-landscape")?.classList.add("bg-green");
      document
        .querySelector(".button-landscape-text")
        ?.classList.add("choose-color-text-remove");
      document
        .querySelector(".onclick-color-text")
        ?.classList.add("block-onlick-text");
      document.querySelector(".button-control-inner")?.classList.add("cursor");
    }
    setIsActive((current) => !current);
    document.querySelector(".button-landscape")?.classList.remove("bg-blue");
    document.querySelector(".button-landscape")?.classList.remove("bg-orange");
  };

  const handleClickBlue = () => {
    if (userAmounts <= 0 || count > userAmounts) {
      setOpenDeposit(true);
      return;
    }
    setIsActive(false);
    setIsActiveOrange(false);
    setSelectedColor("red");
    if (isActiveBlue) {
      document.querySelector(".button-landscape")?.classList.remove("bg-blue");
      document
        .querySelector(".onclick-color-text")
        ?.classList.remove("block-onlick-text");
      document
        .querySelector(".button-landscape-text")
        ?.classList.remove("choose-color-text-remove");
      document
        .querySelector(".button-control-inner")
        ?.classList.remove("cursor");
    } else {
      document.querySelector(".button-landscape")?.classList.add("bg-blue");
      document
        .querySelector(".button-landscape-text")
        ?.classList.add("choose-color-text-remove");
      document
        .querySelector(".onclick-color-text")
        ?.classList.add("block-onlick-text");
      document.querySelector(".button-control-inner")?.classList.add("cursor");
    }
    setIsActiveBlue((current) => !current);
    document.querySelector(".button-landscape")?.classList.remove("bg-green");
    document.querySelector(".button-landscape")?.classList.remove("bg-orange");
  };

  const handleRemoveBg = (event) => {
    let bgExist = document
      .querySelector(".button-landscape")
      ?.classList.contains("bg-green");
    if (bgExist) {
      document.querySelector(".button-landscape")?.classList.remove("bg-green");
      document
        .querySelector(".down-active-btn")
        ?.classList.add("value-show-green");
      document
        .querySelector(".onclick-color-text")
        ?.classList.remove("block-onlick-text");
      document
        .querySelector(".button-landscape-text")
        ?.classList.remove("choose-color-text-remove");
      document
        .querySelector(".onclick-prices-hidden")
        ?.classList.remove("remove-price-onclick-green");

      setTimeout(function () {
        document
          .getElementById("choose-multiple-id")
          ?.classList.add("choose-d-block");
        setTimeout(function () {}, 150);
      }, 100);
    }

    let bgExistOrange = document
      .querySelector(".button-landscape")
      ?.classList.contains("bg-orange");
    if (bgExistOrange) {
      document
        .querySelector(".button-landscape")
        ?.classList.remove("bg-orange");
      document
        .querySelector(".down-active-btn")
        ?.classList.add("value-show-orange");
      document
        .querySelector(".onclick-color-text")
        ?.classList.remove("block-onlick-text");
      document
        .querySelector(".button-landscape-text")
        ?.classList.remove("choose-color-text-remove");
      document
        .querySelector(".onclick-prices-hidden")
        ?.classList.remove("remove-price-onclick-orange");
      setTimeout(function () {
        document
          .getElementById("choose-multiple-id")
          ?.classList.add("choose-d-block");
        setTimeout(function () {}, 150);
      }, 100);
    }

    let bgExistBlue = document
      .querySelector(".button-landscape")
      ?.classList.contains("bg-blue");
    if (bgExistBlue) {
      document.querySelector(".button-landscape")?.classList.remove("bg-blue");
      document
        .querySelector(".down-active-btn")
        ?.classList.add("value-show-blue");
      document
        .querySelector(".onclick-color-text")
        ?.classList.remove("block-onlick-text");
      document
        .querySelector(".button-landscape-text")
        ?.classList.remove("choose-color-text-remove");
      document
        .querySelector(".onclick-prices-hidden")
        ?.classList.remove("remove-price-onclick-blue");
      setTimeout(function () {
        document
          .getElementById("choose-multiple-id")
          ?.classList.add("choose-d-block");
        setTimeout(function () {}, 150);
      }, 100);
    }
  };
  // functions

  const startBattle = async () => {
    setDisableBetConfirmBtn(true);
    setIsUserPlayingGame(true);
    const data = {
      colourName: selectedColor,
      betAmount: count,
      gameId: gameId?._id,
      gameType: gameType,
      gameMode: gameId?.gameMode,
      period: periodData?.period,
      selectedTime: +selectedSecond,
    };
    if (userAmounts < count) {
      toast.error("Balance is insufficient");
    } else {
      await DataService.post(Api.User.THREE_COLOR_BETTING, data)
        .then((res) => {
          if (res.data.status === 200 || res.data.status === 201) {
            setShow(true);
            getSingleData();
            // setAutoBetColor(res?.data?.data?.colourName);
            dispatch(getTotalCoins());
            toast.success(res?.data?.message, {
              toastId: "customId",
              // autoClose: 5000,
            });
          }

          setIsActiveOrange(false);
          setIsActiveBlue(false);
          setIsActive(false);
          if (isActiveOrange) {
            document
              .querySelector(".button-landscape")
              ?.classList.remove("bg-orange");
            document
              .querySelector(".onclick-color-text")
              ?.classList.remove("block-onlick-text");
            document
              .querySelector(".button-landscape-text")
              ?.classList.remove("choose-color-text-remove");
            document
              .querySelector(".button-control-inner")
              ?.classList.remove("cursor");
          }
          if (isActiveBlue) {
            document
              .querySelector(".button-landscape")
              ?.classList.remove("bg-blue");
            document
              .querySelector(".onclick-color-text")
              ?.classList.remove("block-onlick-text");
            document
              .querySelector(".button-landscape-text")
              ?.classList.remove("choose-color-text-remove");
            document
              .querySelector(".button-control-inner")
              ?.classList.remove("cursor");
          }
          if (isActive) {
            document
              .querySelector(".button-landscape")
              ?.classList.remove("bg-green");
            document
              .querySelector(".onclick-color-text")
              ?.classList.remove("block-onlick-text");
            document
              .querySelector(".button-landscape-text")
              ?.classList.remove("choose-color-text-remove");
            document
              .querySelector(".button-control-inner")
              ?.classList.remove("cursor");
          }

          // dispatch(userAmount())
          //   .then((res) => {
          //     toast.success(res?.data?.message, {
          //       toastId: "customId",
          //     });
          //   })
          //   .catch((error) => {});
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            toastId: "customId",
          });
        });
    }
    setBattleModal(false);
    setDisableBetConfirmBtn(false);
  };
  const handleCloseWinnerModal = () => {
    setShowWinnewModal(false);
    setIsActiveOrange(false);
    setIsActiveBlue(false);
    setIsActive(false);
    setShow(false);
  };

  const increaseCount = (incrementValue) => {
    if (gameId.gameMaximumCoin <= count + incrementValue) {
      setCount(gameId.gameMaximumCoin);
    } else if (userAmounts >= count + incrementValue) {
      setCount(count + incrementValue);
    } else {
      toast.error("Balance is insufficient", {
        toastId: "customId",
      });
    }
  };

  const decreaseCount = (decrementValue) => {
    if (gameId.gameMinimumCoin <= count - decrementValue) {
      if (count >= decrementValue) {
        setCount(count - decrementValue);
      }
    }
  };

  // const userGameRules = async () => {
  //   await DataService.get(Api.User.USER_GAME_RULES + "/" + gameId?._id)
  //     .then((res) => {
  //       setUserGameRulesId(res?.data?.data?.gameRules);
  //     })
  //     .catch((error) => {});
  // };

  useEffect(() => {
    if (gameId.gameMaximumCoin == count || Math.floor(Number(userAmounts)) == count || Math.floor(Number(userAmounts)) == 0) {
      setIncDisableButtons(true);
    } else {
      setIncDisableButtons(false);
    }
    if (gameId.gameMinimumCoin == count) {
      setDncDisableButtons(true);
    } else {
      setDncDisableButtons(false);
    }
    if (
      count + 1 < gameId?.gameMinimumCoin ||
      count - 1 > gameId?.gameMaximumCoin
    ) {
      setDisableIfWrongCount(true);
      setIsActiveOrange(false);
      setIsActiveBlue(false);
      setIsActive(false);
      setBattleModal(false);

      if (isActive) {
        document
          .querySelector(".button-landscape")
          ?.classList.remove("bg-green");
        document
          .querySelector(".onclick-color-text")
          ?.classList.remove("block-onlick-text");
        document
          .querySelector(".button-landscape-text")
          ?.classList.remove("choose-color-text-remove");
        document
          .querySelector(".button-control-inner")
          ?.classList.remove("cursor");
      }
      if (isActiveOrange) {
        document
          .querySelector(".button-landscape")
          ?.classList.remove("bg-orange");
        document
          .querySelector(".onclick-color-text")
          ?.classList.remove("block-onlick-text");
        document
          .querySelector(".button-landscape-text")
          ?.classList.remove("choose-color-text-remove");
        document
          .querySelector(".button-control-inner")
          ?.classList.remove("cursor");
      }
      if (isActiveBlue) {
        document
          .querySelector(".button-landscape")
          ?.classList.remove("bg-blue");
        document
          .querySelector(".onclick-color-text")
          ?.classList.remove("block-onlick-text");
        document
          .querySelector(".button-landscape-text")
          ?.classList.remove("choose-color-text-remove");
        document
          .querySelector(".button-control-inner")
          ?.classList.remove("cursor");
      }
    } else {
      setDisableIfWrongCount(false);
    }
  }, [count]);

  // useEffect(() => {
  //   userGameRules();
  // }, [userGameRulesId]);

  const handleWinnerColor = () => {
    if (GameWinnerUser) {
      setWinnerAnnounced(true);
      if (GameWinnerUser.toLowerCase() == "red") {
        document.querySelector(".button-landscape")?.classList.add("bg-blue");
        document
          .querySelector(".button-landscape-text")
          ?.classList.add("choose-color-text-remove");
        document
          .querySelector(".onclick-color-text")
          ?.classList.add("block-onlick-text");
        document
          .querySelector(".gaming-circle-count")
          ?.classList.add("wheels-stopped-red");
        setTimeout(() => {
          setWinnerAnnounced(false);
          document
            .querySelector(".button-landscape")
            ?.classList.remove("bg-blue");
          document
            .querySelector(".onclick-color-text")
            ?.classList.remove("block-onlick-text");
          document
            .querySelector(".button-landscape-text")
            ?.classList.remove("choose-color-text-remove");
          document
            .querySelector(".gaming-circle-count")
            ?.classList.remove("wheels-stopped-red");
        }, 1000);
      } else if (GameWinnerUser.toLowerCase() == "green") {
        document.querySelector(".button-landscape")?.classList.add("bg-green");
        document
          .querySelector(".button-landscape-text")
          ?.classList.add("choose-color-text-remove");
        document
          .querySelector(".onclick-color-text")
          ?.classList.add("block-onlick-text");
        document
          .querySelector(".gaming-circle-count")
          ?.classList.add("wheels-stopped-green");
        setTimeout(() => {
          setWinnerAnnounced(false);
          document
            .querySelector(".button-landscape")
            ?.classList.remove("bg-green");
          document
            .querySelector(".onclick-color-text")
            ?.classList.remove("block-onlick-text");
          document
            .querySelector(".button-landscape-text")
            ?.classList.remove("choose-color-text-remove");
          document
            .querySelector(".gaming-circle-count")
            ?.classList.remove("wheels-stopped-green");
        }, 1000);
      }
    }
  };

  const closeBetConfirmModal = () => {
    setIsActiveOrange(false);
    setIsActiveBlue(false);
    setIsActive(false);
    if (isActiveOrange) {
      document
        .querySelector(".button-landscape")
        ?.classList.remove("bg-orange");
      document
        .querySelector(".onclick-color-text")
        ?.classList.remove("block-onlick-text");
      document
        .querySelector(".button-landscape-text")
        ?.classList.remove("choose-color-text-remove");
      document
        .querySelector(".button-control-inner")
        ?.classList.remove("cursor");
    }
    if (isActiveBlue) {
      document.querySelector(".button-landscape")?.classList.remove("bg-blue");
      document
        .querySelector(".onclick-color-text")
        ?.classList.remove("block-onlick-text");
      document
        .querySelector(".button-landscape-text")
        ?.classList.remove("choose-color-text-remove");
      document
        .querySelector(".button-control-inner")
        ?.classList.remove("cursor");
    }
    if (isActive) {
      document.querySelector(".button-landscape")?.classList.remove("bg-green");
      document
        .querySelector(".onclick-color-text")
        ?.classList.remove("block-onlick-text");
      document
        .querySelector(".button-landscape-text")
        ?.classList.remove("choose-color-text-remove");
      document
        .querySelector(".button-control-inner")
        ?.classList.remove("cursor");
    }
    setBattleModal(false);
  };

  useEffect(() => {
    if (
      Array.isArray(getallGameData) &&
      getallGameData.length >= 2 &&
      periodData?.period &&
      !previousPeriodWinnerChecked
    ) {
      let tempAllGamePeriod = getallGameData;
      let previousPeriod = tempAllGamePeriod[1];
  
      if (!previousPeriod?.winColour) {
        checkColorWinner(previousPeriod?.period).then(() => {
          getSingleData();
          getAllData();
        });
      }
      DataService?.get(
        `${Api.User.GAME_WINNER_LIST}/${gameType}/${gameId?._id}/${previousPeriod?.period}?second=${selectedSecond}&prevPeriod=true`
      )
      setPreviousPeriodWinnerChecked(true);
    }
  }, [getallGameData, periodData]);
  return (
    <>
      <Index.Modal
        open={showWinnewModal}
        onClose={() => {
          handleCloseWinnerModal();
          setShow(false);
          setGameWinnerUser("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-comman-details modal-blur-common-remove "
      >
        <Index.Box sx={style} className="modal-comman-inner-style">
          <Index.Box className="modal-cancel-btn">
            <Index.Button className="btn btn-cancel">
              <img
                src={PageIndex.Svg.cancelmodal}
                className="cancel-modal"
                alt="modal-cancel"
                onClick={() => {
                  {
                    handleCloseWinnerModal();
                    setShow(false);
                    setGameWinnerUser("");
                  }
                }}
              />
            </Index.Button>
          </Index.Box>
          <Index.Box>
            <Index.Box className="delete-game-data-main">
              <Index.Box className="number-list-details">
                <Index.Typography
                  className="number-bet-coin are-you-sure-text"
                  component="p"
                  variant="p"
                >
                  {responseMessage}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="deleteModel-btna1">
                <Index.Box className="btn-col">
                  <PagesIndex.BlueOutlineButton
                    variant="contained"
                    color="error"
                    btnLabel="Ok"
                    className="outline-blue-btn-content"
                    onClick={() => {
                      handleCloseWinnerModal();
                      setShow(false);
                    }}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Box className="dashbaord-user-main-page">
        <Index.Box className="dasboard-flex-user-main">
          <Index.Box className="left-dashboard-main-user">
            {/*  */}
            <Index.Box className="betting-three-main">
              <Index.Box className="spin-ball-strike-content"></Index.Box>
              <Index.Box className="game-spin-main-content">
                <Index.Box className="game-spin-details">
                  <Index.Box
                    className={
                      socketTimer == 0
                        ? "spin-arrow-content"
                        : "spin-arrow-content animation-spin-details"
                    }
                  ></Index.Box>
                  <Index.Box className="game-content-rounded">
                    <Index.Box className="gaming-brun">
                      <Index.Box
                        className={
                          socketTimer == 0 &&
                          gameId?.gameMode?.toLowerCase() == "auto"
                            ? // ? "gaming-circle-count"
                              // : "gaming-circle-count wheels-rotated"
                              "gaming-circle-count two-wheel-bg"
                            : "gaming-circle-count two-wheel-bg wheels-rotated"
                        }
                        // className="gaming-circle-count wheels-rotated"
                        id={"gaming-circle-count"}
                        // className={second == 30 ?"gaming-circle-count gaming-cricle-first-sec": second == 60 ?"gaming-circle-count gaming-cricle-second-sec":second == 120 ?"gaming-circle-count gaming-cricle-third-sec":"gaming-circle-count"}
                      ></Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="game-controller-details">
                <Index.Box className="game-amount-details">
                  <Index.Box className="game-amount-title pointer-event">
                    Bet Amount
                  </Index.Box>
                  <Index.ClickAwayListener onClickAway={handleTooltipClose}>
                    <Index.Box
                      className="game-amount-inner"
                      onClick={handleTooltipOpen}
                    >
                      <Index.Box className="game-amount-landscape">
                        <Index.Box className="amount-landscape-inner">
                          <Index.Box className="amount-landscape-center amount-landscape-center-custom">
                            {/* <Index.Typography
                            className="game-amount-landscape-label"
                            variant="label"
                            component="label"
                          ></Index.Typography> */}

                            <HtmlTooltip
                              className=""
                              title={count}
                              open={openTooltip}
                              onClose={handleTooltipClose}
                              PopperProps={{
                                disablePortal: true,
                              }}
                            >
                              <Index.TextField
                                className="input-hidden-cus"
                                id="betPriceInput"
                                sx={{ color: "transparent" }}
                                type="text"
                                onWheel={(event) => event.target.blur()}
                                name="number"
                                disabled={disableButtons}
                                value={count}
                                onChange={(e) => {
                                  let batValue = e.target.value.replace(/\D/g, "");
                                  if (batValue.startsWith("0")) {
                                    batValue = batValue.substring(1);
                                  }
                                  if (
                                    !isNaN(batValue) &&
                                    batValue.toString().length <= 15
                                  ) {
                                    setCount(+batValue);
                                  }
                                }}
                                autoComplete="off"
                              />
                            </HtmlTooltip>
                          </Index.Box>
                          <Index.Box className="custom-label">
                            <label
                              className="amount-form-control-custum"
                              htmlFor="betPriceInput"
                            >
                              {formatCompactNumber(count)}
                            </label>
                          </Index.Box>

                          <Index.Box className="amount-landscape-game-btns">
                            <Index.Box
                              className="game-amount-landscape-btn game-topleft"
                              disabled={disableButtons || IncDisableButtons}
                              onClick={() => setCount(Math.floor(Number(userAmounts)))}
                            >
                              max
                            </Index.Box>
                            <Index.Box
                              className="game-amount-landscape-btn game-topright"
                              disabled={disableButtons || IncDisableButtons}
                              onClick={() => increaseCount(10)}
                            >
                              +
                            </Index.Box>
                            <Index.Box
                              className="game-amount-landscape-btn game-bottomleft"
                              disabled={disableButtons || DncDisableButtons}
                              onClick={() => decreaseCount(10)}
                            >
                              -
                            </Index.Box>
                            <Index.Box
                              className="game-amount-landscape-btn game-bottomright"
                              disabled={disableButtons || DncDisableButtons}
                              onClick={() => setCount(gameId.gameMinimumCoin)}
                            >
                              min
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.ClickAwayListener>
                </Index.Box>
                {/* Select color buttons */}
                <Index.Box className="button-game-user-list two-color-bet-game-width ">
                  <Index.Box className="button-inner-list">
                    <Index.Button
                      className="button-item game-green-btn"
                      disabled={disableButtons || disableIfWrongCount}
                    >
                      <Index.Box className="button-led-game"></Index.Box>
                      <Index.Box className="button-sum-game"></Index.Box>
                      <Index.Box
                        onClick={(e) => {
                          handleClickBlue();
                        }}
                        className={`button-btn-game ${
                          isActiveBlue ? "down-active-btn" : ""
                        }`}
                      >
                        <span className="price-value-details">Red</span>
                        <span className="cancel-span">Cancel</span>
                      </Index.Box>
                      <Index.Box className="onclick-prices-hidden remove-price-onclick-blue">
                        {/* <span className="onclick-bet-price">$80.00</span> */}
                      </Index.Box>
                    </Index.Button>
                    <Index.Button
                      className="button-item game-purple-btn"
                      disabled={disableButtons || disableIfWrongCount}
                    >
                      <Index.Box className="button-led-game"></Index.Box>
                      <Index.Box className="button-sum-game"></Index.Box>
                      <Index.Box
                        onClick={(e) => {
                          handleClickGreen();
                        }}
                        className={`button-btn-game ${
                          isActive ? "down-active-btn" : ""
                        }`}
                      >
                        <span className="price-value-details">Green</span>
                        <span className="cancel-span">Cancel</span>
                      </Index.Box>
                      <Index.Box className="onclick-prices-hidden remove-price-onclick-green">
                        {/* <span className="onclick-bet-price">$80.00</span> */}
                      </Index.Box>
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                {/* Place bet and timer circle */}
                <Index.Box className="button-control-game two-btn-control-game">
                  <Index.Box className="button-control-inner">
                    <Index.Box
                      className="button-landscape"
                      onClick={(e) => {
                        // if (!walletAddress) {
                        //   setOpen(true); // connect to wallet
                        //   return;
                        // }
                        if (selectedSecond == "") {
                          toast.error("Please select game time");
                        }
                        if (userAmounts <= count) {
                          toast.error("Balance is insufficient");
                        } else {
                          handleRemoveBg(e);
                          setBattleModal(true);
                        }
                      }}
                    >
                      <Index.Box className="button-landscape-inner">
                        <span
                          className="button-landscape-text"
                          id="rotate-choose-none"
                        >
                          Choose color
                        </span>
                        <span className="onclick-color-text">
                          {winnerAnnounced
                            ? `${GameWinnerUser} color won.`
                            : "Place bet"}
                        </span>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="choose-process">
                      <Index.Box
                        className="liner-border-choose"
                        id="line-single-id"
                      >
                        <Index.Box className="button-await-details"></Index.Box>
                        <Index.Box className="border-timer-rounded ">
                          <svg viewBox="0 0 64 64">
                            <circle
                              r="25%"
                              cx="50%"
                              cy="50%"
                              stroke-width="0.6"
                              fill-opacity="0"
                              stroke="#65cc01"
                            ></circle>
                          </svg>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        className="choose-mutiple-rounded choose-d-done"
                        id="choose-multiple-id"
                      >
                        <Index.Box className="multi-line-border">
                          <img
                            src={PageIndex.Svg.loaderimg}
                            className="loader-img-multi"
                          ></img>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="multiple-line-timer-show">
                        <Index.Typography
                          className="multiline-title"
                          variant="p"
                          component="p"
                        >
                          <Index.Box className="countdown-text resend-main">
                            {socketTimer > 0 ? socketTimer : <>0</>}
                          </Index.Box>
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="timer-right-control">
                    <Index.Typography
                      className="timer-title"
                      variant="p"
                      component="p"
                    ></Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* my records */}
            <Index.Box className="my-record-margin">
              <Index.Box className="game-record-title">
                <Index.Typography
                  className="record-title"
                  component="p"
                  variant="p"
                >
                  {" "}
                  My Records :
                </Index.Typography>
              </Index.Box>

              {firstTwentyMyRecords.length ? (
                <Index.Box className="game-records-details">
                  <Index.Box className="accordian-records-details">
                    {firstTwentyMyRecords?.map((ele, index) => {
                      const str = ele?.colourName;
                      const betSideUppercase =
                        str.charAt(0).toUpperCase() + str.slice(1);
                      return (
                        <Index.Accordion
                          key={ele?._id}
                          className="accordian-main-record"
                          expanded={expanded === index + 1}
                          onChange={() =>
                            handleChangeAccro(index + 1, expanded)
                          }
                        >
                          <Index.AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            className="accordian-summary-record"
                            expandIcon={
                              <Index.ExpandMoreIcon className="accordian-icon-record" />
                            }
                          >
                            {/* <Index.Typography className="accordian-main-record-title">{ele?.period} <span className='yellow-span'>Wait!</span></Index.Typography> */}
                            <Index.Typography className="accordian-main-record-title">
                              {ele?.period}{" "}
                              <span
                                className={
                                  ele?.status == "pending"
                                    ? "yellow-span"
                                    : ele?.isWin == true
                                    ? "green-text-order"
                                    : "red-text-order"
                                }
                              >
                                {ele?.status == "pending"
                                  ? "Pending"
                                  : ele?.isWin == true
                                  ? "Won"
                                  : "Loss"}
                              </span>
                            </Index.Typography>
                          </Index.AccordionSummary>
                          <Index.AccordionDetails className="accordian-inner-details-record">
                            <Index.Typography className="accordian-inner-description">
                              <Index.Typography
                                component="h4"
                                variant="h4"
                                className="accoro-details-title"
                              >
                                Period Details
                              </Index.Typography>
                              <Index.Box className="my-record-details">
                                <Index.Box className="my-record-content-details">
                                  <Index.Typography
                                    className="period-my-record"
                                    component="p"
                                    variant="p"
                                  >
                                    Period id :<span>{ele?.period}</span>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="my-record-content-details">
                                  <Index.Typography
                                    className="period-my-record"
                                    component="p"
                                    variant="p"
                                  >
                                    Bet coins :<span>{ele?.price}</span>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="my-record-content-details">
                                  <Index.Typography
                                    className="period-my-record"
                                    component="p"
                                    variant="p"
                                  >
                                    Selected color :
                                    <span>{betSideUppercase}</span>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="my-record-content-details">
                                  <Index.Typography
                                    className="period-my-record"
                                    component="p"
                                    variant="p"
                                  >
                                    Bet status :<span>Successful</span>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="my-record-content-details">
                                  <Index.Typography
                                    className="period-my-record"
                                    component="p"
                                    variant="p"
                                  >
                                    Created at :
                                    <span>
                                      {moment(ele?.betCreatedAt).format(
                                        "YYYY-MM-D, hh:mm "
                                      )}
                                    </span>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="my-record-content-details">
                                  <Index.Typography
                                    className="period-my-record"
                                    component="p"
                                    variant="p"
                                  >
                                    {ele?.status == "pending" ? (
                                      <>
                                        Winning coins (approx.) :
                                        <span>
                                          {ele?.price * ele?.winningAmount +
                                            ele?.price}
                                        </span>
                                      </>
                                    ) : ele?.isWin == true ? (
                                      <>
                                        Winning coins (approx.) :
                                        <span>
                                          {ele?.price * ele?.winningAmount +
                                            ele?.price}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="my-record-content-details">
                                  {/* <Index.Typography className="period-my-record" component='p' variant='p'>Result:<span>Pending</span></Index.Typography> */}
                                </Index.Box>
                              </Index.Box>
                            </Index.Typography>
                          </Index.AccordionDetails>
                        </Index.Accordion>
                      );
                    })}
                  </Index.Box>
                  {firstTwentyMyRecords.length >= 10 ? (
                    <Index.Box className="more-right-record">
                      <Index.Box
                        className="more-link-content"
                        onClick={() => {
                          navigate("/user/two-color-trading/my-records", {
                            state: {
                              singleuserList: singleuserList,
                              selectedSecond: selectedSecond,
                              ele: location?.state?.ele,
                            },
                          });
                        }}
                      >
                        More &#8811;
                      </Index.Box>
                    </Index.Box>
                  ) : (
                    ""
                  )}
                </Index.Box>
              ) : (
                <Index.Box className="my-record-content-details">
                  <Index.Typography
                    className="my-record-data-not-found"
                    component="p"
                    variant="p"
                  >
                    No record found
                  </Index.Typography>
                </Index.Box>
              )}
            </Index.Box>

            {/* game records */}

            <Index.Box className="game-records-details">
              <Index.Box className="game-record-title">
                <Index.Typography
                  className="record-title"
                  component="p"
                  variant="p"
                >
                  {" "}
                  Game Records : {periodData?.period}{" "}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="game-details-uldetails">
                <Index.List className="game-records-ul">
                  <Index.ListItem className="game-record--listitem">
                    <Index.Box className="record-box-content">
                      <Index.Box className={`comman-bg-record`}>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="comman-text-content"
                        >
                          {"?"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="game-record-number-id">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="recordid-text-content"
                        >
                          {/* {periodData?.period} */}
                          {periodData?.period
                            ? periodData?.period?.toString()?.slice(-3)
                            : "-"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.ListItem>

                  {firstTwentyAllRecords?.map((userData, index) => {
                    const Color_firstCharacter = userData?.winColour
                      ?.charAt(0)
                      ?.toUpperCase();
                    if (userData?.period == periodData?.period) {
                      return <></>;
                    }
                    return (
                      <>
                        <Index.ListItem
                          className="game-record--listitem"
                          key={index}
                        >
                          <Index.Box className="record-box-content">
                            <Index.Box
                              className={`comman-bg-record ${
                                userData?.winColour == "orange"
                                  ? "green-bg-record"
                                  : userData?.winColour == "red"
                                  ? "red-bg-record"
                                  : userData?.winColour == "green"
                                  ? "orange-bg-record"
                                  : null
                              }`}
                            >
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="comman-text-content"
                              >
                                {userData?.winColour
                                  ? Color_firstCharacter
                                  : "?"}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="game-record-number-id">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="recordid-text-content"
                              >
                                {/* {userData?.period} */}
                                {userData?.period
                                  ? userData?.period?.toString()?.slice(-3)
                                  : "-"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.ListItem>
                      </>
                    );
                  })}
                </Index.List>
                {isMoreGameRecords() ? (
                  <Index.Box className="more-right-record">
                    <Index.Box
                      className="more-link-content"
                      onClick={() => {
                        navigate("/user/two-color-trading/all-records", {
                          state: {
                            getallGameData: getallGameData,
                            selectedSecond: selectedSecond,
                            ele: location?.state?.ele,
                            more: true,
                          },
                        });
                      }}
                    >
                      More &#8811;
                    </Index.Box>
                  </Index.Box>
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>

            {/* my orders */}

            <Index.Box className="my-record-details custom-record-details">
              {singleuserList.some((data) => data && data.isWin === true) && (
                <Index.Box className="game-record-title">
                  <Index.Typography
                    className="record-title"
                    component="p"
                    variant="p"
                  >
                    My Orders :
                  </Index.Typography>
                </Index.Box>
              )}
              {singleuserList?.some((data) => data && data?.isWin === true) && (
                <>
                  <Index.Box className="user-listitem-details user-listitem-details-two-color">
                    <Index.Box className="period-order-one">
                      <Index.Box className="myorder-thead-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="myorder-comman-title"
                        >
                          Periods
                        </Index.Typography>
                      </Index.Box>

                      {singleuserList?.map((data, index) => {
                        if (data?.isWin == true) {
                          return (
                            <Index.Box
                              className="myorder-description-details"
                              key={index}
                            >
                              <Index.Box className="myorder-desc-periods comman-myorder-description">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="myorder-periods-details-show comman-order-show"
                                >
                                  {data?.period}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          );
                        }
                      })}
                    </Index.Box>

                    <Index.Box className="select-order-two">
                      <Index.Box className="myorder-thead-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="myorder-comman-title"
                        >
                          Selected Color
                        </Index.Typography>
                      </Index.Box>

                      {singleuserList?.map((data, index) => {
                        const str = data?.colourName;
                        const betSideUppercase =
                          str.charAt(0).toUpperCase() + str.slice(1);
                        if (data?.isWin == true) {
                          return (
                            <Index.Box
                              className="select-description-details"
                              key={index}
                            >
                              <Index.Box className="myorder-desc-select comman-myorder-description">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className={`myorder-select-details-show comman-order-show ${
                                    data?.colourName == "red"
                                      ? "red-text-order"
                                      : data?.colourName == "orange"
                                      ? "orange-text-order"
                                      : data?.colourName == "green" &&
                                        "green-text-order"
                                  }`}
                                >
                                  {betSideUppercase}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          );
                        }
                      })}
                    </Index.Box>

                    <Index.Box className="amount-order-three">
                      <Index.Box className="myorder-thead-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="myorder-comman-title"
                        >
                          Amount
                        </Index.Typography>
                      </Index.Box>

                      {singleuserList?.map((data, index) => {
                        if (data?.isWin == true) {
                          return (
                            <Index.Box
                              className="amount-description-details"
                              key={index}
                            >
                              <Index.Box className="myorder-desc-amount comman-myorder-description">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="myorder-amount-details-show comman-order-show"
                                >
                                  {data?.price}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          );
                        }
                      })}
                    </Index.Box>
                  </Index.Box>
                </>
              )}
            </Index.Box>
          </Index.Box>

          <Index.Box
            className={
              !openChatDrawer
                ? "right-dashboard-main-user header-left-active"
                : "right-dashboard-main-user"
            }
          >
            <PageIndex.UserChat
              openChatMenu={!openChatDrawer}
              setOpenChatMenu={setOpenChatDrawer}
              setOpen={setOpen}
              snapImage={snapImage}
            />
          </Index.Box>
        </Index.Box>

        <Index.Modal
          open={battleModal}
          onClose={() => {
            setBattleModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-comman-details two-color-betting bg-noblur"
        >
          <Index.Box sx={style} className="modal-comman-inner-style">
            <Index.Box className="modal-cancel-btn">
              <Index.Button className="btn btn-cancel">
                <img
                  src={PageIndex.Svg.cancelmodal}
                  className="cancel-modal"
                  alt="modal-cancel"
                  onClick={() => {
                    setDisableBetConfirmBtn(false);
                    closeBetConfirmModal();
                  }}
                />
              </Index.Button>
            </Index.Box>
            <Index.Box>
              <Index.Box className="delete-game-data-main">
                <Index.Box className="number-list-details">
                  <Index.Typography
                    className="number-bet-coin are-you-sure-text"
                    component="p"
                    variant="p"
                  >
                    {`You want to bet ${count} coin for ${
                      selectedColor.charAt(0).toUpperCase() +
                      selectedColor.slice(1)
                    } `}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="deleteModel-btna1">
                  <Index.Box className="btn-col">
                    <PagesIndex.BlueOutlineButton
                      variant="contained"
                      color="error"
                      btnLabel="Ok"
                      className="outline-blue-btn-content"
                      onClick={() => startBattle()}
                      disabled={disableBetConfirmBtn}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>

        <AutoWinnerModal
          autoWinModal={autoWinModal}
          autoModalClose={autoModalClose}
          autoWinAmount={autoWinAmount}
          winnerUser={winnerUser}
          periodData={periodData}
          singleuserList={singleuserList}
          gameName="2 Color Betting"
          name="Colour Name"
          winnerUserAmount={winnerUserAmount}
          snapUploadInChat={snapUploadInChat}
          isHidden={isHidden}         
        />        
      </Index.Box>
    </>
  );
}
