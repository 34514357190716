import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import Loader from "../../../../component/comman/loader/Loader";
import Index from "../../../Index";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";
// import "../../../../assets/style/global.css";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
// const filterMaintenanceList = [
//   { id: 1, name: "All", value: "all" },
//   { id: 4, name: "Pending", value: "pending" },
//   { id: 2, name: "Win", value: "successfully" },
//   { id: 3, name: "Loss", value: "fail" },
// ];

const UserBetHistory = ({ filterBy, userId }) => {
  const loading = useSelector((state) => state?.UserReducer?.loading);
  const [searchedData, setSearchedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [betHistory, setBetHistory] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [filterByPriority, setFilterByPriority] = useState("");
  const [date, setDate] = useState(null);
  const [gameList, setGameList] = useState([]);
  const [filterByGame, setFilterByGame] = useState("");
  const [totalWin, setTotalWin] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
  const {gameNewName} = useOutletContext();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const getGameList = async () => {
    await DataService.get(Api.User.USER_GAMES)
      .then((res) => {
        setGameList(res?.data?.data);
      })
      .catch((error) => {});
  };

  const getUserBetHistory = async () => {
    await DataService.post(Api.ADMIN_BET_HISTORY_BY_USER, { filterBy, userId })
      .then((res) => {
        // setLoading(false);
        let data = res?.data?.data?.betHistory;
        setBetHistory(
          data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
        setTotalWin(res?.data?.data?.totalWin);
        setTotalLoss(res?.data?.data?.totalLoss);
        // setFilterData(res?.data?.data);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    setFilterData(betHistory);
  }, [betHistory]);

  useEffect(() => {
    getGameList();
    getUserBetHistory();
  }, []);

  useEffect(() => {
    let filtered;
    let filterDate = [];
    if (filterByPriority && filterByPriority !== "all") {
      filtered = betHistory?.filter(
        (ele) => ele?.status?.toLowerCase() === filterByPriority
      );
    } else {
      filtered = betHistory;
    }
    if (filterByGame && filterByGame !== "all") {
      filtered = filtered?.filter((ele) => ele?.gameId._id === filterByGame);
    }
    if (date) {
      filterDate = filtered.filter(
        (item) => Index.moment(item?.createdAt).format("DD/MM/YYYY") === date
      );
      setFilterData(filterDate);
    } else {
      setFilterData(filtered);
    }
  }, [betHistory, filterByPriority, filterByGame, date]);

  useEffect(() => {
    if (!search) {
      setSearchedData(filterData);
    } else {
      const filtered = filterData?.filter((item) => {
        return (
          item?.betAmount
            ?.toString()
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          item?.period
            ?.toString()
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          item?.gameId?.gameName
            ?.toString()
            ?.toLowerCase()
            .includes(search?.toLowerCase())
        );
      });

      if (filtered?.length > 0) {
        setSearchedData(filtered);
      } else {
        setSearchedData([]);
      }
    }
  }, [filterData, search]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Index.Box className="page-content-box admin-user-bet-container">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex flex-w-100-transaction custom-main-admin-header">
              <Index.Box className="admin-card-box-dash custom-admin-card-box">
                <Index.Box className="admin-pd-box-card custom-admin-pd-box-card">
                  <Index.Box className="card-dash-list-details ">
                    <Index.Typography
                      component="h5"
                      variant="h5"
                      className="user-title-main"
                    >
                      Total Win : {totalWin}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="admin-card-box-dash custom-admin-card-box">
                <Index.Box className="admin-pd-box-card custom-admin-pd-box-card">
                  <Index.Box className="card-dash-list-details">
                    <Index.Typography
                      component="h5"
                      variant="h5"
                      className="user-title-main"
                    >
                      Total Loss : {totalLoss}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="transaction-table-user-history">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container transaction-container-user"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main barge-table transaction-table-user dashboard-bet-table bet-history-admin"
              >
                <Index.TableHead className="transaction-table-thead-user">
                  <Index.TableRow className="transaction-user-tr">
                    <Index.TableCell className="transaction-user-th">
                      Date and Time
                    </Index.TableCell>
                    <Index.TableCell className="transaction-user-th">
                      Period
                    </Index.TableCell>
                    <Index.TableCell
                      className="transaction-user-th"
                      align="left"
                    >
                      Game
                    </Index.TableCell>
                    <Index.TableCell
                      className="transaction-user-th"
                      align="left"
                    >
                      Selected Slot
                    </Index.TableCell>
                    <Index.TableCell
                      className="transaction-user-th"
                      align="left"
                    >
                      Bet Amount
                    </Index.TableCell>
                    <Index.TableCell
                      className="transaction-user-th"
                      align="left"
                    >
                      Amount Win
                    </Index.TableCell>
                    {/* <Index.TableCell
                      className="transaction-user-th"
                      align="left"
                    >
                      Loose Amount
                    </Index.TableCell> */}
                    <Index.TableCell
                      className="transaction-user-th"
                      align="left"
                    >
                      Status
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {searchedData?.length ? (
                  searchedData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((ele) => {
                      return (
                        <Index.TableBody className="transaction-user-tbody">
                          <Index.TableRow className="transaction-user-tr">
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {ele?.createdAt
                                ? Index.moment(ele?.createdAt).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )
                                : "-"}
                            </Index.TableCell>
                            {/* <Index.TableCell
                            align="left"
                            className="transaction-user-td"
                          >
                            {ele?.networkChainId}
                          </Index.TableCell> */}
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {ele?.period}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {gameNewName(ele?.gameId?.gameName)}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {["Number Betting", "Community Betting"].includes(
                                ele?.gameId?.gameName
                              )
                                ? (ele?.slotTime ? ele?.slotTime + " minutes" : "-")
                                : (ele?.selectedTime ? ele?.selectedTime + " seconds": "-")
                                }
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {ele?.betAmount}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {ele?.status === "pending"
                                ? "-"
                                : ele?.status?.toLowerCase() === "refund"
                                ? "NA"
                                : ele?.isWin
                                ? ele?.gameId?.gameName === "Community Betting"
                                  ? `${ele?.betAmount} + ${(ele?.rewardAmount - ele?.betAmount).toFixed(8)} = ${(ele?.rewardAmount).toFixed(8)}`
                                  : ele?.gameId?.gameName === "Number Betting"
                                  ? `${ele?.betAmount} + ${(ele?.betAmount * ele?.winningPercentage - ele?.betAmount).toFixed(8)} = ${(ele?.betAmount * ele?.winningPercentage).toFixed(8)}`
                                  : `${ele?.betAmount} + ${(ele?.betAmount * ele?.winningPercentage).toFixed(8)} = ${(ele?.betAmount + ele?.betAmount * ele?.winningPercentage).toFixed(8)}`
                                : "0 + 0 = 0"}
                            </Index.TableCell>
                            {/* <Index.TableCell
                            align="left"
                            className="transaction-user-td"
                          >
                            {ele?.lossAmount}
                          </Index.TableCell> */}
                            <Index.TableCell
                              align="left"
                              className="transaction-user-td"
                            >
                              {ele?.status === "pending"
                                ? "Pending"
                                : ele?.status?.toLowerCase() === "refund"
                                ? "Refund"
                                : ele?.isWin
                                ? "Win"
                                : "Loss"}
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableBody>
                      );
                    })
                ) : (
                  <Index.NoDataFound
                    colSpan={7}
                    message={"No bet history found"}
                  />
                )}
              </Index.Table>
              {searchedData?.length ? (
                <Index.TablePagination
                  className="paginationColor"
                  component="div"
                  page={page}
                  count={searchedData?.length}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : (
                ""
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}></Index.Stack>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default UserBetHistory;
