import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";

import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getTotalCoins, userAmount } from "../../../../redux/user/userReducer";
import PagesIndex from "../../../pageIndex";
import PageIndex from "../../../pageIndex";
import AutoWinnerModal from "../autoWinnerModal/AutoWinnerModal";
import SendIcon from "@mui/icons-material/Send";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { io } from "socket.io-client";
import { checkGameAvailability } from "../../../../component/comman/CommonFunction/CheckGameAvailability";

const cardsImages = [
  { img: PageIndex.Png.clubs_2, name: "2" },
  { img: PageIndex.Png.clubs_3, name: "3" },
  { img: PageIndex.Png.clubs_4, name: "4" },

  { img: PageIndex.Png.diamonds_5, name: "5" },
  { img: PageIndex.Png.diamonds_6, name: "6" },
  { img: PageIndex.Png.spades_8, name: "8" },

  { img: PageIndex.Png.spades_9, name: "9" },
  { img: PageIndex.Png.hearts_10, name: "10" },

  { img: PageIndex.Png.spades_ace, name: "A" },

  { img: PageIndex.Png.spades_jack, name: "J" },
  { img: PageIndex.Png.spades_king, name: "K" },
  { img: PageIndex.Png.spades_queen, name: "Q" },
];

var socket;

export default function UserCardBetting() {
  const location = useLocation();
  const navigate = useNavigate();
  // const { state: { selectedSecond } = {} } = useLocation();

  // const selectedSecond = location?.state?.selectedSecond;
  const selectedSecond = useSelector(
    (state) => state?.UserReducer?.currentGameDetail.selectedSecond
  );
  const storedValue = localStorage.getItem("userGameId");
  const gameId = JSON.parse(storedValue);

  const [expanded, setExpanded] = React.useState("panel1");
  let timeRef = useRef(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [disableButtons, setDisableButtons] = useState(false);

  const [battleModal, setBattleModal] = useState(false);
  const [showWinnewModal, setShowWinnewModal] = useState(false);
  const [betSide, setBetSide] = useState("");
  const [count, setCount] = useState(gameId?.gameMinimumCoin);
  const dispatch = useDispatch();
  const [singleuserList, setSingleUserList] = useState([]);
  const [getallGameData, setGetallGameData] = useState([]);
  // const [gameTimer, setGameTimer] = useState("");

  const [periodData, setPeriodData] = useState({});
  const startDate = moment(gameId?.gameTimeFrom).format("YYYY/MM/DD ");
  const endDate = moment(gameId?.gameTimeTo).format("YYYY/MM/DD");
  const startDateTime = moment(startDate + " " + gameId?.gameDurationFrom);
  const endDateTime = moment(endDate + " " + gameId?.gameDurationTo);
  const currentDateTime = moment();
  const [isValidBetAmount, setIsValidBetAmount] = useState(false);
  const [checkWinnerApiCalled, setCheckWinnerApiCalled] = useState(false);
  const [previousPeriodWinnerChecked, setPreviousPeriodWinnerChecked] =
    useState(false);
  const [winnerUser, setWinnerUser] = useState([]);
  const [autoWinModal, setAutoWinModal] = useState(false);
  const [autoWinAmount, setAutoWinAmount] = useState();
  const [snapImage, setSnapImage] = useState();
  const [socketTimer, setSocketTimer] = useState("");
  const [firstTwentyAllRecords, setFirstTwentyAllRecord] = useState([]);
  const handleVisibilityChangeRef = useRef();
  const autoModalClose = () => {
    setAutoWinModal(false);
  };

  const {
    setOpen,
    setOpenDeposit,
    openChatDrawer,
    setOpenChatDrawer,
    setIsUserPlayingGame,
    currentGameDetail,
    fetchGameListAgain,
    setFetchGameListAgain,
  } = useOutletContext();

  //socket state

  const snapUploadInChat = (img) => {
    setSnapImage(img);
  };
  //socket call
  useEffect(() => {
    socket = io(Api.common.TIMER_SOCKET);
  }, []);
  const socketCall = () => {
    const data = {
      gameId: location?.state?.ele?._id,
      second: selectedSecond,
    };
    socket.emit("requestPeriod", data);
    socket.on("updateTimer", (data) => {
      if (parseInt(data?.seconds) >= 0) {
        setSocketTimer(data?.seconds);
      }
      if (data?.seconds == 0 || data?.seconds == -1) {
        setTimeout(() => {
          setWinningCard(null);
        }, 5000);
      }

      setPeriodData(data?.periodData);
    });
    socket.on("disconnect", () => {
      // Reconnect after a delay
      setTimeout(() => socketCall(), 3000);
    });
  };

  useEffect(() => {
    socketCall();
  }, []);

  const handleChangeAccro = (panel, expanded) => {
    setExpanded(expanded == panel ? "" : panel);
  };
  const userAmounts = useSelector(
    (state) => state?.UserReducer?.totalCoins?.totalCoin
  );
  useEffect(() => {
    if (startDateTime >= currentDateTime && endDateTime <= currentDateTime) {
      navigate("/user");
    }
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        getGamePeriod();
      }
    };

    handleVisibilityChangeRef.current = handleVisibilityChange;

    document.addEventListener(
      "visibilitychange",
      handleVisibilityChangeRef.current
    );

    return () => {
      if (handleVisibilityChangeRef.current) {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChangeRef.current
        );
      }
    };
  }, []);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getCountdown = (endtime) => {
    var currentDate = moment().format("YYYY/MM/DD");

    const endDateTime = moment(currentDate + " " + endtime);
    // another date
    var now = moment();
    var duration = moment.duration(endDateTime.diff(now));
    //Get Days and subtract from duration
    var days = duration.days();
    duration.subtract(days, "days");

    //Get hours and subtract from duration
    var hours = duration.hours();
    duration.subtract(hours, "days2");

    //Get Minutes and subtract from duration
    var minutes = duration.minutes();
    duration.subtract(minutes, "minutes");

    //Get seconds
    var seconds = duration.seconds();

    let countDown = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

    if (hours <= 0 && minutes <= 0 && seconds <= 0) {
      // setGameTimer("00:00:00");
      return "00:00:00";
    }
    // setGameTimer(countDown);
    return countDown;
  };

  const getSingleData = async () => {
    await DataService.get(
      `${Api.User.GET_CARD_MY_RECORDS}/${gameId?._id}?second=${selectedSecond}`
    )
      .then((res) => {
        setSingleUserList(res?.data?.data);
      })

      .catch((error) => {
        toast.error(error?.data?.message, {
          toastId: "customId",
        });
      });
  };

  const getAllData = (isCallingFirstTime) => {
    DataService.get(
      `${Api.User.GET_ALL_CARD_GAME_PERIOD}/${gameId?._id}?second=${selectedSecond}`
    )
      .then((res) => {
        let data = res?.data?.data;
        data.reverse();

        setGetallGameData(data);
      })

      .catch((error) => {
        toast.error(error?.data?.message, {
          toastId: "customId",
        });
      });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setFirstTwentyAllRecord(getallGameData?.slice(0, 15));
      } else {
        setFirstTwentyAllRecord(getallGameData?.slice(0, 21));
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getallGameData]);

  // useEffect(()=>{
  //   window.innerWidth<768
  //   ? setFirstTwentyAllRecord(getallGameData?.slice(0, 15))
  //   : setFirstTwentyAllRecord(getallGameData?.slice(0, 20))

  // },[getallGameData,  window.innerWidth])
  // let firstTwentyAllRecords =
  //   window.innerWidth < 768
  //     ? getallGameData?.slice(0, 15)
  //     : getallGameData?.slice(0, 21);

  const firstTwentyMyRecords = singleuserList.slice(0, 10);

  const isMoreGameRecords = () => {
    const isMobile = window.innerWidth < 768;
    return isMobile
      ? getallGameData?.length >= 3
      : getallGameData?.length >= 20;
  };

  // card flip logics
  const [winningCard, setWinningCard] = useState("");
  const [winnerSingleBetPrice, setWinnerSingleBetPrice] = useState();
  const [winnerCard, setWinnerCard] = useState();

  const congoModal = (price, winningAmount, side) => {
    const winprice = price * winningAmount + price;
    setAutoWinModal(true);
    setWinnerSingleBetPrice(winprice);
    setWinnerCard(side);
  };

  const winnerDeclaration = async () => {
    await DataService.get(
      `${Api.User.CARD_WINNER_RESULT}/${gameId?._id}/${periodData?.period}?second=${selectedSecond}`
    )
      .then((res) => {
        setWinnerUser(res?.data?.data?.[0]?.card);
        const cardNumber = res?.data?.message.split(" ").pop();
        const winningCardInfo = cardsImages?.find(
          (card) => card?.name == cardNumber
        );

        setWinningCard(winningCardInfo);

        setAutoWinAmount(res?.data?.data?.[0].totalBetAmount);

        if (res?.data?.data?.length == 0) {
          setResponseMessage(res?.data?.message);
        }
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
  };

  const checkCardWinner = async (previousPeriod) => {
    try {
      // let urlencoded = new URLSearchParams();
      // urlencoded.append("gameId", gameId?._id);
      // urlencoded.append("period", previousPeriod || periodData?.period);
      // urlencoded.append("selectedTime", selectedSecond);
      // const cardWinner = await DataService.post(
      //   Api.User.CHECK_CARD_WINNER,
      //   urlencoded
      // );
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (
      socketTimer <= 15 &&
      socketTimer > 5 &&
      periodData?.period &&
      !checkWinnerApiCalled
    ) {
      checkCardWinner();
      setCheckWinnerApiCalled(true);
    }

    if (socketTimer == 5) {
      winnerDeclaration();
      setFetchGameListAgain(!fetchGameListAgain);
      autoModalClose();
    }

    if (socketTimer == 0) {
      dispatch(getTotalCoins());
      // dispatch(userAmount())
      //   .then((res) => {
      //     toast.success(res?.data?.message, {
      //       toastId: "customId",
      //     });
      //   })
      //   .catch((error) => {
      //     toast.error(error?.data?.message, {
      //       toastId: "customId",
      //     });
      //   });
      if (responseMessage) {
        setShowWinnewModal(true);
        setTimeout(() => {
          handleCloseWinnerModal();
        }, 3000);
      }
      setTimeout(() => {
        getGamePeriod();
        // socketCall();
      }, 4000);
      setCheckWinnerApiCalled(false);
      isGameAvailable();
      getSingleData();
      getAllData();
    }
  }, [socketTimer]);

  // function checkGameAvailability() {
  //   const {
  //     gameTimeFrom,
  //     gameTimeTo,
  //     gameDurationFrom,
  //     gameDurationTo,
  //     gameSecond,
  //   } = currentGameDetail;
  
  //   const currentDateTime = moment();
  
  //   if (currentDateTime.isBetween(moment(gameTimeFrom), moment(gameTimeTo))) {
  //     const currentDayStart = currentDateTime.clone().startOf('day');
  //     const dailyStartDateTime = moment(currentDayStart.format("YYYY-MM-DD") + " " + gameDurationFrom, "YYYY-MM-DD hh:mm A");
  //     const dailyEndDateTime = moment(currentDayStart.format("YYYY-MM-DD") + " " + gameDurationTo, "YYYY-MM-DD hh:mm A");
  
  //     if (currentDateTime.isBetween(dailyStartDateTime, dailyEndDateTime) && gameSecond?.includes(selectedSecond)) {
  //       return true;
  //     }
  //   }
  //   setTimeout(() => {
  //     toast.error("Game will start soon");
  //     setIsUserPlayingGame(false);
  //     navigate("/user");
  //   }, 2000);
  //   return false;
  // }

  function isGameAvailable() {
    if(!checkGameAvailability(currentGameDetail, selectedSecond, true)){
      setTimeout(() => {
        toast.error("Game will start soon");
        setIsUserPlayingGame(false);
        navigate("/user");
      }, 2000);
      return false;
    }
    return true;
  }

  const calculateGameTime = (periodData) => {
    clearInterval(timeRef.current);
    timeRef.current = setInterval(() => {
      var timer = getCountdown(periodData?.endTime);

      if (timer === "00:00:00") {
        clearInterval(timeRef.current);
        setTimeout(() => {
          getGamePeriod();
          handleCloseWinnerModal();
        }, 4000);
        getSingleData();
        getAllData();
      }
    }, 1000);
  };

  useEffect(() => {
    isGameAvailable();
    return () => clearInterval(timeRef.current); // to clean up on unmount
  }, []);

  useEffect(() => {
    // return () => setGameTimer("00:00:00"); // to clean up on unmount
  }, []);

  const getGamePeriod = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("gameId", gameId._id);
    urlencoded.append("specificSecond", selectedSecond);
    await DataService?.post(Api.User.CREATE_GAME_PERIOD, urlencoded)
      .then((res) => {
        // console.log(res);
        socketCall();
        getAllData();
      })
      .catch((error) => {
        // console.log(error, "477");
      });
  };

  useEffect(() => {
    if (selectedSecond && gameId?._id) {
      getGamePeriod();
    }
  }, []);

  useEffect(() => {
    getSingleData();
    getAllData(1);
  }, []);

  // function
  const startBattle = () => {
    setIsUserPlayingGame(true);
    const data = {
      betAmount: count,
      gameId: gameId?._id,
      period: periodData?.period,
      card: betSide,
      gameMode: gameId?.gameMode,
      selectedTime: selectedSecond,
    };
    if (userAmounts <= count) {
      toast.error("Balance is insufficient");
    } else {
      DataService.post(Api.User.CREATE_CARD_BET, data)
        .then((res) => {
          if (res.data.status === 200 || res.data.status === 201) {
            socket?.emit("createColourBet");
            toast.success(res?.data?.message, {
              toastId: "customId",
            });
            getSingleData();

            dispatch(getTotalCoins());
          }
          // dispatch(userAmount())
          //   .then((res) => {
          //     toast.success(res?.data?.message, {
          //       toastId: "customId",
          //     });
          //   })
          //   .catch((error) => {});
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            toastId: "customId",
          });
        });
    }
    setBattleModal(false);
  };

  const increaseCount = (incrementValue) => {
    if (userAmounts <= 0 || count > userAmounts) {
      setOpenDeposit(true);
      return;
    }

    if (gameId?.gameMaximumCoin <= count + incrementValue) {
      setCount(gameId?.gameMaximumCoin);
    } else if (userAmounts >= count + incrementValue) {
      setCount(count + incrementValue);
    }
  };

  const decreaseCount = (decrementValue) => {
    if (userAmounts <= 0 || count > userAmounts) {
      setOpenDeposit(true);
      return;
    }

    if (gameId?.gameMinimumCoin <= count - decrementValue) {
      setCount(count - decrementValue);
    } else if (gameId?.gameMinimumCoin >= count - decrementValue) {
      setCount(gameId?.gameMinimumCoin);
    }
  };
  useEffect(() => {
    if (socketTimer <= 20) {
      setDisableButtons(true);
      setBattleModal(false);
    } else {
      setDisableButtons(false);
    }
  }, [socketTimer]);

  const handleCloseWinnerModal = () => {
    setResponseMessage("");
    setShowWinnewModal(false);
  };
  const totalSecondRound = (selectedSecond / selectedSecond) * 100;
  const pandingSecond = (socketTimer / selectedSecond) * 100;
  const getColor = () => {
    return socketTimer <= 10
      ? "red"
      : socketTimer >= 10 && socketTimer <= 30
      ? "orange"
      : socketTimer >= 30 && socketTimer <= 60
      ? "yellow"
      : socketTimer >= 60 && socketTimer <= 100
      ? "green"
      : "green";
  };

  useEffect(() => {
    if (
      !count ||
      parseInt(count) < parseInt(gameId?.gameMinimumCoin) ||
      parseInt(count) > parseInt(gameId?.gameMaximumCoin)
    ) {
      setIsValidBetAmount(false);
    } else {
      setIsValidBetAmount(true);
    }
  }, [count]);

  useEffect(() => {
    if (
      Array.isArray(getallGameData) &&
      getallGameData.length >= 2 &&
      periodData?.period &&
      !previousPeriodWinnerChecked
    ) {
      let tempAllGamePeriod = getallGameData;
      let previousPeriod = tempAllGamePeriod[1];
  
      if (!previousPeriod?.card) {
        checkCardWinner(previousPeriod?.period).then(() => {
          getSingleData();
          getAllData();
        });
      }
      DataService.get(
        `${Api.User.CARD_WINNER_RESULT}/${gameId?._id}/${previousPeriod?.period}?second=${selectedSecond}&prevPeriod=true`
      )
      setPreviousPeriodWinnerChecked(true);
    }
  }, [getallGameData, periodData]);

  return (
    <>
      <Index.Modal
        open={showWinnewModal}
        onClose={() => {
          handleCloseWinnerModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-comman-details modal-blur-common-remove"
      >
        <Index.Box sx={style} className="modal-comman-inner-style">
          <Index.Box className="modal-cancel-btn">
            <Index.Button className="btn btn-cancel">
              <img
                src={PageIndex.Svg.cancelmodal}
                className="cancel-modal"
                alt="modal-cancel"
                onClick={() => {
                  {
                    handleCloseWinnerModal();
                  }
                }}
              />
            </Index.Button>
          </Index.Box>
          <Index.Box>
            <Index.Box className="delete-game-data-main">
              <Index.Box className="number-list-details">
                <Index.Typography
                  className="number-bet-coin are-you-sure-text"
                  component="p"
                  variant="p"
                >
                  {responseMessage}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="deleteModel-btna1">
                <Index.Box className="btn-col">
                  <PagesIndex.BlueOutlineButton
                    variant="contained"
                    color="error"
                    btnLabel="Ok"
                    className="outline-blue-btn-content"
                    onClick={() => {
                      handleCloseWinnerModal();
                    }}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={battleModal}
        onClose={() => {
          setBattleModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-comman-details bg-noblur"
      >
        <Index.Box sx={style} className="modal-comman-inner-style">
          <Index.Box className="modal-cancel-btn">
            <Index.Button className="btn btn-cancel">
              <img
                src={PageIndex.Svg.cancelmodal}
                className="cancel-modal"
                alt="modal-cancel"
                onClick={() => {
                  setBattleModal(false);
                }}
              />
            </Index.Button>
          </Index.Box>
          <Index.Box>
            <Index.Box className="delete-game-data-main">
              <Index.Box className="number-list-details">
                <Index.Typography
                  className="number-bet-coin are-you-sure-text"
                  component="p"
                  variant="p"
                >
                  {`You want to bet ${count} coin for ${
                    betSide.charAt(0).toUpperCase() + betSide.slice(1)
                  } `}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="deleteModel-btna1">
                <Index.Box className="btn-col">
                  <PagesIndex.BlueOutlineButton
                    variant="contained"
                    color="error"
                    btnLabel="Ok"
                    className="outline-blue-btn-content"
                    onClick={() => startBattle()}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Box className="dashbaord-user-main-page">
        <Index.Box className="dasboard-flex-user-main">
          <Index.Box className="left-dashboard-main-user">
            <Index.Box className="card-betting-main-details">
              <Index.Box className="card-bet-row">
                <Index.Box className="card-col1">
                  <Index.Box className="card-bet-bg">
                    <Index.Box className="card-pd-bet">
                      <Index.Box className="card-bet-video">
                        <img
                          src={PageIndex.Png.cardbetting}
                          className="card-betting-video"
                        ></img>
                      </Index.Box>

                      <Index.Box className="timer-progress-details">
                        <Index.Box className="timer-progress-bg">
                          <CircularProgressbar
                            value={totalSecondRound - pandingSecond}
                            styles={buildStyles({
                              textSize: "16px",
                              textColor: getColor(),
                              pathColor: getColor(),
                              trailColor: "#d6d6d6",
                            })}
                          />
                          <Index.Box className="timer-text-details">
                            {`${socketTimer ? socketTimer : 0}`}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-low-high-bet-list">
                      <Index.Box className="pd-card-low-details custom-pd-card-low-details">
                        <Index.Box className="flex-low-high-card">
                          <Index.Box className="low-card-content">
                            <Index.Box className="low-card-main-btn">
                              <Index.Button className="low-card-btn-list">
                                <span>Low Card</span>
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="card-show-content">
                            <Index.Box className="card-details-middle">
                              <Index.Box className="card-bg-details">
                                {winningCard?.img &&
                                (socketTimer == 0 || socketTimer == -1) ? (
                                  <img
                                    className={
                                      (socketTimer == 0 || socketTimer == -1) &&
                                      winningCard?.img
                                        ? "card-flip-middle-content zoom-out"
                                        : "card-flip-middle-content-second"
                                    }
                                    src={winningCard?.img}
                                  />
                                ) : (
                                  <img
                                    className={
                                      (socketTimer == 0 || socketTimer == -1) &&
                                      winningCard?.img
                                        ? "card-flip-middle-content zoom-out"
                                        : "card-flip-middle-content-second"
                                    }
                                    src={PageIndex.Png.cardbet}
                                  />
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="high-card-content">
                            <Index.Box className="high-card-main-btn">
                              <Index.Button className="high-card-btn-list">
                                <span>high Card</span>
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="card-col3">
                  <Index.Box className="bid-details-contentg">
                    <Index.Box className="bid-amount-main-details"></Index.Box>
                    <Index.Box className="bet-amount-bg-content">
                      <Index.Box className="pd-bet-content">
                        <Index.Box className="card-bet-details-con">
                          <Index.Box className="bet-flex-content flex-gap-penalty">
                            <Index.Box className="bet-user-card-content">
                              <Index.Box className="penalty-flex-content desktop-btn-bet">
                                <Index.Box className="places-brt-main-btn">
                                  <Index.Button
                                    className={
                                      !isValidBetAmount || disableButtons
                                        ? "place-btn-content btn-disabled-content"
                                        : "place-btn-content"
                                    }
                                    onClick={(e) => {
                                      if (
                                        userAmounts <= 0 ||
                                        count > userAmounts
                                      ) {
                                        setOpenDeposit(true);
                                        return;
                                      }

                                      if (selectedSecond == "") {
                                        toast.error("Ple select game time");
                                      } else {
                                        if (userAmounts < count) {
                                          toast.error(
                                            "Balance is insufficient"
                                          );
                                        } else if (!disableButtons) {
                                          setBetSide("low");
                                          setBattleModal(true);
                                        }
                                      }
                                    }}
                                  >
                                    Bet low card
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="price-bet-input-details">
                                <Index.Box className="price-input-details-content">
                                  <Index.Box className="input-design-div with-border">
                                    <Index.TextField
                                      id="filled-hidden-label-normal"
                                      placeholder="Enter Amount"
                                      variant="filled"
                                      className="admin-input-design input-placeholder"
                                      autoComplete="off"
                                      name="coin"
                                      type="text"
                                      onWheel={(event) => event.target.blur()}
                                      disabled={disableButtons}
                                      value={count}
                                      onChange={(e) => {
                                        let batValue = e.target.value.replace(
                                          /\D/g,
                                          ""
                                        );
                                        if (batValue.startsWith("0")) {
                                          batValue = batValue.substring(1);
                                        }
                                        if (
                                          !isNaN(batValue) &&
                                          batValue.toString().length <= 15
                                        ) {
                                          setCount(+batValue);
                                        }
                                      }}
                                    />
                                  </Index.Box>
                                  <Index.Box className="place-plus-bet-left">
                                    <Index.Button
                                      className="plus-bet-btn-card"
                                      onClick={() => decreaseCount(10)}
                                      disabled={
                                        disableButtons ||
                                        count <= gameId?.gameMinimumCoin
                                      }
                                    >
                                      -
                                    </Index.Button>
                                  </Index.Box>
                                  <Index.Box className="place-plus-bet-right">
                                    <Index.Button
                                      className="plus-bet-btn-card"
                                      onClick={() => increaseCount(10)}
                                      disabled={
                                        disableButtons ||
                                        count >= gameId?.gameMaximumCoin
                                      }
                                    >
                                      +
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="penalty-flex-content desktop-btn-bet">
                                <Index.Box className="places-brt-main-btn">
                                  <Index.Button
                                    className={
                                      !isValidBetAmount || disableButtons
                                        ? "place-btn-content btn-disabled-content"
                                        : "place-btn-content"
                                    }
                                    onClick={(e) => {
                                      if (
                                        userAmounts <= 0 ||
                                        count > userAmounts
                                      ) {
                                        setOpenDeposit(true);
                                        return;
                                      }

                                      if (selectedSecond == "") {
                                        toast.error("Ple select game time");
                                      } else {
                                        if (userAmounts < count) {
                                          toast.error(
                                            "Balance is insufficient"
                                          );
                                        } else if (!disableButtons) {
                                          setBetSide("high");
                                          setBattleModal(true);
                                        }
                                      }
                                    }}
                                  >
                                    Bet high card
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="flex-mobile-bet-btn">
                                <Index.Box className="penalty-flex-content">
                                  <Index.Box className="places-brt-main-btn">
                                    <Index.Button
                                      className={
                                        disableButtons
                                          ? "place-btn-content btn-disabled-content"
                                          : "place-btn-content"
                                      }
                                      onClick={(e) => {
                                        if (selectedSecond == "") {
                                          toast.error("Ple select game time");
                                        } else {
                                          if (userAmounts < count) {
                                            toast.error(
                                              "Balance is insufficient"
                                            );
                                          } else if (!disableButtons) {
                                            setBetSide("low");
                                            setBattleModal(true);
                                          }
                                        }
                                      }}
                                    >
                                      Bet low card
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box className="penalty-flex-content ">
                                  <Index.Box className="places-brt-main-btn">
                                    <Index.Button
                                      className={
                                        !isValidBetAmount || disableButtons
                                          ? "place-btn-content btn-disabled-content"
                                          : "place-btn-content"
                                      }
                                      onClick={(e) => {
                                        if (selectedSecond == "") {
                                          toast.error("Ple select game time");
                                        } else {
                                          if (userAmounts < count) {
                                            toast.error(
                                              "Balance is insufficient"
                                            );
                                          } else if (!disableButtons) {
                                            setBetSide("high");
                                            setBattleModal(true);
                                          }
                                        }
                                      }}
                                    >
                                      Bet high card
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* my records */}
            {firstTwentyMyRecords.length ? (
              <Index.Box className="game-records-details">
                <Index.Box className="game-record-title">
                  <Index.Typography
                    className="record-title"
                    component="p"
                    variant="p"
                  >
                    {" "}
                    My Records :
                  </Index.Typography>
                </Index.Box>
                {firstTwentyMyRecords.length ? (
                  <Index.Box className="game-records-details">
                    <Index.Box className="accordian-records-details">
                      {firstTwentyMyRecords?.map((ele, index) => {
                        const str = ele?.card;
                        const betSideUppercase =
                          str.charAt(0).toUpperCase() + str.slice(1);
                        return (
                          <Index.Accordion
                            className="accordian-main-record"
                            expanded={expanded === index + 1}
                            onChange={() =>
                              handleChangeAccro(index + 1, expanded)
                            }
                          >
                            <Index.AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                              className="accordian-summary-record"
                              expandIcon={
                                <Index.ExpandMoreIcon className="accordian-icon-record" />
                              }
                            >
                              <Index.Box className="details-icon-main">
                                <Index.Typography className="accordian-main-record-title">
                                  {ele?.period}{" "}
                                  <span
                                    className={
                                      ele?.status == "pending"
                                        ? "yellow-span"
                                        : ele?.isWin == true
                                        ? "green-text-order"
                                        : "red-text-order"
                                    }
                                  >
                                    {ele?.status == "pending"
                                      ? "Pending"
                                      : ele?.isWin == true
                                      ? "Won"
                                      : "Loss"}
                                  </span>
                                </Index.Typography>
                              </Index.Box>

                              {ele?.isWin == true && (
                                <Index.Box className="details-icon-main-test">
                                  <Index.IconButton className="icon-button-box-chat">
                                    <SendIcon
                                      className="details-icon"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        congoModal(
                                          ele?.price,
                                          ele?.winningAmount,
                                          betSideUppercase
                                        );
                                      }}
                                    />
                                  </Index.IconButton>
                                </Index.Box>
                              )}
                            </Index.AccordionSummary>
                            <Index.AccordionDetails className="accordian-inner-details-record">
                              <Index.Typography className="accordian-inner-description">
                                <Index.Typography
                                  component="h4"
                                  variant="h4"
                                  className="accoro-details-title"
                                >
                                  Period Details
                                </Index.Typography>
                                <Index.Box className="my-record-details">
                                  <Index.Box className="my-record-content-details">
                                    <Index.Typography
                                      className="period-my-record"
                                      component="p"
                                      variant="p"
                                    >
                                      Period id :<span>{ele?.period}</span>
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="my-record-content-details">
                                    <Index.Typography
                                      className="period-my-record"
                                      component="p"
                                      variant="p"
                                    >
                                      Selected card :
                                      <span>{betSideUppercase}</span>
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="my-record-content-details">
                                    <Index.Typography
                                      className="period-my-record"
                                      component="p"
                                      variant="p"
                                    >
                                      Bet Status :<span>Successful</span>
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="my-record-content-details">
                                    <Index.Typography
                                      className="period-my-record"
                                      component="p"
                                      variant="p"
                                    >
                                      Bet coins :<span>{ele?.price}</span>
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="my-record-content-details">
                                    <Index.Typography
                                      className="period-my-record"
                                      component="p"
                                      variant="p"
                                    >
                                      Created at :
                                      <span>
                                        {moment(ele?.betCreatedAt).format(
                                          "YYYY-MM-D, hh:mm "
                                        )}
                                      </span>
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="my-record-content-details">
                                    <Index.Typography
                                      className="period-my-record"
                                      component="p"
                                      variant="p"
                                    >
                                      {ele?.status == "pending" ? (
                                        <>
                                          Winning coins (approx.) :
                                          <span>
                                            {ele?.price * ele?.winningAmount +
                                              ele?.price}
                                          </span>
                                        </>
                                      ) : ele?.isWin == true ? (
                                        <>
                                          Winning coins (approx.) :
                                          <span>
                                            {ele?.price * ele?.winningAmount +
                                              ele?.price}
                                          </span>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="my-record-content-details"></Index.Box>
                                </Index.Box>
                              </Index.Typography>
                            </Index.AccordionDetails>
                          </Index.Accordion>
                        );
                      })}
                    </Index.Box>
                    {firstTwentyMyRecords.length >= 10 ? (
                      <Index.Box className="more-right-record">
                        <Index.Box
                          className="more-link-content"
                          onClick={() => {
                            navigate("/user/card-trading/my-records", {
                              state: {
                                singleuserList: singleuserList,
                                selectedSecond: selectedSecond,
                                ele: location?.state?.ele,
                              },
                            });
                          }}
                        >
                          More &#8811;
                        </Index.Box>
                      </Index.Box>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                ) : (
                  <Index.Box className="my-record-content-details">
                    <Index.Typography
                      className="my-record-data-not-found"
                      component="p"
                      variant="p"
                    >
                      No record found
                    </Index.Typography>
                  </Index.Box>
                )}
              </Index.Box>
            ) : (
              <></>
            )}

            {/* Game Records */}

            <Index.Box className="game-records-details">
              <Index.Box className="game-record-title">
                <Index.Typography
                  className="record-title"
                  component="p"
                  variant="p"
                >
                  {" "}
                  Game Records : {periodData?.period}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="game-details-uldetails">
                <Index.List className="game-records-ul">
                  <Index.ListItem className="game-record--listitem">
                    <Index.Box className="record-box-content">
                      <Index.Box className={`comman-bg-record`}>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="comman-text-content"
                        >
                          {"?"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="game-record-number-id">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="recordid-text-content"
                        >
                          {periodData?.period
                            ? periodData?.period?.toString()?.slice(-3)
                            : "-"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.ListItem>

                  {firstTwentyAllRecords?.map((userData) => {
                    const Color_firstCharacter = userData?.card
                      ?.charAt(0)
                      ?.toUpperCase();
                    if (userData?.period == periodData?.period) {
                      return <></>;
                    }
                    return (
                      <>
                        <Index.ListItem className="game-record--listitem">
                          <Index.Box className="record-box-content">
                            <img
                              src={PageIndex.Png.cardbet}
                              className="card-small-bet-img card-game-record"
                            ></img>
                            <Index.Box className="game-record-number-id">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="recordid-text-content"
                              >
                                {`${userData?.period?.toString()?.slice(-3)} (${
                                  userData?.card ? Color_firstCharacter : "?"
                                })`}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.ListItem>
                      </>
                    );
                  })}
                </Index.List>

                {isMoreGameRecords() ? (
                  <Index.Box className="more-right-record">
                    <Index.Box
                      className="more-link-content"
                      onClick={() => {
                        navigate("/user/card-trading/all-records", {
                          state: {
                            getallGameData: getallGameData,
                            selectedSecond: selectedSecond,
                            ele: location?.state?.ele,
                            more: true,
                          },
                        });
                      }}
                    >
                      More &#8811;
                    </Index.Box>
                  </Index.Box>
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box
            className={
              !openChatDrawer
                ? "right-dashboard-main-user header-left-active"
                : "right-dashboard-main-user"
            }
          >
            <PageIndex.UserChat
              openChatMenu={!openChatDrawer}
              setOpenChatMenu={setOpenChatDrawer}
              setOpen={setOpen}
              snapImage={snapImage}
            />
          </Index.Box>
          <AutoWinnerModal
            autoWinModal={autoWinModal}
            autoModalClose={autoModalClose}
            autoWinAmount={autoWinAmount}
            winnerUser={winnerUser}
            periodData={periodData}
            singleuserList={singleuserList}
            gameName="Card Betting"
            type="card betting"
            winnerSingleBetPrice={winnerSingleBetPrice}
            name="Card Side"
            winnerCard={winnerCard}
            snapUploadInChat={snapUploadInChat}
            isNumberCard={true}
          />
        </Index.Box>
      </Index.Box>
    </>
  );
}
